import React, { FC, ReactNode, useEffect, useState } from "react";
import { StayDataType } from "data/types";
import Button from "shared/Button/Button";

import HeaderFilter from "./HeaderFilter";
import StayCard from "components/StayCard/StayCard";
import { fetchFlightDeals } from "../../data/flightDeal/_requests";

export interface SectionGridFeaturePlacesProps {
  gridClass?: string;
  heading?: ReactNode;
  subHeading?: ReactNode;
}

const SectionGridFeaturePlaces: FC<SectionGridFeaturePlacesProps> = ({
  gridClass = "",
  heading = "Featured Flight Deals",
  subHeading = "Featured flight deals recommended for you",
}) => {
  const [loading, setLoading] = useState(true);
  const [flightDeals, setFlightDeals] = useState<StayDataType[]>([]);

  useEffect(() => {
    const fetchFlightDealsData = async () => {
      try {
        setLoading(true);
        const response = await fetchFlightDeals({
          is_featured: 1, // Set the filter for is_featured = 1
          limit: 8, // Set the limit to 8
        });
        setFlightDeals(response.data.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching flight deals:", error);
      }
    };

    fetchFlightDealsData();
  }, []);

  const renderCard = (flightDeal: StayDataType) => {
    return <StayCard key={flightDeal.id} data={flightDeal} />;
  };

  return (
    <div className="nc-SectionGridFeaturePlaces relative">
      <HeaderFilter
        tabActive={""}
        subHeading={subHeading}
        tabs={[]}
        heading={heading}
        onClickTab={() => {}}
      />
      {loading ? (
        <div>Loading flight deals...</div>
      ) : (
        <div
          className={`grid gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 ${gridClass}`}
        >
          {flightDeals.map((flightDeal) => renderCard(flightDeal))}
        </div>
      )}
      <div className="flex mt-16 justify-center items-center">
        <Button href="/special-flights" className="bg-red-700 hover:bg-red-800">
          Show me more
        </Button>
      </div>
    </div>
  );
};

export default SectionGridFeaturePlaces;
