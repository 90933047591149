import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;
const PAYMENT_FLIGHT_DEAL_CHECKOUT_INIT = `${API_URL}/payment/flight_deal/init`;
const PAYMENT_TOUR_CHECKOUT_INIT = `${API_URL}/payment/tour/init`;

interface InitiateFlightDealCheckoutRequest {
  email_address: string;
  order_number: string;
  flight_deal_id: number;
  full_name: string;
  mobile: string;
}

interface InitiateTourCheckoutRequest {
  email_address: string;
  order_number: string;
  tour_id: number;
  full_name: string;
  mobile: string;
}

interface InitiateCheckoutResponse {
  response: {
    orderId: string;
    sessionId: string;
    merchant: string;
  };
}

export const initiateFlightDealCheckout = async (
  payload: InitiateFlightDealCheckoutRequest
): Promise<InitiateCheckoutResponse> => {
  try {
    const response = await axios.post(
      PAYMENT_FLIGHT_DEAL_CHECKOUT_INIT,
      payload
    );
    return response.data;
  } catch (error) {
    throw new Error("An error occurred while initiating checkout");
  }
};

export const initiateTourCheckout = async (
  payload: InitiateTourCheckoutRequest
): Promise<InitiateCheckoutResponse> => {
  try {
    const response = await axios.post(PAYMENT_TOUR_CHECKOUT_INIT, payload);
    return response.data;
  } catch (error) {
    throw new Error("An error occurred while initiating checkout");
  }
};
