import { FC, useState, useEffect } from "react";
import TourCard from "components/TourCard/TourCard";
import Pagination from "shared/Pagination/Pagination";
import TabFilters from "./TabFilters";
import Heading1 from "components/Heading/Heading1";
import { fetchTours } from "../../data/tour/_requests";
import Loading from "../../components/Loading/Loading";

export interface SectionGridFilterCardProps {
  className?: string;
}

const SectionGridFilterCard: FC<SectionGridFilterCardProps> = ({
  className = "",
}) => {
  const [flightDeals, setFlightDeals] = useState<any>([]);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedCities, setSelectedCities] = useState<string[]>([]);
  const [selectedDays, setSelectedDays] = useState<number[]>([1, 30]);
  const [selectedPrices, setSelectedPrices] = useState<number[]>([100, 10000]);

  // Function to fetch data based on the current filter settings
  const fetchData = async () => {
    try {
      const params = {
        page: currentPage,
        limit: 12,
        cities: selectedCities,
        numberOfDays: selectedDays,
        priceRange: selectedPrices,
      };

      const response = await fetchTours(params);
      setFlightDeals(response.data.data);
      setTotalItems(response.data.pagination.total);
    } catch (error) {
      console.error("Error fetching flight deals:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // useEffect for initial data fetch and page change
  useEffect(() => {
    fetchData();
  }, [currentPage, selectedCities, selectedDays, selectedPrices]); // Trigger fetchData on filter change

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleApplyFilters = (
    cities: string[],
    days: number[],
    prices: number[]
  ) => {
    console.log("cities", cities, "days", days, "prices", prices);
    setSelectedCities(cities);
    setSelectedDays(days);
    setSelectedPrices(prices);
    console.log(
      "selectedCities",
      selectedCities,
      "selectedDays",
      selectedDays,
      "selectedPrices",
      selectedPrices
    );

    fetchData(); // Fetch data with new filters
  };

  return (
    <div
      className={`nc-SectionGridFilterCard ${className}`}
      data-nc-id="SectionGridFilterCard"
    >
      <Heading1
        heading="My Flight Zone - Tours"
        subHeading={`${totalItems} awesome tours for you!`}
      />

      <div className="mb-8 lg:mb-11">
        <TabFilters
          selectedCities={selectedCities}
          selectedDays={selectedDays}
          selectedPrices={selectedPrices}
          onApplyFilters={handleApplyFilters}
        />
      </div>

      {isLoading && <Loading />}
      {!isLoading && (
        <div>
          <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
            {flightDeals.map((flightDeal: any) => (
              <TourCard key={flightDeal.id} data={flightDeal} />
            ))}
          </div>
          <div className="flex mt-16 justify-center items-center">
            <Pagination
              totalItems={totalItems}
              itemsPerPage={12}
              currentPage={currentPage}
              onChangePage={handlePageChange}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default SectionGridFilterCard;
