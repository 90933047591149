import { FC } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";

export interface TourDataType {
  id: string | number;
  tour_title: string;
  description: string;
  number_of_days: number;
  availability: string;
  banner_image: string;
  old_base_price: number;
  base_price: number;
  slug: string;
  seo_tour_name: string;
  seo_keywords: string;
  seo_description: string;
  arrival_city: string;
}

export interface TourCardProps {
  className?: string;
  data: TourDataType;
  size?: "default" | "small";
}

const TourCard: FC<TourCardProps> = ({
  size = "default",
  className = "",
  data,
}) => {
  const {
    id,
    tour_title,
    description,
    number_of_days,
    arrival_city,
    banner_image,
    base_price,
    slug,
  } = data;

  const formattedPrice = `AUD $${base_price.toLocaleString("en-AU", {
    style: "currency",
    currency: "AUD",
  })}`;

  const truncateDescription = (text: string, maxLength: number) => {
    if (text.length <= maxLength) return text;
    return text.substring(0, maxLength) + "...";
  };

  const truncatedDescription = truncateDescription(description, 135);

  const renderSliderGallery = () => {
    return (
      <div className="relative w-full">
        <GallerySlider
          uniqueID={`TourCard_${id}`}
          ratioClass="aspect-w-4 aspect-h-3 "
          galleryImgs={[banner_image]}
          href={`/tours/${id}/${slug}`}
        />
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className={size === "default" ? "p-4 space-y-4" : "p-3 space-y-2"}>
        <div className="space-y-2">
          {/* <div className="flex justify-between items-center">
            <Badge
              name={
                <div className="flex items-center">
                  <i className="text-sm la la-plane"></i>
                </div>
              }
              color="yellow"
            />
            &nbsp;
            <Badge
              name={
                <div className="flex items-center">
                  <i
                    className="text-sm la la-hotel mr-1"
                  ></i>
                </div>
              }
              color="blue"
            />
            &nbsp;
            <Badge
              name={
                <div className="flex items-center">
                  <i
                    className="text-sm la la-ship mr-1"
                  ></i>
                </div>
              }
              color="green"
            />
            &nbsp;
            <Badge
              name={
                <div className="flex items-center">
                  <i
                    className="text-sm la la-train mr-1"
                  ></i>
                </div>
              }
              color="purple"
            />
            &nbsp;
            <Badge
              name={
                <div className="flex items-center">
                  <i
                    className="text-sm la la-map-marker mr-1"
                  ></i>
                </div>
              }
              color="red"
            />
          </div> */}
          <div className="flex items-center space-x-2">
            <h2
              className={` font-medium capitalize ${
                size === "default" ? "text-lg" : "text-base"
              }`}
            >
              <span className="line-clamp-1">{tour_title}</span>
            </h2>
          </div>
        </div>
        <div className="flex justify-between items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
          <div className="flex items-center space-x-2">
            <div className="flex items-center space-x-2">
              <span className="ml-1">
                <i className="la la-calendar-plus"></i>
              </span>
            </div>
            <div className="flex items-center space-x-2">Number of Days</div>
          </div>

          <div className="flex items-center space-x-2">
            <div className="flex items-center space-x-2">{number_of_days}</div>
          </div>
        </div>

        <div className="flex justify-between items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
          <div className="flex items-center space-x-2">
            <div className="flex items-center space-x-2">
              <span className="ml-1">
                <i className="las la-plane-arrival"></i>
              </span>
            </div>
            <div className="flex items-center space-x-2">{arrival_city}</div>
          </div>
        </div>

        <div className="flex justify-between items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
          <div className="flex items-center space-x-2">
            <span className="text-sm text-neutral-500 dark:text-neutral-400">
              {truncatedDescription}
            </span>
          </div>
        </div>

        <div className="w-14 border-b border-neutral-100 dark:border-neutral-800"></div>
        <div className="flex justify-between items-center">
          <span className="text-base font-semibold">{formattedPrice}</span>

          <a
            href={`/tours/${id}/${slug}`}
            className="ttnc-ButtonPrimary px-4 py-2 text-sm disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50 rounded-full inline-flex items-center text-sm dark:text-neutral-300 font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
          >
            Book Now
          </a>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-TourCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow ${className}`}
      data-nc-id="TourCard"
    >
      {renderSliderGallery()}
      {renderContent()}
    </div>
  );
};

export default TourCard;
