import React, { useState } from "react";
import { Tour } from "../_models";
import { Disclosure } from "@headlessui/react";
import { ChevronRightIcon } from "@heroicons/react/20/solid";

const TourTypeTabs: React.FC<{ tour: Tour }> = ({ tour }) => {
  const [activeTab, setActiveTab] = useState(tour.tour_types[0].id);

  const renderTabContent = () => {
    const activeTourType = tour.tour_types.find(
      (type) => type.id === activeTab
    );

    // Check if activeTourType is not undefined before rendering its content
    return (
      <div className="mt-5">
        {activeTourType && (
          <>
            <div className=" mb-5">
              <Disclosure defaultOpen>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-blue-900 bg-blue-100 rounded-lg hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
                      <span>Tour Inclusions</span>
                      <ChevronRightIcon
                        className={`${
                          open ? "transform rotate-90" : ""
                        } w-5 h-5 text-blue-500`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className=" pt-4 pb-2 text-sm">
                      <div className="p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800">
                        <h3 className="text-2xl font-semibold leading-none text-neutral-900 md:text-3xl dark:text-neutral-200">
                          Tour Inclusions
                        </h3>
                        <div className="block text-sm text-neutral-500 mt-3 sm:text-base dark:text-neutral-400">
                          {Object.entries(activeTourType.tour_inclusions).map(
                            ([category, tour_inclusions], index) => (
                              <div
                                key={index}
                                className={`flex flex-row justify-start items-start p-4 rounded-lg ${
                                  index % 2 === 0
                                    ? ""
                                    : "bg-neutral-100 dark:bg-neutral-800"
                                }`}
                              >
                                <h3 className="w-1/3 text-lg font-medium">
                                  {category}
                                </h3>
                                <ul className="w-2/3">
                                  {tour_inclusions.map(
                                    (tour_inclusion, idx) => (
                                      <li key={idx} className="mb-2">
                                        <h4 className="font-semibold">
                                          {tour_inclusion.title}
                                        </h4>
                                        <div className="text-sm">
                                          <div
                                            className="tour-overview-content"
                                            dangerouslySetInnerHTML={{
                                              __html:
                                                tour_inclusion.description,
                                            }}
                                          />{" "}
                                        </div>
                                        {tour_inclusion.external_link && (
                                          <span>
                                            <br />
                                            <a
                                              className="text-sm"
                                              href={
                                                tour_inclusion.external_link
                                              }
                                              target="_blank"
                                              rel="noopener noreferrer"
                                            >
                                              More Information
                                            </a>
                                          </span>
                                        )}
                                      </li>
                                    )
                                  )}
                                </ul>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </div>

            <div className=" mb-5">
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-blue-900 bg-blue-100 rounded-lg hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
                      <span>Itinerary</span>
                      <ChevronRightIcon
                        className={`${
                          open ? "transform rotate-90" : ""
                        } w-5 h-5 text-blue-500`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className=" pt-4 pb-2 text-sm">
                      <div className="p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800">
                        <h3 className="text-2xl font-semibold leading-none text-neutral-900 md:text-3xl dark:text-neutral-200">
                          Itinerary
                        </h3>
                        <div className="block text-sm text-neutral-500 mt-3 sm:text-base dark:text-neutral-400">
                          {Object.entries(activeTourType.itineraries).map(
                            ([category, itineraries], index) => (
                              <div
                                key={index}
                                className={`flex flex-row justify-start items-start p-4 rounded-lg ${
                                  index % 2 === 0
                                    ? ""
                                    : "bg-neutral-100 dark:bg-neutral-800"
                                }`}
                              >
                                <h3 className="w-1/3 text-lg font-medium">
                                  {category}
                                </h3>
                                <ul className="w-2/3">
                                  {itineraries.map((itinerary, idx) => (
                                    <li key={idx} className="mb-2">
                                      <h4 className="font-semibold">
                                        {itinerary.title}
                                      </h4>
                                      <div className="text-sm">
                                        <div
                                          className="tour-overview-content"
                                          dangerouslySetInnerHTML={{
                                            __html: itinerary.description,
                                          }}
                                        />{" "}
                                      </div>

                                      {itinerary.external_link && (
                                        <span>
                                          <br />
                                          <a
                                            className="text-sm"
                                            href={itinerary.external_link}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            More Information
                                          </a>
                                        </span>
                                      )}
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </div>

            <div className=" mb-5">
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-blue-900 bg-blue-100 rounded-lg hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
                      <span>Important Information</span>
                      <ChevronRightIcon
                        className={`${
                          open ? "transform rotate-90" : ""
                        } w-5 h-5 text-blue-500`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className=" pt-4 pb-2 text-sm">
                      <div className="p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800">
                        <h3 className="text-2xl font-semibold leading-none text-neutral-900 md:text-3xl dark:text-neutral-200">
                          Important Information
                        </h3>
                        <div className="block text-sm text-neutral-500 mt-3 sm:text-base dark:text-neutral-400">
                          {Object.entries(
                            activeTourType.important_information
                          ).map(([category, important_information], index) => (
                            <div
                              key={index}
                              className={`flex flex-row justify-start items-start p-4 rounded-lg ${
                                index % 2 === 0
                                  ? ""
                                  : "bg-neutral-100 dark:bg-neutral-800"
                              }`}
                            >
                              <h3 className="w-1/3 text-lg font-medium">
                                {category}
                              </h3>
                              <ul className="w-2/3">
                                {important_information.map(
                                  (iInformation, idx) => (
                                    <li key={idx} className="mb-2">
                                      <h4 className="font-semibold">
                                        {iInformation.title}
                                      </h4>
                                      <div className="text-sm">
                                        <div
                                          className="tour-overview-content"
                                          dangerouslySetInnerHTML={{
                                            __html: iInformation.description,
                                          }}
                                        />{" "}
                                      </div>
                                      {iInformation.external_link && (
                                        <span>
                                          <br />
                                          <a
                                            className="text-sm"
                                            href={iInformation.external_link}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            More Information
                                          </a>
                                        </span>
                                      )}
                                    </li>
                                  )
                                )}
                              </ul>
                            </div>
                          ))}
                        </div>
                      </div>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </div>
          </>
        )}
      </div>
    );
  };

  return (
    <div>
      <div className="flex overflow-x-auto">
        <ul className="mr-2 sm:mr-6 md:mr-12 flex space-x-5 sm:space-x-8 lg:space-x-11 overflow-x-auto hiddenScrollbar list-none p-0">
          {tour.tour_types.map((type) => (
            <li
              onClick={() => setActiveTab(type.id)}
              className={`flex-shrink-0 flex items-center cursor-pointer text-sm lg:text-base font-medium pb-3 ${
                activeTab === type.id
                  ? "border-b-2 border-neutral-800 dark:border-neutral-100"
                  : "text-neutral-500 hover:text-neutral-700 dark:hover:text-neutral-400"
              }`}
              key={type.id}
            >
              <span>{type.type_title}</span>
            </li>
          ))}
        </ul>
      </div>
      <div className="p-4">{renderTabContent()}</div>
    </div>
  );
};

export default TourTypeTabs;
