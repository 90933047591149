import { FC, useEffect, useState } from "react";
import StayCard from "components/StayCard/StayCard";
import Pagination from "shared/Pagination/Pagination";
import TabFilters from "./TabFilters";
import Heading1 from "components/Heading/Heading1";
import { fetchFlightDeals } from "../../data/flightDeal/_requests";
import Loading from "../../components/Loading/Loading";

export interface SectionGridFilterCardProps {
  className?: string;
}

const SectionGridFilterCard: FC<SectionGridFilterCardProps> = ({
  className = "",
}) => {
  const [flightDeals, setFlightDeals] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedCities, setSelectedCities] = useState<string[]>([]);

  useEffect(() => {
    // Fetch flight deals data from the backend
    const fetchData = async () => {
      try {
        const params = {
          page: currentPage,
          limit: 12,
          cities: selectedCities,
        };

        const response = await fetchFlightDeals(params);

        setFlightDeals(response.data.data);
        setTotalItems(response.data.pagination.total);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching flight deals:", error);
      }
    };

    fetchData();
  }, [currentPage, selectedCities]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleCitySelection = (city: string) => {
    if (selectedCities.includes(city)) {
      setSelectedCities(
        selectedCities.filter((selectedCity) => selectedCity !== city)
      );
    } else {
      setSelectedCities([...selectedCities, city]);
    }
  };

  return (
    <div
      className={`nc-SectionGridFilterCard ${className}`}
      data-nc-id="SectionGridFilterCard"
    >
      <Heading1
        heading="My Flight Zone - Special Flights"
        subHeading={`${totalItems} awesome flight deals for you!`}
      />

      <div className="mb-8 lg:mb-11">
        <TabFilters
          selectedCities={selectedCities}
          onCitySelection={handleCitySelection}
        />
      </div>
      {isLoading && <Loading />}
      {!isLoading && (
        <div>
          <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
            {flightDeals.map((flightDeal: any) => (
              <StayCard key={flightDeal.id} data={flightDeal} />
            ))}
          </div>
          <div className="flex mt-16 justify-center items-center">
            <Pagination
              totalItems={totalItems}
              itemsPerPage={12}
              currentPage={currentPage}
              onChangePage={handlePageChange}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default SectionGridFilterCard;
