import rightImg from "images/logo.png";
import React, { FC } from "react";
import { Helmet } from "react-helmet";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionHero from "./SectionHero";
import SectionOurDifference from "./SectionOurDifference";

export interface PageAboutProps {
  className?: string;
}

const PageAbout: FC<PageAboutProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-PageAbout overflow-hidden relative ${className}`}
      data-nc-id="PageAbout"
    >
      <Helmet>
        <title>Who We Are - Travel Agent to Sri Lanka | My Flight Zone</title>

        <meta
          name="description"
          content="From flight ticket bookings to tailored travel packages, we solve all your travel needs. Explore tours Australia to Sri Lanka with ease"
        />
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
        <SectionHero
          rightImg={rightImg}
          heading="Why Us?"
          btnText=""
          subHeading="My Flight Zone has become a specialist in the travel industry and a well-known name over the last few years. We offer high-end services, thousands of in and outbound flights, special hotel rates, exclusive destination activity packages, incredible daily travel packages and so much more. Our company is fast becoming a household name known for discount travel throughout Australia. Being constantly updated on the latest and greatest travel offers in and around the globe, My Flight Zone offers its users an array of the hottest travel destinations worldwide, that will keep your fingers clicking and your pulses racing. Find the flights you want, the accommodation that is most convenient, paired with the best modes of transport and the activities you always wanted and that right there is the recipe for a memorable trip, and we will make sure it is indeed a happy one.

          "
        />

        <div className="relative py-16">
          <BackgroundSection />
          <SectionOurDifference />
        </div>
      </div>
    </div>
  );
};

export default PageAbout;
