import { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { fetchFlightDeal } from "../../../data/flightDeal/_requests";
import Badge from "shared/Badge/Badge";
import SectionDateRange from "../SectionDateRange";

import DetailPagetLayout from "../Layout";
import { GuestsObject } from "components/HeroSearchForm/type";
import Loading from "../../../components/Loading/Loading";
import Sidebar from "./Sidebar";
import FlightDetails from "../(components)/FlightDetails";

export interface GuestsInputProps {
  className?: string;
  flightDeal: { available_tickets: number; id: number };
  guestData: GuestsObject; // Add guestData prop
  handleGuestDataChange: (value: number, type: keyof GuestsObject) => void; // Update handleGuestDataChange function
}

const StayDetailPageContainer: FC<{}> = () => {
  // const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  // const [isCreatingOrder, setIsCreatingOrder] = useState(false);

  const { id } = useParams<{ id: string }>();
  const [flightDeal, setFlightDeal] = useState<any>(null);

  const [guestAdultsInputValue, setGuestAdultsInputValue] = useState(0);
  const [guestChildrenInputValue, setGuestChildrenInputValue] = useState(0);
  const [guestInfantsInputValue, setGuestInfantsInputValue] = useState(0);

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  useEffect(() => {
    const fetchFlightDealData = async (id: string) => {
      try {
        const data = await fetchFlightDeal(id);
        setFlightDeal(data);

        // Retrieve guest data from local storage
        const storedGuestData = localStorage.getItem("flightDeals");
        if (storedGuestData) {
          const parsedGuestData = JSON.parse(storedGuestData);
          const guestDataForId = parsedGuestData[id] || {};
          // setGuestData(guestDataForId);
          setGuestAdultsInputValue(guestDataForId.guestAdults || 0);
          setGuestChildrenInputValue(guestDataForId.guestChildren || 0);
          setGuestInfantsInputValue(guestDataForId.guestInfants || 0);
        }

        // Set the start and end dates for the SectionDateRange component
        const departureDate = new Date(data.departure_datetime);
        const startMonth = departureDate.getMonth() - 1; // Previous month
        const endMonth = departureDate.getMonth() + 2; // Following month
        // Set the start date to the first day of the previous month
        const startDate = new Date(departureDate.getFullYear(), startMonth, 1);

        // Set the end date to the last day of the following month
        const endDate = new Date(departureDate.getFullYear(), endMonth, 0);

        setStartDate(startDate);
        setEndDate(endDate);
      } catch (error) {
        // Handle error
      } finally {
        setIsLoading(false);
      }
    };

    if (id) {
      fetchFlightDealData(id);
    }

    // Retrieve guest data from session storage
    const storedGuestData = localStorage.getItem("flightDeals");
    if (storedGuestData) {
      const parsedGuestData = JSON.parse(storedGuestData);
      const guestDataForId = parsedGuestData[id ?? ""]; // Use nullish coalescing operator

      if (guestDataForId) {
        // setGuestData(guestDataForId);

        // Set the input values based on the retrieved guest data
        setGuestAdultsInputValue(guestDataForId.guestAdults || 0);
        setGuestChildrenInputValue(guestDataForId.guestChildren || 0);
        setGuestInfantsInputValue(guestDataForId.guestInfants || 0);
      }
    }
  }, [
    id,
    guestAdultsInputValue,
    guestChildrenInputValue,
    guestInfantsInputValue,
  ]);

  const handleGuestDataChange = (value: number, type: keyof GuestsObject) => {
    setGuestAdultsInputValue(
      type === "guestAdults" ? value : guestAdultsInputValue
    );
    setGuestChildrenInputValue(
      type === "guestChildren" ? value : guestChildrenInputValue
    );
    setGuestInfantsInputValue(
      type === "guestInfants" ? value : guestInfantsInputValue
    );
  };

  const renderSection1 = () => {
    return (
      <div className="listingSection__wrap !space-y-6">
        {/* 2 */}
        <h1 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">
          {flightDeal.title}
        </h1>

        <div className="inline-flex space-x-3">
          {flightDeal.available_tickets < 10 && (
            <Badge
              name={
                <div className="flex items-center">
                  <i className="text-sm la la-plane"></i>
                  <span className="ml-1">
                    Last {flightDeal.available_tickets} Seats!
                  </span>
                </div>
              }
              color="yellow"
            />
          )}
          {flightDeal.is_return_trip && (
            <Badge
              name={
                <div className="flex items-center justify-end flex-1">
                  <i className="text-sm la la-plane"></i>
                  <i
                    className="text-sm la la-plane mr-1"
                    style={{ transform: "rotate(180deg)" }}
                  ></i>
                  <span className="ml-1">Return</span>
                </div>
              }
              color="blue"
            />
          )}
        </div>

        {/* 5 */}
        <div className="w-full border-b border-neutral-100 dark:border-neutral-700" />

        <FlightDetails flightDeal={flightDeal} />

        {/* 6 */}
      </div>
    );
  };

  //   To add a route: Flight Number, Airline, Departure Location, Departure Date, Departure Time, Arrival Location, Arrival Date, Arrival Time

  // To add a transit: Transit: Xh Xmin

  const renderSection2 = () => {
    return (
      <div className="listingSection__wrap">
        <h2 className="text-2xl font-semibold">Description</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="text-neutral-6000 dark:text-neutral-300">
          <span>{flightDeal.description}</span>
        </div>

        <h2 className="text-2xl font-semibold">Itinerary</h2>

        <div className="grid grid-cols-2 gap-4">
          <div className="flow-root">
            {flightDeal.itineraries.map((itinerary: string, index: number) => {
              // Extracting values from the itinerary record

              const [
                flightNumber,
                airline,
                departureLocation,
                departureDate,
                departureTime,
                arrivalLocation,
                arrivalDate,
                arrivalTime,
              ] = itinerary.split(",").map((item) => item.trim());

              // Checking if it's a transit record
              const isTransit = itinerary.startsWith("Transit:");
              const transitTime = itinerary.startsWith("Transit:")
                ? itinerary.split("Transit: ")[1]
                : "null";

              return (
                <div key={index}>
                  {!isTransit && (
                    <div className="flex flex-col md:flex-row ">
                      <div className="w-24 md:w-20 lg:w-24 flex-shrink-0 md:pt-7">
                        {`${airline} (${flightNumber})`}
                      </div>
                      <div className="flex my-5 md:my-0">
                        <div className="flex-shrink-0 flex flex-col items-center py-2">
                          <span className="block w-6 h-6 rounded-full border border-neutral-400"></span>
                          <span className="block flex-grow border-l border-neutral-400 border-dashed my-1"></span>
                          <span className="block w-6 h-6 rounded-full border border-neutral-400"></span>
                        </div>
                        <div className="ml-4 space-y-10 text-sm">
                          <div className="flex flex-col space-y-1">
                            <span className=" text-neutral-500 dark:text-neutral-400">
                              {departureDate} {departureTime}
                            </span>
                            <span className=" font-semibold">
                              {departureLocation}
                            </span>
                          </div>
                          <div className="flex flex-col space-y-1">
                            <span className=" text-neutral-500 dark:text-neutral-400">
                              {arrivalDate} {arrivalTime}
                            </span>
                            <span className=" font-semibold">
                              {arrivalLocation}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {isTransit && (
                    <div className="flex flex-col md:flex-row mt-10 mb-10">
                      <div className="w-24 md:w-20 lg:w-24 flex-shrink-0 md:pt-7">
                        &nbsp;
                      </div>
                      <div className="flex my-5 md:my-0">
                        <div className="flex-shrink-0 flex flex-col items-center py-2">
                          <span className="block w-6 h-6 rounded-full border border-neutral-400"></span>
                        </div>
                        <div className="ml-4 space-y-10 text-sm">
                          <div className="flex flex-col space-y-1">
                            <span className=" text-neutral-500 dark:text-neutral-400">
                              Transit
                            </span>
                            <span className=" font-semibold">
                              {transitTime}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
          {flightDeal.is_return_trip && (
            <div className="flow-root">
              {flightDeal.return_itineraries.map(
                (return_itinerary: string, index: number) => {
                  // Extracting values from the return_itinerary record
                  const [
                    return_flightNumber,
                    return_airline,
                    return_departureLocation,
                    return_departureDate,
                    return_departureTime,
                    return_arrivalLocation,
                    return_arrivalDate,
                    return_arrivalTime,
                  ] = return_itinerary.split(",").map((item) => item.trim());

                  // Checking if it's a transit record
                  const return_isTransit =
                    return_itinerary.startsWith("Transit:");
                  const return_transitTime = return_itinerary.startsWith(
                    "Transit:"
                  )
                    ? return_itinerary.split("Transit: ")[1]
                    : "null";

                  return (
                    <div key={index}>
                      {!return_isTransit && (
                        <div className="flex flex-col md:flex-row ">
                          <div className="w-24 md:w-20 lg:w-24 flex-shrink-0 md:pt-7">
                            {`${return_airline} (${return_flightNumber})`}
                          </div>
                          <div className="flex my-5 md:my-0">
                            <div className="flex-shrink-0 flex flex-col items-center py-2">
                              <span className="block w-6 h-6 rounded-full border border-neutral-400"></span>
                              <span className="block flex-grow border-l border-neutral-400 border-dashed my-1"></span>
                              <span className="block w-6 h-6 rounded-full border border-neutral-400"></span>
                            </div>
                            <div className="ml-4 space-y-10 text-sm">
                              <div className="flex flex-col space-y-1">
                                <span className=" text-neutral-500 dark:text-neutral-400">
                                  {return_departureDate} {return_departureTime}
                                </span>
                                <span className=" font-semibold">
                                  {return_departureLocation}
                                </span>
                              </div>
                              <div className="flex flex-col space-y-1">
                                <span className=" text-neutral-500 dark:text-neutral-400">
                                  {return_arrivalDate} {return_arrivalTime}
                                </span>
                                <span className=" font-semibold">
                                  {return_arrivalLocation}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      {return_isTransit && (
                        <div className="flex flex-col md:flex-row mt-10 mb-10">
                          <div className="w-24 md:w-20 lg:w-24 flex-shrink-0 md:pt-7">
                            &nbsp;
                          </div>
                          <div className="flex my-5 md:my-0">
                            <div className="flex-shrink-0 flex flex-col items-center py-2">
                              <span className="block w-6 h-6 rounded-full border border-neutral-400"></span>
                            </div>
                            <div className="ml-4 space-y-10 text-sm">
                              <div className="flex flex-col space-y-1">
                                <span className=" text-neutral-500 dark:text-neutral-400">
                                  Transit
                                </span>
                                <span className=" font-semibold">
                                  {return_transitTime}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  );
                }
              )}
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderSection4 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <div>
          <h2 className="text-2xl font-semibold">Conditions </h2>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* CONTENT */}
        <div className="flow-root">
          <div className="text-sm sm:text-base text-neutral-6000 dark:text-neutral-300 -mb-4">
            {flightDeal.conditions.map((condition: string, index: number) => (
              <div
                key={index}
                className={`p-4 flex justify-between items-center space-x-4 rounded-lg ${
                  index % 2 === 0 ? "" : "bg-neutral-100 dark:bg-neutral-800"
                }`}
              >
                <span>{condition}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  if (isLoading) {
    return (
      <div className="nc-ListingStayDetailPage">
        <main className=" relative z-10 mt-11 flex flex-col lg:flex-row ">
          <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10">
            <Loading />
          </div>
        </main>
      </div>
    );
  }

  const appBaseUrl = process.env.APP_BASE_URL
  ? process.env.APP_BASE_URL
  : "https://myflightzone.com.au";

  return (
    <div className="nc-ListingStayDetailPage">
      <Helmet>
        <title>My Flight Zone | {flightDeal.seo_title}</title>
        <meta name="description" content={flightDeal.seo_description} />
        <meta name="keywords" content={flightDeal.seo_keywords} />
        <link rel="canonical" href={`${appBaseUrl}/special-flight/${id}/${flightDeal.slug}`} />
      </Helmet>
      {/*  HEADER */}
      <header className="rounded-b-md sm:rounded-b-xl">
        <div className="relative grid grid-cols-1 gap-1 sm:gap-2">
          <div className="relative overflow-hidden h-64 sm:h-72 rounded-b-md sm:rounded-b-xl">
            {
              <img
                className="absolute inset-0 object-cover w-full h-full"
                src={flightDeal.banner_image}
                alt=""
                sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 50vw"
                style={{ borderRadius: "0 0 0.5rem 0.5rem" }} // Here 0.5rem is just an example, adjust as needed
              />
            }
          </div>
        </div>
      </header>
      {/* MAIN */}
      <main className=" relative z-10 mt-11 flex flex-col lg:flex-row ">
        <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10">
          {renderSection1()}

          <div
            className="lg:hidden flex-[3]  !mx-auto  mt-10 mb-20"
            style={{ textAlign: "center" }}
          >
            {
              <Sidebar
                flightDeal={flightDeal}
                handleGuestDataChange={handleGuestDataChange}
                guestAdultsInputValue={guestAdultsInputValue}
                guestChildrenInputValue={guestChildrenInputValue}
                guestInfantsInputValue={guestInfantsInputValue}
              />
            }
          </div>
          {renderSection2()}
          {renderSection4()}
          <SectionDateRange
            startDate={startDate}
            endDate={endDate}
            departureAirport={flightDeal.departure_airport}
            arrivalAirport={flightDeal.arrival_airport}
          />
        </div>

        {/* SIDEBAR */}
        <div className="hidden lg:block flex-grow mt-14 lg:mt-0">
          {/* <div className="sticky top-28">{renderSidebar()}</div> */}
          <div className="sticky top-28">
            {
              <Sidebar
                flightDeal={flightDeal}
                handleGuestDataChange={handleGuestDataChange}
                guestAdultsInputValue={guestAdultsInputValue}
                guestChildrenInputValue={guestChildrenInputValue}
                guestInfantsInputValue={guestInfantsInputValue}
              />
            }
          </div>
        </div>
      </main>
    </div>
  );
};

export default function ListingStayDetailPage() {
  return (
    <DetailPagetLayout>
      <StayDetailPageContainer />
    </DetailPagetLayout>
  );
}
