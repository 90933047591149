import { FC, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Helmet } from "react-helmet";
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { refundStatus } from "../../data/refund/_requests";

export interface PageRefundStatusProps {
  className?: string;
}

const info = [
  {
    icon: "la la-map-marked",
    desc: "No 13, Linden Tree Way Cranbourne North, VIC 3977, Australia",
  },
  {
    icon: "la la-mail-bulk",
    desc: "info@myflightzone.com.au",
  },
  {
    icon: "la la-phone",
    desc: "+61 452 365 24 7",
  },
  {
    icon: "la la-fax",
    desc: "+61 398 0322 44",
  },
  {
    icon: "lab la-whatsapp",
    desc: "+61 452 365 24 7",
  },
  {
    icon: "lab la-viber",
    desc: "+61 452 365 24 7",
  },
];

const PageRefundStatus: FC<PageRefundStatusProps> = ({ className = "" }) => {
  const [submitting, setSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const reCaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY
    ? process.env.REACT_APP_RECAPTCHA_SITE_KEY
    : "";

  const validationSchema = Yup.object().shape({
    booking_reference: Yup.string().required("E-ticket number is required"),
    last_name: Yup.string().required("Last name is required"),
    recaptcha: Yup.string().required("Please complete the reCAPTCHA"),
  });

  const handleSubmit = async (values: any) => {
    try {
      setSubmitting(true);
      setSuccessMessage("");
      setErrorMessage("");

      const recaptchaResponse = formik.values.recaptcha;

      if (!recaptchaResponse) {
        setErrorMessage("Please complete the reCAPTCHA verification.");
        return;
      }

      // Make API call using Axios or fetch
      const response = await refundStatus(values);

      if (response.status && response.updated_at) {
        const formattedDate = moment(response.updated_at).format("DD/MM/YYYY"); // Format the date
        const refundDate = response.refund_date
          ? `Refund Expected Date: ${moment(response.refund_date).format(
              "DD/MM/YYYY"
            )} <br /> `
          : "Refund Expected Date: N/A <br /> "; // Format the refund date

        setSuccessMessage(
          `Status: ${response.status}  <br />${refundDate} Last updated at: ${formattedDate}`
        );
      } else {
        setErrorMessage("Failed to submit the form. Please try again later.");
      }
    } catch (error: any) {
      setErrorMessage(error.message);
    } finally {
      setSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      booking_reference: "",
      last_name: "",
      recaptcha: "",
    },
    validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <div
      className={`nc-PageRefundStatus overflow-hidden ${className}`}
      data-nc-id="PageRefundStatus"
    >
      <Helmet>
        <title>Refund Request Status</title>

        <meta
          name="description"
          content="Are you looking to travel somewhere? Not sure where to go? Want to make your tour plan with the travelling specialist? You got us. My Flight Zone is ready to offer the support. If you have any queries, don't hesitate to contact My Flight Zone. We are looking forward to providing you with clear solutions & ultimately clearing all your doubts."
        />
      </Helmet>
      <div className="mb-24 lg:mb-32">
        <h1 className="my-16 sm:my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Refund Request Status
        </h1>
        <div className="container max-w-7xl mx-auto">
          <div className="flex-shrink-0 grid grid-cols-1 sm:grid-cols-2 gap-12 ">
            <div className="max-w-sm space-y-8">
              {info.map((item, index) => (
                <div key={index}>
                  <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                    <i className={item.icon} style={{ fontSize: "24px" }}></i>
                    &nbsp;&nbsp;
                    {item.desc}
                  </span>
                </div>
              ))}
            </div>

            <div className="p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800">
              <p>
                <strong>
                  {successMessage && (
                    <div
                      className="text-green-500"
                      dangerouslySetInnerHTML={{ __html: successMessage }}
                    ></div>
                  )}
                  {errorMessage && (
                    <div className="text-red-500">{errorMessage}</div>
                  )}
                  &nbsp;
                </strong>
              </p>
              <form
                className="grid grid-cols-1 gap-6"
                onSubmit={formik.handleSubmit}
              >
                <label className="block">
                  <Label>
                    E-ticket No (Please enter the last 10 digits of your
                    E-ticket)
                  </Label>
                  <Input
                    name="booking_reference"
                    placeholder="XXXXXX"
                    type="text"
                    className="mt-1"
                    value={formik.values.booking_reference}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.booking_reference &&
                    formik.errors.booking_reference && (
                      <div className="text-red-500">
                        {formik.errors.booking_reference}
                      </div>
                    )}
                </label>
                <label className="block">
                  <Label>Last name as per the E-ticket</Label>
                  <Input
                    name="last_name"
                    type="text"
                    placeholder="XXX XXX"
                    className="mt-1"
                    value={formik.values.last_name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.last_name && formik.errors.last_name && (
                    <div className="text-red-500">
                      {formik.errors.last_name}
                    </div>
                  )}
                </label>

                <label className="block">
                  <Label>reCAPTCHA</Label>
                  <ReCAPTCHA
                    type="image"
                    {...formik.getFieldProps("recaptcha")}
                    sitekey={reCaptchaSiteKey}
                    onChange={(response) =>
                      formik.setFieldValue("recaptcha", response)
                    }
                    onBlur={() => formik.setFieldTouched("recaptcha", true)}
                    onExpired={() => formik.setFieldValue("recaptcha", "")}
                  />

                  {formik.touched.recaptcha && !formik.values.recaptcha && (
                    <div className="text-red-500">
                      Please complete the reCAPTCHA.
                    </div>
                  )}
                </label>
                <div>
                  <ButtonPrimary type="submit" disabled={submitting}>
                    {submitting ? "Submitting..." : "Submit"}
                  </ButtonPrimary>
                </div>
              </form>
            </div>
          </div>

          <div
            className={`nc-SectionOurDifference relative mt-20 ${className} `}
            data-nc-id="SectionOurDifference"
          >
            <div className={`nc-SectionStatistic relative ${className}`}>
              <div className="grid md:grid-cols-2 gap-6 lg:grid-cols-3 xl:gap-8">
                <div className="p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800">
                  <h3 className="text-2xl font-semibold leading-none text-neutral-900 md:text-3xl dark:text-neutral-200">
                    AUSTRALIA
                  </h3>
                  <span className="block text-sm text-neutral-500 mt-3 sm:text-base dark:text-neutral-400">
                    <ul>
                      <li>
                        <i className="la la-map-marked"></i> No 13, Linden Tree
                        Way Cranbourne North, VIC 3977, Australia
                      </li>
                      <li className="mt-5">
                        <i className="la la la-phone"></i>{" "}
                        <a href="tel:+61452365247">+61 452 365 24 7</a>
                      </li>
                      <li className="mt-5">
                        <i className="la la la-mail-bulk"></i>{" "}
                        <a href="mailto:info@myflightzone.com.au">
                          info@myflightzone.com.au
                        </a>
                      </li>
                    </ul>
                  </span>
                </div>
                <div className="p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800">
                  <h3 className="text-2xl font-semibold leading-none text-neutral-900 md:text-3xl dark:text-neutral-200">
                    SRI LANKA
                  </h3>
                  <span className="block text-sm text-neutral-500 mt-3 sm:text-base dark:text-neutral-400">
                    <ul>
                      <li>
                        <i className="la la-map-marked"></i> No: 37/1, Colombo
                        Road, Kurana, Katunayake,Sri Lanka
                      </li>
                      <li className="mt-5">
                        <i className="la la la-phone"></i>{" "}
                        <a href="tel:+94117365247">+94 117 365 24 7</a>
                      </li>
                      <li className="mt-5">
                        <i className="la la la-mail-bulk"></i>{" "}
                        <a href="mailto:info@myflightzone.com.au">
                          info@myflightzone.com.au
                        </a>
                      </li>
                    </ul>
                  </span>
                </div>
                <div className="p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800">
                  <h3 className="text-2xl font-semibold leading-none text-neutral-900 md:text-3xl dark:text-neutral-200">
                    New Zealand
                  </h3>
                  <span className="block text-sm text-neutral-500 mt-3 sm:text-base dark:text-neutral-400">
                    <ul>
                      <li>
                        <i className="la la-map-marked"></i> 15 Victoria Road,
                        Devenport, New Zealand
                      </li>
                      <li className="mt-5">
                        <i className="la la la-phone"></i>{" "}
                        <a href="tel:+64273365247">+64 27 336 5247</a>
                      </li>
                      <li className="mt-5">
                        <i className="la la la-mail-bulk"></i>{" "}
                        <a href="mailto:info@myflightzone.com.au">
                          info@myflightzone.com.au
                        </a>
                      </li>
                    </ul>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageRefundStatus;
