import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;
const GET_BLOG_ARTICLE_BY_ID = `${API_URL}/blog_article`;
const GET_BLOG_ARTICLES = `${API_URL}/blog_article/get_list`;

const fetchBlogArticle = async (id: string) => {
  try {
    const response = await axios.get(`${GET_BLOG_ARTICLE_BY_ID}/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching blog article:", error);
    throw error;
  }
};

const fetchBlogArticles = async (params: any) => {
  try {
    const response = await axios.get(GET_BLOG_ARTICLES, {
      params: {
        ...params,
      },
    });
    return response;
  } catch (error) {
    console.error("Error fetching blog articles:", error);
    throw error;
  }
};

export { fetchBlogArticle, fetchBlogArticles };
