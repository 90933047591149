import { ReactNode } from "react";

const DetailPagetLayout = ({ children }: { children: ReactNode }) => {
  return (
    <div className="ListingDetailPage">
      <div className="container ListingDetailPage__content">{children}</div>

      <div className="container py-24 lg:py-32"></div>
    </div>
  );
};

export default DetailPagetLayout;
