import React from "react";

interface TourDetailsProps {
  tour: any;
}

const TourDetails: React.FC<TourDetailsProps> = ({ tour }) => {
  return (
    <>
      <div className="grid grid-cols-2 gap-4">
        <div className="flex items-start justify-start space-x-6 text-sm text-neutral-700 dark:text-neutral-300">
          <div className="flex items-start space-x-2">
            <span className="flex items-center ml-1">
              <i className="las la-plane-arrival mr-2"></i> Arrival
            </span>
          </div>
          <div className="flex items-start space-x-2">
            <span className="ml-1">
              <i className="las la-map-marker"></i>&nbsp;
              {tour.arrival_country}, {tour.arrival_city}
            </span>
          </div>
        </div>
        <div className="flex items-start justify-start space-x-6 text-sm text-neutral-700 dark:text-neutral-300">
          <div className="flex items-start space-x-2">
            <span className="flex items-center ml-1">
              <i className="la la-calendar-plus mr-2"></i> Number of Days
            </span>
          </div>
          <div className="flex items-start space-x-2">
            <span className="ml-1">{tour.number_of_days}</span>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1">
        <p>{tour.description}</p>
      </div>
    </>
  );
};

export default TourDetails;
