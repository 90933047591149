import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;
const GET_CURRENT_EXCHANGE_RATE = `${API_URL}/exchange_rate/current?${Date.now()}&`;

const fetchCurrentExchangeRate = async () => {
  try {
    const response = await axios.get(GET_CURRENT_EXCHANGE_RATE);
    return response.data;
  } catch (error) {
    console.error("Error fetching exchange rate:", error);
    throw error;
  }
};

export { fetchCurrentExchangeRate };
