import React, { useEffect, useState } from "react";
import NcImage from "shared/NcImage/NcImage";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { fetchBlogArticle } from "../../data/blogArticle/_requests"; // import the fetchBlogArticle function
import Loading from "../../components/Loading/Loading";
// import SocialsList from "shared/SocialsList/SocialsList";

const BlogSingle = () => {
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState(true);
  const [post, setPost] = useState<any>(null);

  useEffect(() => {
    const fetchPost = async (id: string) => {
      try {
        const data = await fetchBlogArticle(id); // Fetch the blog article with the specified id
        setPost(data);
      } catch (error) {
        console.error("Error fetching blog article:", error);
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchPost(id);
    }
  }, [id]);

  const renderHeader = () => {
    let updatedAt = new Date(post.updated_at);

    let formattedUpdatedAt = updatedAt.toLocaleDateString("en-US", {
      day: "2-digit",
      month: "long",
      year: "numeric",
    });

    return (
      <header className="container rounded-xl">
        <div className="max-w-screen-md mx-auto space-y-5">
          <h1
            className=" text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-4xl dark:text-neutral-100 max-w-4xl "
            title="Quiet ingenuity: 120,000 lunches and counting"
          >
            {post.title}
          </h1>
          <span className="block text-base text-neutral-500 md:text-lg dark:text-neutral-400 pb-1">
            {post.meta_description}
          </span>

          <div className="w-full border-b border-neutral-100 dark:border-neutral-800"></div>
          <div className="flex flex-col items-baseline sm:flex-row sm:justify-between">
            <div className="nc-PostMeta2 flex items-center flex-wrap text-neutral-700 text-left dark:text-neutral-200 text-sm leading-none flex-shrink-0">
              <div className="ml-3">
                <div className="flex items-center">
                  <a className="block font-semibold" href="/">
                    My Flight Zone
                  </a>
                </div>
                <div className="text-xs mt-[6px]">
                  <span className="text-neutral-700 dark:text-neutral-300">
                    {formattedUpdatedAt}
                  </span>
                  <span className="mx-2 font-semibold">·</span>
                  <span className="text-neutral-700 dark:text-neutral-300">
                    5 min read
                  </span>
                </div>
              </div>
            </div>
            {/* <div className="mt-3 sm:mt-0 sm:ml-3">
              <SocialsList />
            </div> */}
          </div>
        </div>
      </header>
    );
  };

  if (loading) {
    return (
      <div className="nc-PageSingle pt-8 lg:pt-16 ">
        <div className="nc-SingleContent container space-y-10">
          <Loading />
        </div>
      </div>
    );
  }

  const appBaseUrl = process.env.APP_BASE_URL
  ? process.env.APP_BASE_URL
  : "https://myflightzone.com.au";

  return (
    <div className="nc-PageSingle pt-8 lg:pt-16 ">
      <Helmet>
        <title>My Flight Zone | {post.seo_title}</title>

        <meta name="description" content={post.meta_description} />
        <meta name="keywords" content={post.keywords} />
        <link rel="canonical" href={`${appBaseUrl}/blog/${id}/${post.slug}`} />
      </Helmet>
      {renderHeader()}
      <NcImage
        className="w-full rounded-xl"
        containerClassName="container my-10 sm:my-12 "
        src={post.banner_image}
      />

      <div className="nc-SingleContent container space-y-10">
        <div dangerouslySetInnerHTML={{ __html: post.article }} />{" "}
        {/* Render the HTML content */}
        <br />
        <br />
        <br />
      </div>
    </div>
  );
};

export default BlogSingle;
