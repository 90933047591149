import React, { useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import GuestsInput from "./GuestsInput";
import { GuestsObject } from "components/HeroSearchForm/type";
import { createFlightDealBookingPending } from "../../../data/flightDealOrderPending/_requests";
import { useNavigate } from "react-router-dom";

interface SidebarProps {
  flightDeal: any;
  guestAdultsInputValue: number;
  guestChildrenInputValue: number;
  guestInfantsInputValue: number;
  handleGuestDataChange: (value: number, type: keyof GuestsObject) => void;
}

const Sidebar: React.FC<SidebarProps> = ({
  flightDeal,
  guestAdultsInputValue,
  guestChildrenInputValue,
  guestInfantsInputValue,
  handleGuestDataChange,
}) => {
  const [isCreatingOrder, setIsCreatingOrder] = useState(false);
  const [showValidationMessage, setShowValidationMessage] = useState(false);
  const navigate = useNavigate();

  const total_price_adults = flightDeal.new_price * guestAdultsInputValue;
  const total_price_children = flightDeal.child_price * guestChildrenInputValue;
  const total_price_infants = flightDeal.infant_price * guestInfantsInputValue;

  const totalPrice =
    total_price_adults + total_price_children + total_price_infants;

  // Format totalPrice as an AUD price value
  const formattedTotalPrice = `AUD ${totalPrice.toLocaleString("en-AU", {
    style: "currency",
    currency: "AUD",
  })}`;

  const formattedReservePrice = `AUD $${flightDeal.reserve_deal_price.toLocaleString(
    "en-AU",
    {
      style: "currency",
      currency: "AUD",
    }
  )}`;

  const handleSubmit = async (isReserve = false) => {
    if (guestAdultsInputValue <= 0) {
      setShowValidationMessage(true);
      return;
    }

    setShowValidationMessage(false);

    setIsCreatingOrder(true);

    const payload = {
      flight_deal_id: Number(flightDeal.id),
      adults: guestAdultsInputValue,
      children: guestChildrenInputValue,
      infants: guestInfantsInputValue,
      is_reserve_booking: isReserve,
    };

    try {
      const response = await createFlightDealBookingPending(payload);
      const orderNumber = response.order_number;

      // Update the flight deal's order number in the storage
      const localStorageData = localStorage.getItem("flightDeals");
      const flightDealData = localStorageData
        ? JSON.parse(localStorageData)
        : {};
      const updatedFlightDealData = {
        ...flightDealData,
        [flightDeal.id]: {
          guestAdults: guestAdultsInputValue,
          guestChildren: guestChildrenInputValue,
          guestInfants: guestInfantsInputValue,
          order_number: orderNumber,
        },
      };
      localStorage.setItem(
        "flightDeals",
        JSON.stringify(updatedFlightDealData)
      );

      navigate(`/special-flights-checkout/${flightDeal.id}/${orderNumber}`);
    } catch (error) {
      // Handle error
    } finally {
      setIsCreatingOrder(false);
    }
  };

  return (
    <>
      <div className="listingSectionSidebar__wrap shadow-xl">
        {/* PRICE */}
        <div className="flex justify-between">
          <span className="text-3xl font-semibold">
            ${flightDeal.new_price}
          </span>
          <div
            className={`nc-SaleOffBadge flex items-center justify-center text-xs py-0.5 px-3 bg-red-700 text-red-50 rounded-full line-through font-bold`}
            data-nc-id="SaleOffBadge"
          >
            ${flightDeal.old_price}
          </div>{" "}
        </div>

        {/* FORM */}
        <form className="flex flex-col border border-neutral-200 dark:border-neutral-700 rounded-3xl">
          <GuestsInput
            flightDeal={flightDeal}
            className="flex-1"
            guestData={{
              guestAdults: guestAdultsInputValue,
              guestChildren: guestChildrenInputValue,
              guestInfants: guestInfantsInputValue,
            }}
            handleGuestDataChange={handleGuestDataChange}
          />
        </form>
      </div>
      <div className=" shadow-xl mt-5">
        {isCreatingOrder && (
          <ButtonPrimary loading>In progress...</ButtonPrimary>
        )}

        {/* SUBMIT */}
        {!isCreatingOrder && (
          <div className="listingSectionSidebar__wrap shadow-xl">
            <div className="flex flex-col space-y-4">
              <h3 className="text-2xl font-semibold">Price Details</h3>
              <div className="flex justify-between font-semibold">
                <span>Total Price</span>
                <span>{formattedTotalPrice}</span>
              </div>
            </div>

            <ButtonPrimary onClick={() => handleSubmit(false)}>
              Book Now
            </ButtonPrimary>

            {flightDeal.allow_reserve_deal === 1 && (
              <div className="listingSectionSidebar__wrap shadow-xl mt-5">
                <ButtonPrimary onClick={() => handleSubmit(true)}>
                  Reserve for {formattedReservePrice}
                </ButtonPrimary>
              </div>
            )}
          </div>
        )}

        {showValidationMessage && (
          <p className="text-red-500 mt-2">
            Please add at least one adult passenger.
          </p>
        )}
      </div>
    </>
  );
};

export default Sidebar;
