import { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import { PostDataType } from "data/types";
import { Link } from "react-router-dom";

export interface Card12Props {
  className?: string;
  post: PostDataType;
}

const Card12: FC<Card12Props> = ({ className = "h-full", post }) => {
  const { title, id, banner_image, meta_description, slug } = post;

  return (
    <div
      className={`nc-Card12 group relative flex flex-col ${className}`}
      data-nc-id="Card12"
    >
      <Link
        to={`/blog/${id}/${slug}`}
        className="block flex-shrink-0 flex-grow relative w-full h-0 aspect-w-4 aspect-h-3 rounded-3xl overflow-hidden"
      >
        <NcImage
          containerClassName="absolute inset-0"
          src={banner_image}
          alt={title}
        />
      </Link>

      <div className=" mt-8 pr-10 flex flex-col">
        <h2
          className={`nc-card-title block font-semibold text-neutral-900 dark:text-neutral-100 transition-colors text-lg sm:text-2xl`}
        >
          <Link
            to={`/blog/${id}/${slug}`}
            className="line-clamp-2"
            title={title}
          >
            {title}
          </Link>
        </h2>
        <span className="hidden sm:block mt-4 text-neutral-500 dark:text-neutral-400">
          {/* <span className="line-clamp-2"> {meta_description}</span> */}
          <span> {meta_description}</span>
        </span>
      </div>
    </div>
  );
};

export default Card12;
