import React, { Fragment, FC, useState, useEffect } from "react";
import { Popover, Transition } from "@headlessui/react";
import { UserPlusIcon } from "@heroicons/react/24/outline";
import { GuestsObject } from "components/HeroSearchForm/type";
import ClearDataButton from "components/HeroSearchForm/ClearDataButton";
import NcInputNumber from "components/NcInputNumber/NcInputNumber";

export interface GuestsInputProps {
  className?: string;
  flightDeal: { available_tickets: number; id: number };
  guestData?: GuestsObject;
  handleGuestDataChange: (value: number, type: keyof GuestsObject) => void;
}

const GuestsInput: FC<GuestsInputProps> = ({
  className = "flex-1",
  flightDeal,
  guestData = {},
  handleGuestDataChange,
}) => {
  const [totalGuests, setTotalGuests] = useState(0);
  // const [totalChargeableGuests, setTotalChargeableGuests] = useState(0);
  const [totalAllowedChargeableGuests, setTotalAllowedChargeableGuests] =
    useState(0);
  const [error, setError] = useState("");

  // const [guestAdultsInputValue, setGuestAdultsInputValue] = useState(
  //   guestData.guestAdults || 0
  // );
  // const [guestChildrenInputValue, setGuestChildrenInputValue] = useState(
  //   guestData.guestChildren || 0
  // );
  // const [guestInfantsInputValue, setGuestInfantsInputValue] = useState(
  //   guestData.guestInfants || 0
  // );

  const handleChangeData = (value: number, type: keyof GuestsObject) => {
    handleGuestDataChange(value, type);
    setError("");

    // Save the new value in localStorage
    const flightDeals = JSON.parse(localStorage.getItem("flightDeals") || "{}");
    flightDeals[flightDeal.id] = {
      ...flightDeals[flightDeal.id],
      [type]: value,
    };
    localStorage.setItem("flightDeals", JSON.stringify(flightDeals));
  };

  useEffect(() => {
    const { guestAdults = 0, guestChildren = 0, guestInfants = 0 } = guestData;

    // Retrieve data from localStorage
    // const flightDeals = JSON.parse(localStorage.getItem("flightDeals") || "{}");
    // const savedGuestData = flightDeals[flightDeal.id] || {};

    // If localStorage data exists, use that. Otherwise use guestData prop.
    // setGuestAdultsInputValue(savedGuestData.guestAdults || guestAdults);
    // setGuestChildrenInputValue(savedGuestData.guestChildren || guestChildren);
    // setGuestInfantsInputValue(savedGuestData.guestInfants || guestInfants);

    const totalAllowedChargeableGuests =
      flightDeal.available_tickets < 9 ? flightDeal.available_tickets : 9;
    setTotalAllowedChargeableGuests(totalAllowedChargeableGuests);

    const totalGuests = guestAdults + guestChildren + guestInfants;
    setTotalGuests(totalGuests);

    const totalChargeableGuests = guestAdults + guestChildren;
    // setTotalChargeableGuests(totalChargeableGuests);

    if (totalChargeableGuests > flightDeal.available_tickets) {
      setError("Maximum number of tickets reached.");
    } else if (totalChargeableGuests > 9) {
      setError("Maximum number of tickets reached.");
    } else {
      setError("");
    }
  }, [guestData, flightDeal.available_tickets, flightDeal.id]);

  return (
    <Popover className={`flex relative ${className}`}>
      {({ open }) => (
        <>
          <div
            className={`flex-1 flex items-center focus:outline-none rounded-b-3xl ${
              open ? "shadow-lg" : ""
            }`}
          >
            <Popover.Button
              className={`relative z-10 flex-1 flex text-left items-center p-3 space-x-3 focus:outline-none`}
            >
              <div className="text-gray-300 dark:text-gray-400">
                <UserPlusIcon className="w-5 h-5 lg:w-7 lg:h-7" />
              </div>
              <div className="flex-grow">
                <span className="block xl:text-lg font-semibold">
                  {totalGuests || ""} Guests
                </span>
                <span className="block mt-1 text-sm text-gray-400 leading-none font-light">
                  {totalGuests ? "Guests" : "Add guests"}
                </span>
              </div>

              {!!totalGuests && open && (
                <ClearDataButton
                  onClick={() => {
                    handleGuestDataChange(0, "guestAdults");
                    handleGuestDataChange(0, "guestChildren");
                    handleGuestDataChange(0, "guestInfants");
                  }}
                />
              )}
            </Popover.Button>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute right-0 z-10 w-full sm:min-w-[340px] max-w-sm bg-white dark:bg-gray-800 top-full mt-3 py-5 sm:py-6 px-4 sm:px-8 rounded-3xl shadow-xl ring-1 ring-black ring-opacity-5">
              <NcInputNumber
                className="w-full"
                value={guestData.guestAdults || 0}
                onChange={(value) => handleChangeData(value, "guestAdults")}
                max={
                  totalAllowedChargeableGuests - (guestData.guestChildren || 0)
                }
                label="Adults"
                desc="Ages 13 or above"
              />
              <NcInputNumber
                className="w-full mt-6"
                value={guestData.guestChildren || 0}
                onChange={(value) => handleChangeData(value, "guestChildren")}
                max={
                  totalAllowedChargeableGuests - (guestData.guestAdults || 0)
                }
                label="Children"
                desc="Ages 2–12"
              />
              <NcInputNumber
                className="w-full mt-6"
                value={guestData.guestInfants || 0}
                onChange={(value) => handleChangeData(value, "guestInfants")}
                max={4}
                label="Infants"
                desc="Ages 0–2"
              />

              {error && <p className="mt-2 text-sm text-red-500">{error}</p>}
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default GuestsInput;
