import { TourType, TourAvailableDate, TourDepartureLocation } from "./_models";

// Define an interface for the parameters the function will accept
interface CalculateTotalParams {
  basePrice: string;
  tourTypes: TourType[];
  selectedTourTypeId: number | null;
  selectedDate: TourAvailableDate | undefined;
  departureLocations: TourDepartureLocation[] | undefined;
  selectedDepartureLocation: TourDepartureLocation | undefined;
  numberOfPassengers: number;
  additionalOptionsPrice?: number;
}

export function calculateTotal({
  basePrice,
  tourTypes,
  selectedTourTypeId,
  selectedDate,
  departureLocations,
  selectedDepartureLocation,
  numberOfPassengers,
  additionalOptionsPrice = 0,
}: CalculateTotalParams): number {
  const getAdditionalPriceForTourType = (): number => {
    const selectedType = tourTypes.find(
      (type) => type.id === selectedTourTypeId
    );
    return selectedType ? parseFloat(selectedType.additional_price) : 0;
  };

  const getAdditionalPriceForDate = (): number => {
    if (selectedDate) {
      return parseFloat(selectedDate.additional_price);
    }
    return 0;
  };

  const getAdditionalPriceForLocation = (): number => {
    const selectedLocation = departureLocations?.find(
      (location) =>
        location.location_name === selectedDepartureLocation?.location_name
    );
    return selectedLocation ? parseFloat(selectedLocation.additional_price) : 0;
  };

  // Calculate the total price
  const additionalPriceForTourType = getAdditionalPriceForTourType();
  const additionalPriceForDate = getAdditionalPriceForDate();
  const additionalPriceForLocation = getAdditionalPriceForLocation();
  const totalPricePerPassenger =
    parseFloat(basePrice) +
    additionalPriceForTourType +
    additionalPriceForDate +
    additionalPriceForLocation;

  const subTotalPrice = totalPricePerPassenger * numberOfPassengers;

  return subTotalPrice + additionalOptionsPrice;
}
