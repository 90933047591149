import React from "react";
import { ReactNode } from "react";

export interface Heading1Props {
  heading?: ReactNode;
  subHeading?: ReactNode;
  className?: string;
}

const Heading1: React.FC<Heading1Props> = ({
  className = "",
  heading = "",
  subHeading,
}) => {
  return (
    <div className={`mb-12 lg:mb-16 ${className}`}>
      <br />
      <br />
      <br />
      <h1 className="text-4xl font-semibold">{heading}</h1>
      {subHeading ? (
        <h2 className="block text-neutral-500 dark:text-neutral-400 mt-3">
          {subHeading}
          {/* <span className="mx-2">·</span>
          Aug 12 - 18
          <span className="mx-2">·</span>2 Guests */}
        </h2>
      ) : (
        ""
      )}
    </div>
  );
};

export default Heading1;
