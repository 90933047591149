import { CustomLink } from "data/types";
import  { FC, useEffect, useState } from "react";
// import { Link } from "react-router-dom";
// import axios from "axios";
import twFocusClass from "utils/twFocusClass";

export interface PaginationProps {
  className?: string;
  totalItems: number;
  itemsPerPage: number;
  currentPage: number;
  onChangePage: (page: number) => void;
}

const Pagination: FC<PaginationProps> = ({
  className = "",
  totalItems,
  itemsPerPage,
  currentPage,
  onChangePage,
}) => {
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    const calculateTotalPages = () => {
      const pages = Math.ceil(totalItems / itemsPerPage);
      setTotalPages(pages);
    };

    calculateTotalPages();
  }, [totalItems, itemsPerPage]);

  const generatePaginationItems = () => {
    const paginationItems: CustomLink[] = [];
    for (let i = 1; i <= totalPages; i++) {
      paginationItems.push({
        label: i.toString(),
        href: "#",
      });
    }
    return paginationItems;
  };

  const handlePageChange = (page: number) => {
    onChangePage(page);
  };

  const renderItem = (pag: CustomLink, index: number) => {
    const isActive = currentPage === index + 1;
    const itemClass = isActive
      ? `inline-flex w-11 h-11 items-center justify-center rounded-full bg-primary-6000 text-white ${twFocusClass()}`
      : `inline-flex w-11 h-11 items-center justify-center rounded-full bg-white hover:bg-neutral-100 border border-neutral-200 text-neutral-6000 dark:text-neutral-400 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:border-neutral-700 ${twFocusClass()}`;

    return (
      <button
        key={index}
        className={itemClass}
        onClick={() => handlePageChange(index + 1)}
      >
        {pag.label}
      </button>
    );
  };

  return (
    <nav
      className={`nc-Pagination inline-flex space-x-1 text-base font-medium ${className}`}
    >
      {generatePaginationItems().map(renderItem)}
    </nav>
  );
};

export default Pagination;
