import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;
const CERATE_TOUR_BOOKING_PENDING = `${API_URL}/tour_booking_pending/add`;
const GET_TOUR_BOOKING_PENDING = `${API_URL}/tour_booking_pending/get`;

interface TourBookingPendingResponse {
  id: number;
  tour_id: number;
  order_number: string;
}

interface Tour {
  id: number;
  title: string;
  arrival_country: string;
  arrival_city: string;
  child_price: number;
  infant_price: number;
}

interface ErrorResponse {
  error: string;
}

interface GetTourBookingPendingResponse {
  bookingId: number;
  bookingTourId: number;
  bookingOrderNumber: string;
  adults: number;
  children: number;
  infants: number;
  total_price: number;
  tour: Tour;
}

export async function createTourBookingPending(
  payload: any
): Promise<TourBookingPendingResponse> {
  try {
    const response = await axios.post<TourBookingPendingResponse>(
      CERATE_TOUR_BOOKING_PENDING,
      payload
    );
    return response.data;
  } catch (error) {
    throw new Error("Failed to create tour booking pending");
  }
}

export const getTourBookingPending = async (
  tourId: string,
  bookingNumber: string
): Promise<GetTourBookingPendingResponse | ErrorResponse> => {
  try {
    const response = await axios.get<GetTourBookingPendingResponse>(
      `${GET_TOUR_BOOKING_PENDING}/${tourId}/${bookingNumber}`
    );
    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
    // return { error: "Failed to retrieve tour booking pending data" };
  }
};
