import React, { FC, ReactNode, useEffect, useState } from "react";
import { DestinationDataType } from "data/types";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import HeaderFilter from "./HeaderFilter";
import DestinationCard from "components/DestinationCard/DestinationCard";
import { fetchBlogArticles } from "../../data/blogArticle/_requests";

export interface SectionGridPopularDestinationsProps {
  gridClass?: string;
  heading?: ReactNode;
  subHeading?: ReactNode;
}

const SectionGridPopularDestinations: FC<
  SectionGridPopularDestinationsProps
> = ({
  gridClass = "",
  heading = "Latest Articles",
  subHeading = "Latest articles all about Sri Lanka",
}) => {
  const [loading, setLoading] = useState(true);
  const [blogArticles, setBlogArticles] = useState<DestinationDataType[]>([]);

  useEffect(() => {
    const fetchBlogArticlesData = async () => {
      try {
        setLoading(true);
        const response = await fetchBlogArticles({
          limit: 8, // Set the limit to 8
        });
        setBlogArticles(response.data.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching blog articles:", error);
      }
    };

    fetchBlogArticlesData();
  }, []);

  const renderCard = (blogArticle: DestinationDataType, index: number) => {
    return <DestinationCard key={index} index={index} data={blogArticle} />;
  };

  return (
    <div className="nc-SectionGridPopularDestinations relative">
      <HeaderFilter
        tabActive={""}
        subHeading={subHeading}
        tabs={[]}
        heading={heading}
        onClickTab={() => {}}
      />
      {loading ? (
        <div>Loading blog articles...</div>
      ) : (
        <div
          className={`grid gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 ${gridClass}`}
        >
          {blogArticles.map((blogArticle, index) =>
            renderCard(blogArticle, index)
          )}
        </div>
      )}
      <div className="flex mt-16 justify-center items-center">
        <ButtonPrimary href="/blog">Show me more</ButtonPrimary>
      </div>
    </div>
  );
};

export default SectionGridPopularDestinations;
