import { FC, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Helmet } from "react-helmet";
import { useFormik } from "formik";
import * as Yup from "yup";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import {
  addRefundForm,
  validateRefundForm,
  Booking,
} from "../../data/refund/_requests";

export interface PageRefundRequestProps {
  className?: string;
}

const info = [
  {
    icon: "la la-map-marked",
    desc: "No 13, Linden Tree Way Cranbourne North, VIC 3977, Australia",
  },
  {
    icon: "la la-mail-bulk",
    desc: "info@myflightzone.com.au",
  },
  {
    icon: "la la-phone",
    desc: "+61 452 365 24 7",
  },
  {
    icon: "la la-fax",
    desc: "+61 398 0322 44",
  },
  {
    icon: "lab la-whatsapp",
    desc: "+61 452 365 24 7",
  },
  {
    icon: "lab la-viber",
    desc: "+61 452 365 24 7",
  },
];

const PageRefundRequest: FC<PageRefundRequestProps> = ({ className = "" }) => {
  const [submitting, setSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [bookings, setBookings] = useState<Booking[]>([]);
  const [selectedBookings, setSelectedBookings] = useState<Booking[]>([]);
  const [isFormVisible, setIsFormVisible] = useState(true);

  const reCaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY
    ? process.env.REACT_APP_RECAPTCHA_SITE_KEY
    : "";

  const validationSchema = Yup.object().shape({
    booking_reference: Yup.string().required("E-ticket number is required"),
    last_name: Yup.string().required("Last name is required"),
    refund_reason: Yup.string().required("Refund reason is required"),
    recaptcha: Yup.string().required("Please complete the reCAPTCHA"),
  });

  const handleSubmit = async (values: any) => {
    try {
      setSubmitting(true);
      setSuccessMessage("");
      setErrorMessage("");

      const recaptchaResponse = formik.values.recaptcha;

      if (!recaptchaResponse) {
        setErrorMessage("Please complete the reCAPTCHA verification.");
        return;
      }

      const response = await validateRefundForm(values);
      if ("bookings" in response && response.bookings) {
        setBookings(response.bookings || []);
        setIsFormVisible(false); // Hide the initial form
      } else {
        setErrorMessage("Failed to validate the form. Please try again later.");
      }
    } catch (error: any) {
      setErrorMessage(error.message);
    } finally {
      setSubmitting(false);
    }
  };

  const handleCheckboxChange = (booking: Booking) => {
    const selectedBookings = bookingsFormik.values.selectedBookings;
    bookingsFormik.setFieldValue(
      "selectedBookings",
      selectedBookings.includes(booking)
        ? selectedBookings.filter(
            (b) => b.booking_reference !== booking.booking_reference
          )
        : [...selectedBookings, booking]
    );
  };

  const formik = useFormik({
    initialValues: {
      booking_reference: "",
      last_name: "",
      refund_reason: "",
      recaptcha: "",
    },
    validationSchema,
    onSubmit: handleSubmit,
  });

  // New formik instance for the second form
  const bookingsFormik = useFormik({
    initialValues: {
      selectedBookings: [] as Booking[],
      no_show_terms_accepted: false,
      refund_terms_accepted: false,
    },
    validationSchema: Yup.object().shape({
      selectedBookings: Yup.array().min(
        1,
        "Please select at least one booking."
      ),
      no_show_terms_accepted: Yup.boolean().oneOf(
        [true],
        "You must confirm the no-show policy"
      ),
      refund_terms_accepted: Yup.boolean().oneOf(
        [true],
        "You must accept the terms and conditions"
      ),
    }),
    onSubmit: async (values) => {
      try {
        setSubmitting(true);
        setSuccessMessage("");
        setErrorMessage("");
        console.log("values ::: ", values);
        console.log("selectedBookings ::: ", selectedBookings);
        const payload = {
          bookings: values.selectedBookings.map((booking) => ({
            booking_reference: booking.booking_reference,
            last_name: booking.last_name,
            refund_reason: formik.values.refund_reason,
          })),
          no_show_terms_accepted: values.no_show_terms_accepted,
          refund_terms_accepted: values.refund_terms_accepted,
        };
        console.log("payload ::: ", payload);
        const response = await addRefundForm(payload);
        setSuccessMessage(
          response.message || "Refund request submitted successfully."
        );
        // Reset the forms and state
        formik.resetForm();
        bookingsFormik.resetForm();
        setBookings([]);
        setSelectedBookings([]);
        setIsFormVisible(true); // Show the initial form again after submission
      } catch (error: any) {
        setErrorMessage(error.message);
      } finally {
        setSubmitting(false);
      }
    },
  });
  return (
    <div
      className={`nc-PageRefundRequest overflow-hidden ${className}`}
      data-nc-id="PageRefundRequest"
    >
      <Helmet>
        <title>Refund Request</title>

        <meta
          name="description"
          content="Are you looking to travel somewhere? Not sure where to go? Want to make your tour plan with the travelling specialist? You got us. My Flight Zone is ready to offer the support. If you have any queries, don't hesitate to contact My Flight Zone. We are looking forward to providing you with clear solutions & ultimately clearing all your doubts."
        />
      </Helmet>
      <div className="mb-24 lg:mb-32">
        <h1 className="my-16 sm:my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Refund Request
        </h1>
        <div className="container max-w-7xl mx-auto">
          <div className="flex-shrink-0 grid grid-cols-1 sm:grid-cols-2 gap-12 ">
            <div className="max-w-sm space-y-8">
              {info.map((item, index) => (
                <div key={index}>
                  <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                    <i className={item.icon} style={{ fontSize: "24px" }}></i>
                    &nbsp;&nbsp;
                    {item.desc}
                  </span>
                </div>
              ))}
            </div>

            <div className="p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800">
              {successMessage && (
                <div className="text-green-500">{successMessage}</div>
              )}
              {errorMessage && (
                <div className="text-red-500">
                  {errorMessage}
                  <br /> If you believe this is a mistake, please{" "}
                  <strong>
                    <u>
                      <a href="contact?type=Refund">click here</a>
                    </u>
                  </strong>{" "}
                  to send us a message, and we will get back to you as soon as
                  possible
                </div>
              )}
              {isFormVisible && (
                <form
                  className="grid grid-cols-1 gap-6"
                  onSubmit={formik.handleSubmit}
                >
                  <label className="block">
                    <Label>
                      E-ticket No (Please enter the last 10 digits of your
                      E-ticket)
                    </Label>
                    <Input
                      name="booking_reference"
                      placeholder="XXXXXX"
                      type="text"
                      className="mt-1"
                      value={formik.values.booking_reference}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.booking_reference &&
                      formik.errors.booking_reference && (
                        <div className="text-red-500">
                          {formik.errors.booking_reference}
                        </div>
                      )}
                  </label>
                  <label className="block">
                    <Label>Last name as per the E-ticket</Label>
                    <Input
                      name="last_name"
                      type="text"
                      placeholder="XXX XXX"
                      className="mt-1"
                      value={formik.values.last_name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.last_name && formik.errors.last_name && (
                      <div className="text-red-500">
                        {formik.errors.last_name}
                      </div>
                    )}
                  </label>
                  <label className="block">
                    <Label>Refund Reason</Label>
                    <select
                      name="refund_reason"
                      className="form-select form-select-solid form-select-lg"
                      value={formik.values.refund_reason}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      <option value="">-- Please Select --</option>
                      <option value="voluntary_cancellation_of_travel_plan">
                        Voluntary Cancellation of Travel Plan
                      </option>
                      <option value="downgrades">Downgrades</option>
                      <option value="illness_with_medical_certificate">
                        Illness (with Medical Certificate)
                      </option>
                      <option value="death_with_death_certificate">
                        Death (with Death Certificate)
                      </option>
                      <option value="flight_rescheduled_cancelled_by_airline">
                        Flight Rescheduled/Cancelled by Airline
                      </option>
                      <option value="natural_disaster_global_crisis_political_unrest">
                        Natural Disaster/Global Crisis/Political Unrest
                      </option>
                      <option value="new_ticket_issued">
                        New Ticket Issued (e.g., Name Change/Upgrade of Fare)
                      </option>
                      <option value="other">Other</option>
                    </select>
                    {formik.touched.refund_reason &&
                      formik.errors.refund_reason && (
                        <div className="text-red-500">
                          {formik.errors.refund_reason}
                        </div>
                      )}
                  </label>
                  <label className="block">
                    <Label>reCAPTCHA</Label>
                    <ReCAPTCHA
                      type="image"
                      {...formik.getFieldProps("recaptcha")}
                      sitekey={reCaptchaSiteKey}
                      onChange={(response) =>
                        formik.setFieldValue("recaptcha", response)
                      }
                      onBlur={() => formik.setFieldTouched("recaptcha", true)}
                      onExpired={() => formik.setFieldValue("recaptcha", "")}
                    />

                    {formik.touched.recaptcha && !formik.values.recaptcha && (
                      <div className="text-red-500">
                        Please complete the reCAPTCHA.
                      </div>
                    )}
                  </label>
                  <div>
                    <ButtonPrimary type="submit" disabled={submitting}>
                      {submitting ? "Submitting..." : "Send Request"}
                    </ButtonPrimary>
                  </div>
                </form>
              )}
              {bookings.length > 0 && (
                <div className="mt-6">
                  <h2 className="text-xl font-semibold">Select Bookings</h2>
                  <form
                    className="grid grid-cols-1 gap-4"
                    onSubmit={bookingsFormik.handleSubmit}
                  >
                    {bookings.map((booking) => (
                      <label
                        key={booking.booking_reference}
                        className="flex items-center"
                      >
                        <input
                          type="checkbox"
                          value={booking.booking_reference}
                          onChange={() => handleCheckboxChange(booking)}
                          checked={bookingsFormik.values.selectedBookings.includes(
                            booking
                          )}
                        />
                        <span className="ml-2">
                          {booking.booking_reference} - {booking.last_name}
                        </span>
                      </label>
                    ))}
                    {bookingsFormik.touched.selectedBookings &&
                      bookingsFormik.errors.selectedBookings && (
                        <div className="text-red-500">
                          {bookingsFormik.errors.selectedBookings as string}
                        </div>
                      )}
                    <label className="block mt-4">
                      <div className="flex items-center">
                        <input
                          type="checkbox"
                          {...bookingsFormik.getFieldProps(
                            "no_show_terms_accepted"
                          )}
                        />
                        <span className="ml-2">
                          I confirm that my ticket is not a no-show. I am
                          submitting this flight schedule 72 hours in advance
                          and authorise you to cancel the booking to avoid the
                          no-show penalty. Within 72 hours, tickets can be
                          considered no-shows, and some may be non-refundable.
                        </span>
                      </div>
                      {bookingsFormik.touched.no_show_terms_accepted &&
                        bookingsFormik.errors.no_show_terms_accepted && (
                          <div className="text-red-500">
                            {bookingsFormik.errors.no_show_terms_accepted}
                          </div>
                        )}
                    </label>

                    <label className="block mt-4">
                      <div className="flex items-center">
                        <input
                          type="checkbox"
                          {...bookingsFormik.getFieldProps(
                            "refund_terms_accepted"
                          )}
                        />
                        <span className="ml-2">
                          I have read and accepted the{" "}
                          <strong>
                            <u>
                              <a
                                href="/terms-and-conditions?section=refund"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Terms and Conditions
                              </a>
                            </u>
                          </strong>
                        </span>
                      </div>
                      {bookingsFormik.touched.refund_terms_accepted &&
                        bookingsFormik.errors.refund_terms_accepted && (
                          <div className="text-red-500">
                            {bookingsFormik.errors.refund_terms_accepted}
                          </div>
                        )}
                    </label>
                    <div>
                      <ButtonPrimary type="submit" disabled={submitting}>
                        {submitting ? "Submitting..." : "Submit Refund Request"}
                      </ButtonPrimary>
                    </div>
                  </form>
                </div>
              )}
              <br />
              <p>
                Do you have an existing refund request? Click{" "}
                <strong>
                  <u>
                    <a href="/refund-status" title="Refund Request Status">
                      here
                    </a>
                  </u>
                </strong>{" "}
                to check the status.
              </p>
            </div>
          </div>

          <div
            className={`nc-SectionOurDifference relative mt-20 ${className} `}
            data-nc-id="SectionOurDifference"
          >
            <div className={`nc-SectionStatistic relative ${className}`}>
              <div className="grid md:grid-cols-2 gap-6 lg:grid-cols-3 xl:gap-8">
                <div className="p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800">
                  <h3 className="text-2xl font-semibold leading-none text-neutral-900 md:text-3xl dark:text-neutral-200">
                    AUSTRALIA
                  </h3>
                  <span className="block text-sm text-neutral-500 mt-3 sm:text-base dark:text-neutral-400">
                    <ul>
                      <li>
                        <i className="la la-map-marked"></i> No 13, Linden Tree
                        Way Cranbourne North, VIC 3977, Australia
                      </li>
                      <li className="mt-5">
                        <i className="la la la-phone"></i>{" "}
                        <a href="tel:+61452365247">+61 452 365 24 7</a>
                      </li>
                      <li className="mt-5">
                        <i className="la la la-mail-bulk"></i>{" "}
                        <a href="mailto:info@myflightzone.com.au">
                          info@myflightzone.com.au
                        </a>
                      </li>
                    </ul>
                  </span>
                </div>
                <div className="p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800">
                  <h3 className="text-2xl font-semibold leading-none text-neutral-900 md:text-3xl dark:text-neutral-200">
                    SRI LANKA
                  </h3>
                  <span className="block text-sm text-neutral-500 mt-3 sm:text-base dark:text-neutral-400">
                    <ul>
                      <li>
                        <i className="la la-map-marked"></i> No: 37/1, Colombo
                        Road, Kurana, Katunayake,Sri Lanka
                      </li>
                      <li className="mt-5">
                        <i className="la la la-phone"></i>{" "}
                        <a href="tel:+94117365247">+94 117 365 24 7</a>
                      </li>
                      <li className="mt-5">
                        <i className="la la la-mail-bulk"></i>{" "}
                        <a href="mailto:info@myflightzone.com.au">
                          info@myflightzone.com.au
                        </a>
                      </li>
                    </ul>
                  </span>
                </div>
                <div className="p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800">
                  <h3 className="text-2xl font-semibold leading-none text-neutral-900 md:text-3xl dark:text-neutral-200">
                    New Zealand
                  </h3>
                  <span className="block text-sm text-neutral-500 mt-3 sm:text-base dark:text-neutral-400">
                    <ul>
                      <li>
                        <i className="la la-map-marked"></i> 15 Victoria Road,
                        Devenport, New Zealand
                      </li>
                      <li className="mt-5">
                        <i className="la la la-phone"></i>{" "}
                        <a href="tel:+64273365247">+64 27 336 5247</a>
                      </li>
                      <li className="mt-5">
                        <i className="la la la-mail-bulk"></i>{" "}
                        <a href="mailto:info@myflightzone.com.au">
                          info@myflightzone.com.au
                        </a>
                      </li>
                    </ul>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageRefundRequest;
