import React from "react";
import IframeResizer from "iframe-resizer-react";

const FlightSearch = () => {
  return (
    <div className="pb-10 pl-5 pr-5 w-full relative mt-8 rounded-[40px] xl:rounded-[49px] rounded-t-2xl xl:rounded-t-3xl shadow-xl dark:shadow-2xl bg-white dark:bg-neutral-800">
      <IframeResizer
        log={false}
        src="/deal_search/index.html"
        style={{ width: "1px", minWidth: "100%", border: "none" }}
        autoResize
        heightCalculationMethod="lowestElement"
        scrolling={true}
      />
    </div>
  );
};

export default FlightSearch;
