import { FC } from "react";
import DealSearch from "components/DealSearch/DealSearch";

export interface SectionHeroProps {
  className?: string;
}

const SectionHero: FC<SectionHeroProps> = ({ className = "" }) => {
  return (
    <DealSearch />
    // <div
    //   className={`nc-SectionHero flex flex-col-reverse lg:flex-col relative ${className}`}
    //   data-nc-id="SectionHero"
    // >
    //   <div className="flex flex-col lg:flex-row lg:items-center">
    //     <div className="flex-shrink-0 lg:w-1/2 flex flex-col items-start space-y-8 sm:space-y-10 pb-14 lg:pb-64 xl:pr-14 lg:mr-10 xl:mr-0"></div>
    //   </div>

    //   <div className="z-10 mb-12 lg:mb-0 lg:-mt-40 w-full">
    //     <DealSearch />
    //   </div>
    // </div>
  );
};

export default SectionHero;
