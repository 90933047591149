import { FC, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Helmet } from "react-helmet";
import { useFormik } from "formik";
import * as Yup from "yup";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { sendContactForm } from "../../data/contact/_requests";
import { useLocation } from "react-router-dom";

export interface PageContactProps {
  className?: string;
}

const info = [
  {
    icon: "la la-map-marked",
    desc: "No 13, Linden Tree Way Cranbourne North, VIC 3977, Australia",
  },
  {
    icon: "la la-mail-bulk",
    desc: "info@myflightzone.com.au",
  },
  {
    icon: "la la-phone",
    desc: "+61 452 365 24 7",
  },
  {
    icon: "la la-fax",
    desc: "+61 398 0322 44",
  },
  {
    icon: "lab la-whatsapp",
    desc: "+61 452 365 24 7",
  },
  {
    icon: "lab la-viber",
    desc: "+61 452 365 24 7",
  },
];

const PageContact: FC<PageContactProps> = ({ className = "" }) => {
  const [submitting, setSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const location = useLocation();

  const reCaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY
    ? process.env.REACT_APP_RECAPTCHA_SITE_KEY
    : "";

  const queryParams = new URLSearchParams(location.search);
  const enquiryTypeFromQuery = queryParams.get("type") || "";

  const validationSchema = Yup.object().shape({
    fullName: Yup.string().required("Full name is required"),
    emailAddress: Yup.string()
      .email("Invalid email address")
      .required("Email address is required"),
    phoneNumber: Yup.string().required("Phone number is required"),
    enquiryType: Yup.string().required("Enquiry type is required"),
    message: Yup.string().required("Message is required"),
    recaptcha: Yup.string().required("Please complete the reCAPTCHA"),
  });

  const handleSubmit = async (values: any) => {
    try {
      setSubmitting(true);
      setSuccessMessage("");
      setErrorMessage("");

      const recaptchaResponse = formik.values.recaptcha;

      if (!recaptchaResponse) {
        setErrorMessage("Please complete the reCAPTCHA verification.");
        return;
      }

      // Make API call using Axios or fetch
      const response = await sendContactForm(values);

      if (response.message) {
        setSuccessMessage(response.message);
      } else {
        setErrorMessage("Failed to submit the form. Please try again later.");
      }
    } catch (error) {
      console.log("error :: ", error);
      setErrorMessage("An error occurred while submitting the form.");
    } finally {
      setSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      fullName: "",
      emailAddress: "",
      phoneNumber: "",
      enquiryType: enquiryTypeFromQuery,
      message: "",
      recaptcha: "",
    },
    validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <div
      className={`nc-PageContact overflow-hidden ${className}`}
      data-nc-id="PageContact"
    >
      <Helmet>
        <title>
          Contact Us - Your Trustworthy Holiday Planner | My Flight Zone
        </title>

        <meta
          name="description"
          content="Are you looking to travel somewhere? Not sure where to go? Want to make your tour plan with the travelling specialist? You got us. My Flight Zone is ready to offer the support. If you have any queries, don't hesitate to contact My Flight Zone. We are looking forward to providing you with clear solutions & ultimately clearing all your doubts."
        />
      </Helmet>
      <div className="mb-24 lg:mb-32">
        <h1 className="my-16 sm:my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Contact
        </h1>
        <div className="container max-w-7xl mx-auto">
          <div className="flex-shrink-0 grid grid-cols-1 sm:grid-cols-2 gap-12 ">
            <div className="max-w-sm space-y-8">
              {info.map((item, index) => (
                <div key={index}>
                  <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                    <i className={item.icon} style={{ fontSize: "24px" }}></i>
                    &nbsp;&nbsp;
                    {item.desc}
                  </span>
                </div>
              ))}
            </div>
            <div>
              {successMessage && (
                <div className="text-green-500">{successMessage}</div>
              )}
              {errorMessage && (
                <div className="text-red-500">{errorMessage}</div>
              )}
              <form
                className="grid grid-cols-1 gap-6"
                onSubmit={formik.handleSubmit}
              >
                <label className="block">
                  <Label>Full name</Label>
                  <Input
                    name="fullName"
                    placeholder="Example Doe"
                    type="text"
                    className="mt-1"
                    value={formik.values.fullName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.fullName && formik.errors.fullName && (
                    <div className="text-red-500">{formik.errors.fullName}</div>
                  )}
                </label>
                <label className="block">
                  <Label>Email address</Label>
                  <Input
                    name="emailAddress"
                    type="email"
                    placeholder="example@example.com"
                    className="mt-1"
                    value={formik.values.emailAddress}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.emailAddress &&
                    formik.errors.emailAddress && (
                      <div className="text-red-500">
                        {formik.errors.emailAddress}
                      </div>
                    )}
                </label>
                <label className="block">
                  <Label>Phone number</Label>
                  <Input
                    name="phoneNumber"
                    type="text"
                    placeholder="+61 452 365 24 7"
                    className="mt-1"
                    value={formik.values.phoneNumber}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                    <div className="text-red-500">
                      {formik.errors.phoneNumber}
                    </div>
                  )}
                </label>
                <label className="block">
                  <Label>Enquiry Type</Label>
                  <select
                    className="mt-1"
                    {...formik.getFieldProps("enquiryType")}
                  >
                    <option value="" disabled>
                      Select an option
                    </option>
                    <option value="Airline tickets">Airline tickets</option>
                    <option value="Holidays">Holidays</option>
                    <option value="Accounts">Accounts</option>
                    <option value="Support">Support</option>
                    <option value="Refund">Refund</option>
                    <option value="Other">Other</option>
                  </select>
                  {formik.touched.enquiryType && formik.errors.enquiryType && (
                    <div className="text-red-500">
                      {formik.errors.enquiryType}
                    </div>
                  )}
                </label>

                <label className="block">
                  <Label>Message</Label>
                  <Textarea
                    name="message"
                    className="mt-1"
                    rows={6}
                    value={formik.values.message}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.message && formik.errors.message && (
                    <div className="text-red-500">{formik.errors.message}</div>
                  )}
                </label>

                <label className="block">
                  <Label>reCAPTCHA</Label>
                  <ReCAPTCHA
                    type="image"
                    {...formik.getFieldProps("recaptcha")}
                    sitekey={reCaptchaSiteKey}
                    onChange={(response) =>
                      formik.setFieldValue("recaptcha", response)
                    }
                    onBlur={() => formik.setFieldTouched("recaptcha", true)}
                    onExpired={() => formik.setFieldValue("recaptcha", "")}
                  />

                  {formik.touched.recaptcha && !formik.values.recaptcha && (
                    <div className="text-red-500">
                      Please complete the reCAPTCHA.
                    </div>
                  )}
                </label>
                <div>
                  <ButtonPrimary type="submit" disabled={submitting}>
                    {submitting ? "Loading..." : "Send Message"}
                  </ButtonPrimary>
                </div>
              </form>
            </div>
          </div>

          <div
            className={`nc-SectionOurDifference relative mt-20 ${className} `}
            data-nc-id="SectionOurDifference"
          >
            <div className={`nc-SectionStatistic relative ${className}`}>
              <div className="grid md:grid-cols-2 gap-6 lg:grid-cols-3 xl:gap-8">
                <div className="p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800">
                  <h3 className="text-2xl font-semibold leading-none text-neutral-900 md:text-3xl dark:text-neutral-200">
                    AUSTRALIA
                  </h3>
                  <span className="block text-sm text-neutral-500 mt-3 sm:text-base dark:text-neutral-400">
                    <ul>
                      <li>
                        <i className="la la-map-marked"></i> No 13, Linden Tree
                        Way Cranbourne North, VIC 3977, Australia
                      </li>
                      <li className="mt-5">
                        <i className="la la la-phone"></i>{" "}
                        <a href="tel:+61452365247">+61 452 365 24 7</a>
                      </li>
                      <li className="mt-5">
                        <i className="la la la-mail-bulk"></i>{" "}
                        <a href="mailto:info@myflightzone.com.au">
                          info@myflightzone.com.au
                        </a>
                      </li>
                    </ul>
                  </span>
                </div>
                <div className="p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800">
                  <h3 className="text-2xl font-semibold leading-none text-neutral-900 md:text-3xl dark:text-neutral-200">
                    SRI LANKA
                  </h3>
                  <span className="block text-sm text-neutral-500 mt-3 sm:text-base dark:text-neutral-400">
                    <ul>
                      <li>
                        <i className="la la-map-marked"></i> No: 37/1, Colombo
                        Road, Kurana, Katunayake,Sri Lanka
                      </li>
                      <li className="mt-5">
                        <i className="la la la-phone"></i>{" "}
                        <a href="tel:+94117365247">+94 117 365 247</a>
                      </li>
                      <li className="mt-5">
                        <i className="la la la-mail-bulk"></i>{" "}
                        <a href="mailto:info@myflightzone.com.au">
                          info@myflightzone.com.au
                        </a>
                      </li>
                    </ul>
                  </span>
                </div>
                <div className="p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800">
                  <h3 className="text-2xl font-semibold leading-none text-neutral-900 md:text-3xl dark:text-neutral-200">
                    New Zealand
                  </h3>
                  <span className="block text-sm text-neutral-500 mt-3 sm:text-base dark:text-neutral-400">
                    <ul>
                      <li>
                        <i className="la la-map-marked"></i> 15 Victoria Road,
                        Devenport, New Zealand
                      </li>
                      <li className="mt-5">
                        <i className="la la la-phone"></i>{" "}
                        <a href="tel:+64273365247">+64 27 336 5247</a>
                      </li>
                      <li className="mt-5">
                        <i className="la la la-mail-bulk"></i>{" "}
                        <a href="mailto:info@myflightzone.com.au">
                          info@myflightzone.com.au
                        </a>
                      </li>
                    </ul>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageContact;
