import DatePickerCustomDay from "./DatePickerCustomDay";
import DatePickerCustomHeaderTwoMonth from "./DatePickerCustomHeaderTwoMonth";
import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import { fetchFlightDealPricesByDateRange } from "../../data/flightDeal/_requests";

interface SectionDateRangeProps {
  startDate: Date | null;
  endDate: Date | null;
  departureAirport: string;
  arrivalAirport: string;
}

interface FlightDeal {
  id: string;
  new_price: number;
  child_price: number;
  infant_price: number;
  departure_datetime: string;
  slug: string;
}

const SectionDateRange: React.FC<SectionDateRangeProps> = ({
  startDate: initialStartDate,
  endDate: initialEndDate,
  departureAirport,
  arrivalAirport,
}) => {
  const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(
    initialStartDate
  );
  const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(
    initialEndDate
  );

  const [flightDeals, setFlightDeals] = useState<FlightDeal[]>([]);

  useEffect(() => {
    setSelectedStartDate(initialStartDate);
    setSelectedEndDate(initialEndDate);

    const flightDealsByDateRange = async () => {
      // Make an API call to fetch flight deals for the selected date range and airports
      if (selectedStartDate && selectedEndDate) {
        const data = await fetchFlightDealPricesByDateRange({
          departureAirport: departureAirport,
          arrivalAirport: arrivalAirport,
          startDate: selectedStartDate,
          endDate: selectedEndDate,
        });
        setFlightDeals(data);
      }
    };

    flightDealsByDateRange();
  }, [initialStartDate, initialEndDate, departureAirport, arrivalAirport]);

  const onChangeDate = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setSelectedStartDate(start);
    setSelectedEndDate(end);
  };

  const renderSectionCheckIndate = () => {
    return (
      <div className="listingSection__wrap overflow-hidden">
        {/* HEADING */}
        <div>
          <h2 className="text-2xl font-semibold">Availability</h2>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* CONTENT */}
        <div className="">
          <DatePicker
            selected={selectedStartDate}
            onChange={onChangeDate}
            startDate={selectedStartDate}
            endDate={selectedEndDate}
            selectsRange
            monthsShown={2}
            showPopperArrow={false}
            inline
            renderCustomHeader={(p) => (
              <DatePickerCustomHeaderTwoMonth {...p} />
            )}
            renderDayContents={(day, date) => {
              if (date) {
                const flightDeal = flightDeals.find(
                  (deal) =>
                    new Date(deal.departure_datetime).toDateString() ===
                    date.toDateString()
                );

                if (flightDeal) {
                  return (
                    <a
                      href={`/special-flight/${flightDeal.id}/${flightDeal.slug}`}
                    >
                      <div
                        style={{ position: "relative", height: "100%" }}
                        className="cursor-pointer"
                      >
                        <div
                          style={{
                            position: "absolute",
                            top: 0,
                            width: "100%",
                            fontSize: "0.7em",
                            textAlign: "center",
                          }}
                          className="text-red-50 font-bold "
                        >
                          ${flightDeal.new_price}
                        </div>
                        <DatePickerCustomDay dayOfMonth={day} date={date} />
                      </div>
                    </a>
                  );
                }
              }

              return (
                <div style={{ position: "relative", height: "100%" }}>
                  <DatePickerCustomDay dayOfMonth={day} date={date} />
                </div>
              );
            }}
          />
        </div>
      </div>
    );
  };

  return renderSectionCheckIndate();
};

export default SectionDateRange;
