import React, { useState, useEffect } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import GuestsInput from "./GuestsInput";
import { GuestsObject } from "components/HeroSearchForm/type";
import { useNavigate } from "react-router-dom";
import RadioButton from "shared/RadioButton/RadioButton";
import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Tour, TourAvailableDate, TourDepartureLocation } from "../_models";
import moment from "moment";
import * as yup from "yup";
import { calculateTotal } from "../calculateTotal";

type FormErrorsType = {
  [key: string]: string | undefined;
};

interface SidebarProps {
  tour: Tour;
  guestAdultsInputValue: number;
  guestChildrenInputValue: number;
  guestInfantsInputValue: number;
  handleGuestDataChange: (value: number, type: keyof GuestsObject) => void;
}

type MonthsData = Record<string, TourAvailableDate[]>;
type YearsData = Record<string, MonthsData>;

const validationSchema = yup.object().shape({
  guestAdultsInputValue: yup
    .number()
    .positive("At least one adult guest is required")
    .required("At least one adult guest is required"),
  selectedTourTypeId: yup.number().required("Tour type is required"),
  selectedDate: yup.string().required("Tour date is required"),
  selectedDepartureLocation: yup
    .string()
    .required("Departure location is required"),
});

const Sidebar: React.FC<SidebarProps> = ({
  tour,
  guestAdultsInputValue,
  guestChildrenInputValue,
  guestInfantsInputValue,
  handleGuestDataChange,
}) => {
  console.log("Sidebar tour ::: ", tour);
  const [isCreatingOrder, setIsCreatingOrder] = useState(false);
  const navigate = useNavigate();
  // const [selectedDate, setSelectedDate] = useState<
  //   TourAvailableDate | undefined
  // >(undefined);

  const [selectedDate, setSelectedDate] = useState<TourAvailableDate | null>(
    null
  );
  const [selectedDepartureLocation, setSelectedDepartureLocation] =
    useState<TourDepartureLocation | null>(null);

  // const [selectedDepartureLocation, setSelectedDepartureLocation] = useState<
  //   TourDepartureLocation | undefined
  // >(undefined);

  const [selectedTourTypeId, setSelectedTourTypeId] = useState<number | null>(
    null
  );

  const [selectedTourTypeDates, setSelectedTourTypeDates] =
    useState<YearsData | null>(null);
  const [selectedDepartureLocations, setSelectedDepartureLocations] = useState<
    TourDepartureLocation[] | null
  >(null);

  const initialFormErrors: FormErrorsType = {
    guestAdultsInputValue: "",
    selectedTourTypeId: "",
    selectedDate: "",
    selectedDepartureLocation: "",
  };

  const [formErrors, setFormErrors] =
    useState<FormErrorsType>(initialFormErrors);

  useEffect(() => {
    const toursData = JSON.parse(localStorage.getItem("tours") || "{}");
    const selectedTourTypeId = toursData[tour.id]?.selectedTourTypeId;
    const selectedDepartureLocation =
      toursData[tour.id]?.selectedDepartureLocation;
    const selectedDate = toursData[tour.id]?.selectedDate;
    if (selectedTourTypeId !== undefined) {
      setSelectedTourTypeId(selectedTourTypeId);
    }
    if (selectedDepartureLocation !== undefined) {
      setSelectedDepartureLocation(selectedDepartureLocation);
    }
    if (selectedDate !== undefined) {
      setSelectedDate(selectedDate);
    }

    const selectedType = tour.tour_types.find(
      (type) => type.id === selectedTourTypeId
    );
    if (selectedType) {
      setSelectedTourTypeDates(selectedType.tour_available_dates as YearsData);
      setSelectedDepartureLocations(
        selectedType.tour_departure_locations as TourDepartureLocation[]
      );
    }
  }, [tour.id]);

  useEffect(() => {
    console.log("selectedTourTypeId ::: ", selectedTourTypeId);
    console.log("selectedDate ::: ", selectedDate);
    console.log("selectedDepartureLocation ::: ", selectedDepartureLocation);

    const toursData = JSON.parse(localStorage.getItem("tours") || "{}");
    toursData[tour.id] = {
      ...toursData[tour.id],
      selectedTourId: tour.id,
      selectedTourTypeId: selectedTourTypeId,
      selectedDate: selectedDate,
      selectedDepartureLocation: selectedDepartureLocation,
    };
    localStorage.setItem("tours", JSON.stringify(toursData));
  }, [selectedTourTypeId, selectedDate, selectedDepartureLocation, tour.id]);

  const handleDateSelection = (date: TourAvailableDate) => {
    setSelectedDate(date);
    // Here you would handle the logic when a date is selected
  };

  const handleTourTypeChange = (tourTypeId: number) => {
    const selectedType = tour.tour_types.find((type) => type.id === tourTypeId);
    if (selectedType) {
      setSelectedTourTypeId(tourTypeId);
      setSelectedTourTypeDates(selectedType.tour_available_dates as YearsData);
      setSelectedDepartureLocations(
        selectedType.tour_departure_locations as TourDepartureLocation[]
      );
      setSelectedDate(null);
      setSelectedDepartureLocation(null);
      setSelectedTourTypeId(tourTypeId);

      // updateSelections(tourTypeId, null, null);
    }
  };

  const handleDepartureLocationSelection = (
    locationName: TourDepartureLocation
  ) => {
    setSelectedDepartureLocation(locationName);
  };

  // Calculate total price for all passengers
  // const totalPricePerPassenger = calculateTotalPricePerPassenger();
  const totalNumberOfPassengers =
    guestAdultsInputValue + guestChildrenInputValue + guestInfantsInputValue;

  const selectedType = tour.tour_types.find(
    (type) => type.id === selectedTourTypeId
  );

  // Call the calculateTotal function with necessary parameters
  const totalPrice = calculateTotal({
    basePrice: tour.base_price || "0",
    tourTypes: tour.tour_types || [],
    selectedTourTypeId,
    selectedDate: selectedDate ?? undefined,
    departureLocations: selectedType?.tour_departure_locations,
    selectedDepartureLocation: selectedDepartureLocation ?? undefined,
    numberOfPassengers: totalNumberOfPassengers,
    additionalOptionsPrice: 0,
  });

  // Format totalPrice as an AUD price value
  const formattedTotalPrice = `AUD ${totalPrice.toLocaleString("en-AU", {
    style: "currency",
    currency: "AUD",
  })}`;

  const handleSubmit = async () => {
    setIsCreatingOrder(true);

    try {
      setFormErrors({
        guestAdultsInputValue: "",
        selectedTourTypeId: "",
        selectedDate: "",
        selectedDepartureLocation: "",
      });

      const validationErrors = await validationSchema.validate(
        {
          guestAdultsInputValue,
          selectedTourTypeId,
          selectedDate: selectedDate?.from_date,
          selectedDepartureLocation: selectedDepartureLocation?.location_name,
        },
        {
          abortEarly: false,
        }
      );

      console.log("validationErrors ::: ", validationErrors);

      navigate(`/tours-additional-options/${tour.id}`);
    } catch (err: any) {
      setIsCreatingOrder(false);

      if (err instanceof yup.ValidationError) {
        const updatedErrors: FormErrorsType = { ...initialFormErrors };
        console.log("err.inner ::: ", err.inner);
        for (const error of err.inner) {
          if (error.path && error.path in updatedErrors) {
            updatedErrors[error.path] = error.message;
          }
        }

        console.log("updatedErrors ::: ", updatedErrors);

        setFormErrors(updatedErrors);
      }
    }
  };

  const renderDepartureLocations = () => {
    return selectedDepartureLocations?.map((location, index) => (
      <RadioButton
        key={index}
        label={`${location.location_name} + ${location.additional_price}`}
        name="DepartureLocation"
        value={location.id.toString()}
        onChange={() => handleDepartureLocationSelection(location)}
        checked={
          !!(
            selectedDepartureLocation &&
            selectedDepartureLocation.location_name === location.location_name
          )
        }
      />
    ));
  };

  return (
    <>
      <div className="listingSectionSidebar__wrap shadow-xl">
        {/* PRICE */}
        <div className="flex justify-between">
          <span className="text-3xl font-semibold">${tour.base_price}</span>
        </div>

        {/* FORM */}
        <form className="flex flex-col border border-neutral-200 dark:border-neutral-700 rounded-3xl">
          <GuestsInput
            tour={tour}
            className="flex-1"
            guestData={{
              guestAdults: guestAdultsInputValue,
            }}
            handleGuestDataChange={handleGuestDataChange}
          />
        </form>

        {formErrors.guestAdultsInputValue && (
          <p className="text-red-500">{formErrors.guestAdultsInputValue}</p>
        )}
        <div
          className="bg-blue-200 border-blue-400 text-blue-700 shadow-xl  listingSectionSidebar__wrap"
          role="alert"
        >
          Remember to count all little explorers!
          <br />
          Please include toddlers and children in your passenger total.
        </div>
      </div>
      <div className="listingSectionSidebar__wrap shadow-xl mt-10">
        <h4>Tour Type</h4>
        <div className="relative flex flex-col space-y-5">
          {totalNumberOfPassengers <= 0 && (
            <div
              className="bg-blue-200 border-blue-400 text-blue-700 shadow-xl  listingSectionSidebar__wrap"
              role="alert"
            >
              Select the number of passengers to <br /> see available departure
              locations for booking.
            </div>
          )}

          {totalNumberOfPassengers > 0 &&
            tour.tour_types.map((type) => (
              <RadioButton
                key={type.id}
                label={`${type.type_title} +${type.additional_price}`}
                name="TourType"
                onChange={() => handleTourTypeChange(type.id)}
                value={type.id.toString()}
                checked={selectedTourTypeId === type.id}
              />
            ))}
        </div>

        {formErrors.selectedTourTypeId && (
          <p className="text-red-500">{formErrors.selectedTourTypeId}</p>
        )}
      </div>

      <div className="listingSectionSidebar__wrap shadow-xl mt-10">
        <h4>Dates</h4>
        <div className="w-full">
          {!selectedTourTypeDates && (
            <div
              className="bg-blue-200 border-blue-400 text-blue-700 shadow-xl  listingSectionSidebar__wrap"
              role="alert"
            >
              Please choose a tour type to <br /> see available dates for
              booking.
            </div>
          )}
          {selectedTourTypeDates &&
            Object.entries(selectedTourTypeDates).map(([year, months]) => (
              <Disclosure key={year}>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-black bg-gray-300 rounded-lg hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                      {year}
                      <ChevronDownIcon
                        className={`${
                          open ? "transform rotate-90" : ""
                        } w-5 h-5`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="mt-5">
                      {Object.entries(months as Record<string, any[]>).map(
                        ([month, dates], monthIdx) => (
                          <Disclosure key={monthIdx}>
                            {({ open }) => (
                              <>
                                <Disclosure.Button className="flex justify-between  px-4 py-2 text-sm font-medium text-left text-black bg-gray-300 rounded-lg hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75 mb-5">
                                  {month}
                                  <ChevronDownIcon
                                    className={`${
                                      open ? "transform rotate-90" : ""
                                    } w-5 h-5`}
                                  />
                                </Disclosure.Button>
                                <Disclosure.Panel className="px-5 pb-6 pt-3 space-y-5">
                                  {dates.map((date, dateIdx) => (
                                    <RadioButton
                                      key={dateIdx}
                                      label={`${moment(date.from_date).format(
                                        "Do MMMM"
                                      )} - Premium Accommodation + $${
                                        date.additional_price
                                      }`}
                                      name="TourDate"
                                      onChange={() => handleDateSelection(date)}
                                      value={date.from_date}
                                      // checked={
                                      //   selectedDate &&
                                      //   selectedDate.from_date ===
                                      //     date.from_date
                                      // }

                                      checked={
                                        !!(
                                          selectedDate &&
                                          selectedDate.from_date ===
                                            date.from_date
                                        )
                                      }
                                      className="mb-5"
                                    />
                                  ))}
                                </Disclosure.Panel>
                              </>
                            )}
                          </Disclosure>
                        )
                      )}
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
        </div>
        {formErrors.selectedDate && (
          <p className="text-red-500">{formErrors.selectedDate}</p>
        )}
      </div>

      <div className="listingSectionSidebar__wrap shadow-xl mt-10">
        <h4>Departure Location</h4>
        <div className="relative flex flex-col  space-y-5">
          {!selectedDepartureLocations && (
            <div
              className="bg-blue-200 border-blue-400 text-blue-700 shadow-xl  listingSectionSidebar__wrap"
              role="alert"
            >
              Please choose a tour type to <br /> see available departure
              locations for booking.
            </div>
          )}
          {renderDepartureLocations()}

          {formErrors.selectedDepartureLocation && (
            <p className="text-red-500">
              {formErrors.selectedDepartureLocation}
            </p>
          )}
        </div>
      </div>

      <div className=" shadow-xl mt-5">
        {isCreatingOrder && (
          <ButtonPrimary loading>In progress...</ButtonPrimary>
        )}

        {/* SUBMIT */}
        {!isCreatingOrder && (
          <div className="listingSectionSidebar__wrap shadow-xl mt-10">
            <div className="flex flex-col space-y-4">
              <h3 className="text-2xl font-semibold">Price Details</h3>
              <div className="flex justify-between font-semibold">
                <span>Total Price</span>
                <span>{formattedTotalPrice}</span>
              </div>
            </div>

            <ButtonPrimary onClick={() => handleSubmit()}>
              Book Now
            </ButtonPrimary>
          </div>
        )}
      </div>
    </>
  );
};

export default Sidebar;
