import { FC } from "react";
import community2 from "images/community-2.png";
import NcImage from "shared/NcImage/NcImage";
import Logo from "shared/Logo/Logo";

export interface SectionCommunityProps {
  className?: string;
}

const SectionCommunity: FC<SectionCommunityProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-SectionCommunity relative flex flex-col lg:flex-row lg:items-center ${className}`}
      data-nc-id="SectionCommunity"
    >
      <div className="flex-shrink-0 mb-10 lg:mb-0 lg:mr-10 lg:w-2/5">
        <Logo />
        <h2 className="font-semibold text-4xl">
          Empowering Communities, Transforming Lives
        </h2>
        <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
          At My Flight Zone we believe in the power of community and the
          transformative impact it can have on lives. As an airline ticketing
          agency, we go beyond simply offering travel services; we actively
          engage in initiatives that empower communities and make a positive
          difference. From organizing beach clean-ups to sponsoring education
          for low-income school children, we are committed to environmental
          sustainability and social responsibility.
          <br />
          <br />
          We take pride in fostering women's empowerment by ensuring a majority
          of our workforce consists of talented and capable women. At My Flight
          Zone, we believe that by investing in communities, we can create a
          better world together. Join us in our journey of empowering
          communities and transforming lives.
        </span>
      </div>
      <div className="flex-grow">
        <NcImage src={community2} />
      </div>
    </div>
  );
};

export default SectionCommunity;
