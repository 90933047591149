import { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import './tourOverviewStyles.css';

import { useParams } from "react-router-dom";
import { fetchTour } from "../../../data/tour/_requests";
import Badge from "shared/Badge/Badge";

import DetailPagetLayout from "../Layout";
import { GuestsObject } from "components/HeroSearchForm/type";
import Loading from "../../../components/Loading/Loading";
import Sidebar from "./Sidebar";
import TourDetails from "../(components)/TourDetails";
import TourTypeTabs from "./TourTypeTabs";

export interface GuestsInputProps {
  className?: string;
  tour: { available_tickets: number; id: number };
  guestData: GuestsObject; // Add guestData prop
  handleGuestDataChange: (value: number, type: keyof GuestsObject) => void; // Update handleGuestDataChange function
}

const StayDetailPageContainer: FC<{}> = () => {
  const [isLoading, setIsLoading] = useState(true);

  const { id } = useParams<{ id: string }>();
  const [tour, setTour] = useState<any>(null);

  const [guestAdultsInputValue, setGuestAdultsInputValue] = useState(0);
  const [guestChildrenInputValue, setGuestChildrenInputValue] = useState(0);
  const [guestInfantsInputValue, setGuestInfantsInputValue] = useState(0);

  useEffect(() => {
    const fetchTourData = async (id: string) => {
      try {
        const data = await fetchTour(id);
        console.log("fetchTour data: ", data);
        setTour(data);

        // Retrieve guest data from local storage
        const storedGuestData = localStorage.getItem("tours");
        if (storedGuestData) {
          const parsedGuestData = JSON.parse(storedGuestData);
          const guestDataForId = parsedGuestData[id] || {};
          // setGuestData(guestDataForId);
          setGuestAdultsInputValue(guestDataForId.guestAdults || 0);
          setGuestChildrenInputValue(guestDataForId.guestChildren || 0);
          setGuestInfantsInputValue(guestDataForId.guestInfants || 0);
        }
      } catch (error) {
        // Handle error
      } finally {
        setIsLoading(false);
      }
    };

    if (id) {
      fetchTourData(id);
    }

    // Retrieve guest data from session storage
    const storedGuestData = localStorage.getItem("tours");
    if (storedGuestData) {
      const parsedGuestData = JSON.parse(storedGuestData);
      const guestDataForId = parsedGuestData[id ?? ""]; // Use nullish coalescing operator

      if (guestDataForId) {
        // setGuestData(guestDataForId);

        // Set the input values based on the retrieved guest data
        setGuestAdultsInputValue(guestDataForId.guestAdults || 0);
        setGuestChildrenInputValue(guestDataForId.guestChildren || 0);
        setGuestInfantsInputValue(guestDataForId.guestInfants || 0);
      }
    }
  }, [
    id,
    guestAdultsInputValue,
    guestChildrenInputValue,
    guestInfantsInputValue,
  ]);

  const handleGuestDataChange = (value: number, type: keyof GuestsObject) => {
    setGuestAdultsInputValue(
      type === "guestAdults" ? value : guestAdultsInputValue
    );
    setGuestChildrenInputValue(
      type === "guestChildren" ? value : guestChildrenInputValue
    );
    setGuestInfantsInputValue(
      type === "guestInfants" ? value : guestInfantsInputValue
    );
  };

  const renderSection1 = () => {
    return (
      <div className="listingSection__wrap !space-y-6">
        {/* 2 */}
        <h1 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">
          {tour.tour_title}
        </h1>

        <div className="inline-flex space-x-3">
          {tour.available_tickets < 10 && (
            <Badge
              name={
                <div className="flex items-center">
                  <i className="text-sm la la-plane"></i>
                  <span className="ml-1">
                    Last {tour.available_tickets} Seats!
                  </span>
                </div>
              }
              color="yellow"
            />
          )}
        </div>

        {/* 5 */}
        <div className="w-full border-b border-neutral-100 dark:border-neutral-700" />

        <TourDetails tour={tour} />

        {/* 6 */}
      </div>
    );
  };

  if (isLoading) {
    return (
      <div className="nc-TourDetailPage">
        <main className=" relative z-10 mt-11 flex flex-col lg:flex-row ">
          <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10">
            <Loading />
          </div>
        </main>
      </div>
    );
  }

  const appBaseUrl = process.env.APP_BASE_URL
    ? process.env.APP_BASE_URL
    : "https://myflightzone.com.au";

  return (
    <div className="nc-TourDetailPage">
      <Helmet>
        <title>My Flight Zone | {tour.seo_title}</title>
        <meta name="description" content={tour.seo_description} />
        <meta name="keywords" content={tour.seo_keywords} />
        <link rel="canonical" href={`${appBaseUrl}/tour/${id}/${tour.slug}`} />
      </Helmet>
      {/*  HEADER */}
      <header className="rounded-b-md sm:rounded-b-xl">
        <div className="relative grid grid-cols-1 gap-1 sm:gap-2">
          <div className="relative overflow-hidden h-64 sm:h-72 rounded-b-md sm:rounded-b-xl">
            {
              <img
                className="absolute inset-0 object-cover w-full h-full"
                src={tour.banner_image}
                alt=""
                sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 50vw"
                style={{ borderRadius: "0 0 0.5rem 0.5rem" }} // Here 0.5rem is just an example, adjust as needed
              />
            }
          </div>
        </div>
      </header>
      {/* MAIN */}
      <main className=" relative z-10 mt-11 flex flex-col lg:flex-row ">
        <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10">
          {renderSection1()}
          <div className="listingSection__wrap !space-y-6">
            <div className="tour-overview-content" dangerouslySetInnerHTML={{ __html: tour.tour_overview }} />{" "}
          </div>
          <TourTypeTabs tour={tour} />
        </div>

        {/* SIDEBAR */}
        <div className=" lg:block flex-grow mt-14 lg:mt-0">
          <div className="sticky top-28">
            {
              <Sidebar
                key="sidebar2"
                tour={tour}
                handleGuestDataChange={handleGuestDataChange}
                guestAdultsInputValue={guestAdultsInputValue}
                guestChildrenInputValue={guestChildrenInputValue}
                guestInfantsInputValue={guestInfantsInputValue}
              />
            }
          </div>
        </div>
      </main>
    </div>
  );
};

export default function TourDetailPage() {
  return (
    <DetailPagetLayout>
      <StayDetailPageContainer />
    </DetailPagetLayout>
  );
}
