import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;
const CERATE_FLIGHT_DEAL_BOOKING_PENDING = `${API_URL}/flight_deal_booking_pending/add`;
const GET_FLIGHT_DEAL_BOOKING_PENDING = `${API_URL}/flight_deal_booking_pending/get`;

interface FlightDealBookingPendingPayload {
  flight_deal_id: number;
  adults: number;
  children: number;
  infants: number;
}

interface FlightDealBookingPendingResponse {
  id: number;
  flight_deal_id: number;
  order_number: string;
}

interface FlightDeal {
  id: number;
  title: string;
  departure_country: string;
  departure_city: string;
  departure_airport: string;
  departure_datetime: string;
  arrival_country: string;
  arrival_city: string;
  arrival_airport: string;
  arrival_datetime: string;
  old_price: number;
  new_price: number;
  child_price: number;
  infant_price: number;
}

// interface Booking {
//   id: number;
//   flight_deal_id: number;
//   order_number: string;
//   adults: number;
//   children: number;
//   infants: number;
//   total_price: number;
//   flight_deal: FlightDeal;
// }

interface ErrorResponse {
  error: string;
}

interface GetFlightDealBookingPendingResponse {
  bookingId: number;
  bookingFlightDealId: number;
  bookingOrderNumber: string;
  adults: number;
  children: number;
  infants: number;
  total_price: number;
  flightDeal: FlightDeal;
}

export async function createFlightDealBookingPending(
  payload: FlightDealBookingPendingPayload
): Promise<FlightDealBookingPendingResponse> {
  try {
    const response = await axios.post<FlightDealBookingPendingResponse>(
      CERATE_FLIGHT_DEAL_BOOKING_PENDING,
      payload
    );
    return response.data;
  } catch (error) {
    throw new Error("Failed to create flight deal booking pending");
  }
}

export const getFlightDealBookingPending = async (
  flightDealId: string,
  bookingNumber: string
): Promise<GetFlightDealBookingPendingResponse | ErrorResponse> => {
  try {
    const response = await axios.get<GetFlightDealBookingPendingResponse>(
      `${GET_FLIGHT_DEAL_BOOKING_PENDING}/${flightDealId}/${bookingNumber}`
    );
    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
    // return { error: "Failed to retrieve flight deal booking pending data" };
  }
};
