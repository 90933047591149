import { FC, useEffect, useState } from "react";
import FlightDetails from "../(components)/FlightDetails";

import ButtonPrimary from "shared/Button/ButtonPrimary";
import Checkbox from "shared/Checkbox/Checkbox";

interface SidebarProps {
  flightDealBookingPending: any;
  currency: string;
  exchangeRate: number;
  isCreatingCustomer: boolean;
  useFormikContext: any;
  handleTermsSelection: any;
  terms: boolean;
  ErrorMessage: any;
}

const Sidebar: FC<SidebarProps> = ({
  flightDealBookingPending,
  currency,
  exchangeRate,
  isCreatingCustomer,
  useFormikContext,
  terms,
  handleTermsSelection,
  ErrorMessage,
}) => {
  const [surcharge, setSurcharge] = useState<string>("");
  const totalPriceAud = flightDealBookingPending.total_price;
  const [subTotalPrice, setSubTotalPrice] = useState<string>("");
  const [totalPrice, setTotalPrice] = useState<string>("");

  const [subTotalAmountReserveDeal, setSubTotalAmountReserveDeal] =
    useState<string>("");
  const [totalReserveAmountWithSurcharge, setTotalReserveAmountWithSurcharge] =
    useState<string>("");
  const [reserveDealSurcharge, setReserveDealSurcharge] = useState<string>("");

  const surcharge_percentage_aud: number = parseFloat(
    process.env.REACT_APP_COMMWEB_SURCHARGE || "1"
  );
  const surcharge_percentage_lkr: number = parseFloat(
    process.env.REACT_APP_COMMERCIAL_SURCHARGE || "3"
  );

  useEffect(() => {
    let totalAmount = Number(totalPriceAud);
    if (currency === "LKR") {
      totalAmount = totalPriceAud * exchangeRate;
    }

    let currentSurcharge = 0;
    if (currency === "AUD") {
      currentSurcharge = Number(totalAmount) * (surcharge_percentage_aud / 100);
    } else if (currency === "LKR") {
      currentSurcharge = Number(totalAmount) * (surcharge_percentage_lkr / 100);
    }

    const totalWithSurcharge = Number(totalAmount) + Number(currentSurcharge);

    setSubTotalPrice(totalAmount.toFixed(2));

    setTotalPrice(totalWithSurcharge.toFixed(2));

    setSurcharge(currentSurcharge.toFixed(2));

    if (flightDealBookingPending.flightDeal.allow_reserve_deal) {
      let totalAmountReserveDeal = Number(
        flightDealBookingPending.flightDeal.reserve_deal_price
      );
      if (currency === "LKR") {
        totalAmountReserveDeal =
          flightDealBookingPending.flightDeal.reserve_deal_price * exchangeRate;
      }

      let reserveDealSurcharge = 0;
      if (currency === "AUD") {
        reserveDealSurcharge =
          Number(totalAmountReserveDeal) * (surcharge_percentage_aud / 100);
      } else if (currency === "LKR") {
        reserveDealSurcharge =
          Number(totalAmountReserveDeal) * (surcharge_percentage_lkr / 100);
      }

      const totalReserveAmountWithSurcharge =
        Number(totalAmountReserveDeal) + Number(reserveDealSurcharge);

      setSubTotalAmountReserveDeal(totalAmountReserveDeal.toFixed(2));

      setTotalReserveAmountWithSurcharge(
        totalReserveAmountWithSurcharge.toFixed(2)
      );

      setReserveDealSurcharge(reserveDealSurcharge.toFixed(2));
    }
  }, [
    surcharge_percentage_aud,
    surcharge_percentage_lkr,
    currency,
    exchangeRate,
    totalPriceAud,
  ]);

  const { setFieldValue } = useFormikContext();

  return (
    <div className="w-full flex flex-col sm:rounded-2xl lg:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-8 px-0 sm:p-6 xl:p-8">
      <h1 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">
        {flightDealBookingPending.flightDeal.title}
      </h1>
      <div className="w-full border-b border-neutral-100 dark:border-neutral-700" />

      <FlightDetails flightDeal={flightDealBookingPending.flightDeal} />

      <div className="flex flex-col space-y-4">
        <h3 className="text-2xl font-semibold">Number of Passengers</h3>
        <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
          <span>Adults</span>
          <span>{flightDealBookingPending.adults}</span>
        </div>
        <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
          <span>Children</span>
          <span>{flightDealBookingPending.children}</span>
        </div>
        <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
          <span>Infants</span>
          <span>{flightDealBookingPending.infants}</span>
        </div>
        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
      </div>
      {flightDealBookingPending.flightDeal.allow_reserve_deal !== 1 && (
        <div className="flex flex-col space-y-4">
          <h3 className="text-2xl font-semibold">Price Details</h3>
          {currency && (
            <div>
              <div className="flex justify-between font-semibold">
                <span>Sub Total</span>
                <span>
                  {currency} {subTotalPrice}
                </span>
              </div>
              <div className="flex justify-between font-semibold">
                <span>Surcharge</span>
                <span>
                  {currency} {surcharge}
                </span>
              </div>
              <div className="flex justify-between font-semibold">
                <span>Total Price</span>
                <span>
                  {currency} {totalPrice}
                </span>
              </div>
            </div>
          )}

          {!currency && (
            <div className="flex justify-between font-semibold">
              <span>Please select the currency.</span>
              <span></span>
            </div>
          )}
        </div>
      )}
      {flightDealBookingPending.flightDeal.allow_reserve_deal === 1 && (
        <div>
          <div className="flex flex-col space-y-4">
            <h3 className="text-2xl font-semibold">Price Details</h3>
            {currency && (
              <div>
                <div className="flex justify-between font-semibold">
                  <span>Total Price</span>
                  <span>
                    {currency} {subTotalPrice}
                  </span>
                </div>
              </div>
            )}
          </div>
          <div className="flex flex-col space-y-4 mt-10">
            <h3 className="text-2xl font-semibold">
              You Pay Today to Reserve Your Deal
            </h3>
            {currency && (
              <div>
                <div className="flex justify-between font-semibold">
                  <span>Sub Total</span>
                  <span>
                    {currency} {subTotalAmountReserveDeal}
                  </span>
                </div>
                <div className="flex justify-between font-semibold">
                  <span>Surcharge</span>
                  <span>
                    {currency} {reserveDealSurcharge}
                  </span>
                </div>
                <div className="flex justify-between font-semibold">
                  <span>Reserve Amount</span>
                  <span>
                    {currency} {totalReserveAmountWithSurcharge}
                  </span>
                </div>
              </div>
            )}

            {!currency && (
              <div className="flex justify-between font-semibold">
                <span>Please select the currency.</span>
                <span></span>
              </div>
            )}
          </div>
        </div>
      )}

      <div className="relative flex flex-col  space-y-5">
        {flightDealBookingPending.flightDeal.allow_reserve_deal === 1 && (
          <div
            className="bg-blue-200 border-blue-400 text-blue-700 shadow-xl  listingSectionSidebar__wrap"
            role="alert"
          >
            <strong className="font-bold">
              <i className="las la-balance-scale"></i> Conditions
            </strong>
            <div className="block sm:inline" style={{ fontSize: "0.75rem" }}>
              <p>
                <strong>Deposits</strong>
              </p>
              <ul>
                <li>
                  A deposit or deposits, as outlined above, are to be paid as
                  advisory service fees for your booking. In certain cases, full
                  payment is to be made within 10 days from the initial booking.
                </li>
                <li>
                  Please note that all deposits are non-refundable in the event
                  of a change of mind or cancellation by you, except in cases
                  where you have specific rights to a refund or another remedy
                  under the Australian Consumer Law.
                </li>
              </ul>

              <p>
                <strong>Final Payment of Outstanding Balance</strong>
              </p>

              <ul>
                <li>
                  The final payment must be settled no later than 10 days from
                  the moment the booking is made, unless otherwise specified.
                </li>
                <li>
                  Failure to complete the payment for the Travel Product in full
                  by the due date may result in the cancellation of your booking
                  and the forfeiture of any deposit you have previously
                  submitted.
                </li>
              </ul>

              <p>
                <strong>Other Terms & Conditions</strong>
              </p>
              <a href="/terms-and-conditions" target="_blank">
                Click here to view other Terms & Conditions
              </a>
            </div>
          </div>
        )}

        {flightDealBookingPending.flightDeal.allow_reserve_deal !== 1 && (
          <div
            className="bg-blue-200 border-blue-400 text-blue-700 shadow-xl  listingSectionSidebar__wrap"
            role="alert"
          >
            <strong className="font-bold">
              <i className="las la-balance-scale"></i> Terms & Conditions
            </strong>
            <div className="block sm:inline" style={{ fontSize: "0.75rem" }}>
              <a href="/terms-and-conditions" target="_blank">
                Click here to view Terms & Conditions
              </a>
            </div>
          </div>
        )}

        <Checkbox
          name="terms"
          label="I have read and agree to the conditions"
          checked={terms}
          onChange={(value: boolean) => {
            handleTermsSelection(value);

            setFieldValue("terms", value);
          }}
        />

        <ErrorMessage name="terms" component="div" className="text-red-500" />
      </div>

      {isCreatingCustomer && (
        <ButtonPrimary loading>In progress...</ButtonPrimary>
      )}
      {!isCreatingCustomer && (
        <ButtonPrimary type="submit">Proceed to Payment</ButtonPrimary>
      )}
    </div>
  );
};

export default Sidebar;
