import Heading from "components/Heading/Heading";
import { FC } from "react";

export interface SectionOurDifferenceProps {
  className?: string;
}

const SectionOurDifference: FC<SectionOurDifferenceProps> = ({
  className = ""
}) => {
  return (
    <div
      className={`nc-SectionOurDifference relative ${className} `}
      data-nc-id="SectionOurDifference"
    >
      <Heading desc="Your Ultimate Travel Partner" isCenter>
        Discover the My Flight Zone Difference
      </Heading>
      <div className={`nc-SectionStatistic relative ${className}`}>
        <div className="grid md:grid-cols-2 gap-6 lg:grid-cols-3 xl:gap-8">
          <div className="p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800">
            <h3 className="text-2xl font-semibold leading-none text-neutral-900 md:text-3xl dark:text-neutral-200">
              We are a Flat structured Team
            </h3>
            <span className="block text-sm text-neutral-500 mt-3 sm:text-base dark:text-neutral-400">
              At My Flight Zone, a team effort is everything to us. Our team has
              been streamlined and more importantly flat structured to give the
              best we have to offer while keeping everyone on equal ground. Our
              coordination and unity have never been higher.
              <br />
              <br />
              Our sole aim is to ensure that our customers have the safest and
              most enjoyable time possible. Once you decide to use our services,
              our team become your invisible partner throughout the entire
              journey.
            </span>
          </div>
          <div className="p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800">
            <h3 className="text-2xl font-semibold leading-none text-neutral-900 md:text-3xl dark:text-neutral-200">
              We Are Different
            </h3>
            <span className="block text-sm text-neutral-500 mt-3 sm:text-base dark:text-neutral-400">
              While many of the top tour companies offer a fixed package with no
              slack for the customer, we choose to differ. At My Flight Zone, we
              like to offer our clients a tailor-made package to suit their
              needs and to not be a burden on their wallets.
              <br />
              <br />
              As a young and budding company, we like to combine our own
              enthusiasm for travel with our spirit of enterprise to give you
              the best that this industry possibly has to offer.
            </span>
          </div>

          <div className="p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800">
            <h3 className="text-2xl font-semibold leading-none text-neutral-900 md:text-3xl dark:text-neutral-200">
              Your Ultimate Travel Solution
            </h3>
            <span className="block text-sm text-neutral-500 mt-3 sm:text-base dark:text-neutral-400">
              We are a bunch of people who have been blessed enough to convert
              what excites us into our bread and butter. Because of the high
              level of expertise in the field, we are enabled to offer total
              travel solutions to our customers, rather than a half-baked cake
              which has no use to anyone. We pledge to offer our services
              throughout your travel, let us worry about both the major & minor
              details of travel, so you don't have to.
            </span>
            <br />
            <ul>
              <li>
                <i className="la la-star"></i> SRI LANKA’S DESTINATION
                SPECIALIST{" "}
              </li>
              <li>
                <i className="la la-star"></i> TRAVEL EXPERT SUPPORT IN 24X7
              </li>
              <li>
                <i className="la la-star"></i> OVER 150,000 HOTELS WORLD WIDE{" "}
              </li>
              <li>
                <i className="la la-star"></i> MULTIPLE PAYMENT OPTIONS{" "}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionOurDifference;
