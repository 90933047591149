import React, { Fragment, useState, useEffect } from "react";
import { Popover, Transition } from "@headlessui/react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Checkbox from "shared/Checkbox/Checkbox";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { fetchTourSearchFilters } from "../../data/tour/_requests";
import Loading from "../../components/Loading/Loading";

export interface TabFiltersProps {
  selectedCities: string[];
  selectedDays: number[];
  selectedPrices: number[];
  onApplyFilters: (cities: string[], days: number[], prices: number[]) => void;
}

const TabFilters: React.FC<TabFiltersProps> = ({
  selectedCities,
  selectedDays,
  selectedPrices,
  onApplyFilters,
}) => {
  const [loading, setLoading] = useState(true);
  const [availableCities, setAvailableCities] = useState([]);
  const [tempSelectedCities, setTempSelectedCities] = useState<string[]>([]);
  const [tempSelectedDays, setTempSelectedDays] = useState<number[]>([1, 30]);
  const [tempSelectedPrices, setTempSelectedPrices] = useState<number[]>([
    100, 10000,
  ]);
  const [applyTrigger, setApplyTrigger] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const filterData = await fetchTourSearchFilters();
        setAvailableCities(filterData.cities);
        setTempSelectedCities(selectedCities);
        setTempSelectedDays(selectedDays);
        setTempSelectedPrices(selectedPrices);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching filter data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedCities, selectedDays, selectedPrices]);

  useEffect(() => {
    if (applyTrigger) {
      onApplyFilters(tempSelectedCities, tempSelectedDays, tempSelectedPrices);
      setApplyTrigger(false);
    }
  }, [
    applyTrigger,
    tempSelectedCities,
    tempSelectedDays,
    tempSelectedPrices,
    onApplyFilters,
  ]);

  const handleCityCheckboxChange = (city: string) => {
    setTempSelectedCities((prevCities) =>
      prevCities.includes(city)
        ? prevCities.filter((selectedCity) => selectedCity !== city)
        : [...prevCities, city]
    );
  };

  const applyFilters = () => {
    setApplyTrigger(true);
  };

  const renderTabsTypeOfPlace = () => {
    if (loading) {
      return <Loading />;
    }

    return (
      <>
        <Popover className="relative">
          {({ open, close }) => (
            <>
              <Popover.Button
                className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border border-neutral-300 dark:border-neutral-700 hover:border-neutral-400 dark:hover:border-neutral-500 focus:outline-none ${
                  open ? "!border-primary-500 " : ""
                }`}
              >
                <span>Filter by Destination</span>
                <i className="las la-angle-down ml-2"></i>
              </Popover.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-md">
                  <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                    <div className="relative flex flex-col px-5 py-6 space-y-5">
                      {availableCities.map((city: string) => (
                        <div key={city} className="">
                          <Checkbox
                            name={city}
                            label={city}
                            checked={tempSelectedCities.includes(city)}
                            onChange={() => handleCityCheckboxChange(city)}
                          />
                        </div>
                      ))}
                    </div>
                    <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                      <ButtonPrimary
                        onClick={applyFilters}
                        sizeClass="px-4 py-2 sm:px-5"
                      >
                        Apply
                      </ButtonPrimary>
                    </div>
                  </div>
                </Popover.Panel>
              </Transition>
            </>
          )}
        </Popover>

        <Popover className="relative">
          {({ open, close }) => (
            <>
              <Popover.Button
                className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border border-neutral-300 dark:border-neutral-700 hover:border-neutral-400 dark:hover:border-neutral-500 focus:outline-none ${
                  open ? "!border-primary-500 " : ""
                }`}
              >
                <span>Filter Number of Days</span>
                <i className="las la-angle-down ml-2"></i>
              </Popover.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-md">
                  <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                    <div className="relative flex flex-col px-5 py-6 space-y-5">
                      <div className="text-center">
                        Selected Days: {tempSelectedDays[0]} to{" "}
                        {tempSelectedDays[1]}
                      </div>
                      <Slider
                        range
                        defaultValue={[1, 30]}
                        min={1}
                        max={30}
                        value={tempSelectedDays}
                        onChange={(value) => {
                          if (Array.isArray(value)) {
                            setTempSelectedDays(value);
                          }
                        }}
                      />
                    </div>
                    <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                      <ButtonPrimary
                        onClick={applyFilters}
                        sizeClass="px-4 py-2 sm:px-5"
                      >
                        Apply
                      </ButtonPrimary>
                    </div>
                  </div>
                </Popover.Panel>
              </Transition>
            </>
          )}
        </Popover>

        <Popover className="relative">
          {({ open, close }) => (
            <>
              <Popover.Button
                className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border border-neutral-300 dark:border-neutral-700 hover:border-neutral-400 dark:hover:border-neutral-500 focus:outline-none ${
                  open ? "!border-primary-500 " : ""
                }`}
              >
                <span>Filter Price</span>
                <i className="las la-angle-down ml-2"></i>
              </Popover.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-md">
                  <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                    <div className="relative flex flex-col px-5 py-6 space-y-5">
                      <div className="text-center">
                        Selected Prices: {tempSelectedPrices[0]} to{" "}
                        {tempSelectedPrices[1]}
                      </div>
                      <Slider
                        range
                        defaultValue={[100, 10000]}
                        min={100}
                        max={10000}
                        step={100}
                        value={tempSelectedPrices}
                        onChange={(value) => {
                          if (Array.isArray(value)) {
                            setTempSelectedPrices(value);
                          }
                        }}
                      />
                    </div>
                    <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                      <ButtonPrimary
                        onClick={applyFilters}
                        sizeClass="px-4 py-2 sm:px-5"
                      >
                        Apply
                      </ButtonPrimary>
                    </div>
                  </div>
                </Popover.Panel>
              </Transition>
            </>
          )}
        </Popover>
      </>
    );
  };

  return <div className="flex lg:space-x-4">{renderTabsTypeOfPlace()}</div>;
};

export default TabFilters;
