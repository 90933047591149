import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!);
const API_URL = process.env.REACT_APP_API_URL;

const cardElementOptions = {
  style: {
    base: {
      fontSize: "16px",
      color: "#fff",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
    },
  },
  hidePostalCode: true,
};

interface OrderDetails {
  id: string;
  productName: string;
  totalAmount: number;
  depositAmount: number;
  status: string;
}

const PaymentForm: React.FC<{ orderDetails: OrderDetails }> = ({
  orderDetails,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [customerEmail, setCustomerEmail] = useState("");
  const [paymentInterval, setPaymentInterval] = useState<
    "weekly" | "fortnightly"
  >("weekly");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handlePaymentIntent = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!stripe || !elements) {
      setErrorMessage("Stripe.js has not loaded yet.");
      return;
    }

    setIsLoading(true);
    setErrorMessage(null);

    try {
      const response = await axios.post(
        `${API_URL}/payment_plan/payment-intent/create`,
        {
          orderId: orderDetails.id,
          customerEmail,
          depositAmount: orderDetails.depositAmount,
        }
      );

      const { clientSecret } = response.data;
      const cardElement = elements.getElement(CardElement);

      if (!cardElement) {
        throw new Error("Card Element not found");
      }

      const paymentResult = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: cardElement,
          billing_details: {
            email: customerEmail,
          },
        },
      });

      if (paymentResult.error) {
        throw new Error(paymentResult.error.message);
      }

      if (paymentResult.paymentIntent?.status === "succeeded") {
        await handleSubscription(
          paymentResult.paymentIntent.payment_method as string
        );
      }
    } catch (error) {
      setErrorMessage(
        error instanceof Error ? error.message : "An unknown error occurred"
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubscription = async (paymentMethodId: string) => {
    try {
      const response = await axios.post(
        `${API_URL}/payment_plan/subscription/create`,
        {
          orderId: orderDetails.id,
          customerEmail,
          paymentMethodId,
          paymentInterval,
        }
      );

      console.log("Subscription created:", response.data.subscriptionId);
      // Here you can add logic to show a success message or redirect the user
    } catch (error) {
      console.error("Error creating subscription:", error);
      setErrorMessage("Failed to set up subscription. Please try again.");
    }
  };

  const remainingBalance =
    orderDetails.totalAmount - orderDetails.depositAmount;
  const interestRate = 0.06; // 6% interest rate
  const interestAmount = remainingBalance * interestRate;
  const totalWithInterest = remainingBalance + interestAmount;

  const weeklyPayment = (totalWithInterest / 8).toFixed(2);
  const fortnightlyPayment = (totalWithInterest / 4).toFixed(2);

  return (
    <form onSubmit={handlePaymentIntent} className="space-y-6 text-white">
      <div className="bg-gray-800 p-4 rounded-lg">
        <p className="text-sm">
          Lock in your deal with a small deposit through our Flight Zone Payment
          Plan. Pay the rest in easy installments.
        </p>
        <a href="/terms-and-conditions" className="text-blue-400 text-sm">
          More Info
        </a>
      </div>

      <div>
        <h2 className="text-xl mb-2">Deposit amount</h2>
        <p className="text-3xl font-bold text-indigo-400">
          ${orderDetails.depositAmount.toFixed(2)}{" "}
          <span className="text-sm font-normal text-gray-400">
            (
            {(
              (orderDetails.depositAmount / orderDetails.totalAmount) *
              100
            ).toFixed(1)}
            % of total)
          </span>
        </p>
      </div>

      <div>
        <h2 className="text-xl mb-2">Choose your payment plan</h2>
        <div className="grid grid-cols-2 gap-4">
          {["weekly", "fortnightly"].map((plan) => (
            <button
              key={plan}
              type="button"
              className={`p-3 rounded-lg text-left ${
                paymentInterval === plan
                  ? "bg-indigo-600 text-white"
                  : "bg-gray-700 text-gray-300"
              }`}
              onClick={() =>
                setPaymentInterval(plan as "weekly" | "fortnightly")
              }
            >
              <div className="font-medium capitalize">{plan}</div>
              <div className="text-sm">
                ${plan === "weekly" ? weeklyPayment : fortnightlyPayment} x{" "}
                {plan === "weekly" ? "8" : "4"}
              </div>
            </button>
          ))}
        </div>
      </div>

      <div className="flex justify-between text-sm">
        <div>
          <p>First Payment</p>
          <p>+{paymentInterval === "weekly" ? "7" : "3"} more payments</p>
          <p>Final Payment</p>
        </div>
        <div className="text-right">
          <p>September 05</p>
          <p>&nbsp;</p>
          <p>October 24</p>
        </div>
      </div>

      <div>
        <h2 className="text-xl mb-2">Purchase Summary</h2>
        <div className="space-y-2 text-sm">
          <div className="flex justify-between">
            <span>Tour Package Price</span>
            <span>${orderDetails.totalAmount.toFixed(2)}</span>
          </div>
          <div className="flex justify-between">
            <span>Deposit (due today)</span>
            <span>${orderDetails.depositAmount.toFixed(2)}</span>
          </div>
          <div className="flex justify-between">
            <span>Remaining Balance</span>
            <span>${remainingBalance.toFixed(2)}</span>
          </div>
          <div className="flex justify-between text-gray-400">
            <span>Interest (6% on remaining balance)</span>
            <span>${interestAmount.toFixed(2)}</span>
          </div>
          <div className="flex justify-between font-bold">
            <span>Total Instalments</span>
            <span>
              $
              {paymentInterval === "weekly"
                ? weeklyPayment
                : fortnightlyPayment}{" "}
              x {paymentInterval === "weekly" ? "8" : "4"}
            </span>
          </div>
        </div>
      </div>

      <input
        type="email"
        className="w-full p-3 bg-gray-800 rounded text-white"
        placeholder="Your email"
        value={customerEmail}
        onChange={(e) => setCustomerEmail(e.target.value)}
        required
      />

      <div className="bg-gray-800 rounded p-3">
        <CardElement options={cardElementOptions} />
      </div>

      <ReCAPTCHA
        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ""}
        theme="dark"
      />

      {errorMessage && <div className="text-red-500 mt-2">{errorMessage}</div>}

      <button
        type="submit"
        className="w-full p-3 bg-indigo-600 rounded text-white font-bold"
        disabled={isLoading}
      >
        {isLoading ? "Processing..." : "Pay Deposit"}
      </button>
    </form>
  );
};

const PaymentInstalment: React.FC = () => {
  const location = useLocation();
  const [orderDetails, setOrderDetails] = useState<OrderDetails | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchOrderDetails = async () => {
      const params = new URLSearchParams(location.search);
      const orderId = params.get("orderId");

      if (!orderId) {
        setError("Order ID is missing from the URL.");
        setIsLoading(false);
        return;
      }

      try {
        const response = await axios.get<OrderDetails>(
          `${API_URL}/payment_plan/order?orderId=${orderId}`
        );
        setOrderDetails(response.data);
      } catch (err) {
        setError("Failed to fetch order details");
      } finally {
        setIsLoading(false);
      }
    };

    fetchOrderDetails();
  }, [location]);

  if (isLoading)
    return <div className="text-white text-center">Loading...</div>;
  if (error || !orderDetails)
    return (
      <div className="text-red-500">
        {error || "Failed to load order details"}
      </div>
    );

  return (
    <div className="bg-gray-900 min-h-screen p-4">
      <div className="max-w-2xl mx-auto">
        <h1 className="text-3xl font-bold mb-6 text-white">
          Payment Plan Details
        </h1>
        <Elements stripe={stripePromise}>
          <PaymentForm orderDetails={orderDetails} />
        </Elements>
      </div>
    </div>
  );
};

export default PaymentInstalment;
