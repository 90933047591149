import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;
const GET_FLIGHT_DEAL_BY_ID = `${API_URL}/flight_deal`;
const GET_FLIGHT_DEALS = `${API_URL}/flight_deal/get_list?${Date.now()}&`;
const GET_FLIGHT_DEAL_CITIES = `${API_URL}/flight_deal/cities`;
const GET_FLIGHT_DEAL_TOP_CITIES = `${API_URL}/flight_deal/top_cities`;
const GET_FLIGHT_DEAL_PRICES_BY_DATE_RANGE = `${API_URL}/flight_deal/get_by_date_range`;

interface FlightDealPriceParams {
  departureAirport: string;
  arrivalAirport: string;
  startDate: Date;
  endDate: Date;
}

const fetchFlightDeal = async (id: string) => {
  try {
    const response = await axios.get(`${GET_FLIGHT_DEAL_BY_ID}/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching flight deal:", error);
    throw error;
  }
};

const fetchFlightDeals = async (params: any) => {
  try {
    const response = await axios.get(GET_FLIGHT_DEALS, {
      params: {
        ...params,
        ...(params.cities &&
          params.cities.length > 0 && { cities: params.cities.join(",") }),
        ...(params.is_featured !== undefined && {
          is_featured: params.is_featured,
        }),
      },
    });
    return response;
  } catch (error) {
    console.error("Error fetching flight deals:", error);
    throw error;
  }
};

const fetchFlightDealCities = async () => {
  try {
    const response = await axios.get(GET_FLIGHT_DEAL_CITIES);
    return response.data;
  } catch (error) {
    console.error("Error fetching cities:", error);
    throw error;
  }
};

const fetchTopFlightDealCities = async (params: any) => {
  try {
    const response = await axios.get(GET_FLIGHT_DEAL_TOP_CITIES, {
      params: {
        ...params,
      },
    });
    return response;
  } catch (error) {
    console.error("Error fetching flight deals:", error);
    throw error;
  }
};

const fetchFlightDealPricesByDateRange = async (
  params: FlightDealPriceParams
) => {
  try {
    const response = await axios.get(GET_FLIGHT_DEAL_PRICES_BY_DATE_RANGE, {
      params: {
        departureAirport: params.departureAirport,
        arrivalAirport: params.arrivalAirport,
        startDate: params.startDate.toISOString(),
        endDate: params.endDate.toISOString(),
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching cities:", error);
    throw error;
  }
};

export {
  fetchFlightDeal,
  fetchFlightDeals,
  fetchFlightDealCities,
  fetchFlightDealPricesByDateRange,
  fetchTopFlightDealCities,
};
