import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;
const GET_TOUR_BY_ID = `${API_URL}/tour`;
const GET_TOURS = `${API_URL}/tour/get_list?${Date.now()}&`;
const GET_TOUR_SEARCH_FILTERS = `${API_URL}/tour/filters`;
const GET_TOUR_TOP_CITIES = `${API_URL}/tour/top_cities`;
const GET_TOUR_PRICES_BY_DATE_RANGE = `${API_URL}/tour/get_by_date_range`;

interface TourPriceParams {
  departureAirport: string;
  arrivalAirport: string;
  startDate: Date;
  endDate: Date;
}

const fetchTour = async (id: string) => {
  try {
    const response = await axios.get(`${GET_TOUR_BY_ID}/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching tour:", error);
    throw error;
  }
};

const fetchTours = async (params: any) => {
  try {
    const response = await axios.get(GET_TOURS, {
      params: {
        ...params,
        ...(params.cities &&
          params.cities.length > 0 && { cities: params.cities.join(",") }),
        ...(params.is_featured !== undefined && {
          is_featured: params.is_featured,
        }),
      },
    });
    return response;
  } catch (error) {
    console.error("Error fetching tours:", error);
    throw error;
  }
};

const fetchTourSearchFilters = async () => {
  try {
    const response = await axios.get(GET_TOUR_SEARCH_FILTERS);
    return response.data;
  } catch (error) {
    console.error("Error fetching cities:", error);
    throw error;
  }
};

const fetchTopTourCities = async (params: any) => {
  try {
    const response = await axios.get(GET_TOUR_TOP_CITIES, {
      params: {
        ...params,
      },
    });
    return response;
  } catch (error) {
    console.error("Error fetching tours:", error);
    throw error;
  }
};

const fetchTourPricesByDateRange = async (params: TourPriceParams) => {
  try {
    const response = await axios.get(GET_TOUR_PRICES_BY_DATE_RANGE, {
      params: {
        departureAirport: params.departureAirport,
        arrivalAirport: params.arrivalAirport,
        startDate: params.startDate.toISOString(),
        endDate: params.endDate.toISOString(),
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching cities:", error);
    throw error;
  }
};

export {
  fetchTour,
  fetchTours,
  fetchTourSearchFilters,
  fetchTourPricesByDateRange,
  fetchTopTourCities,
};
