import React, { FC, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { fetchTour } from "../../../data/tour/_requests";
import Checkbox from "shared/Checkbox/Checkbox";
import Input from "shared/Input/Input";
import DetailPagetLayout from "../Layout";
import RadioButton from "shared/RadioButton/RadioButton";
import {
  TourType,
  AdditionalOption,
  PassengerInfo,
  TourAvailableDate,
  TourDepartureLocation,
} from "../_models";
import { calculateTotal } from "../calculateTotal";
import * as Yup from "yup";
import { createTourBookingPending } from "../../../data/tourOrderPending/_requests";
import { useNavigate } from "react-router-dom";

type FormErrors = {
  [key: string]: string | undefined;
};

// The main component
const TourAdditionalOptionsPageContainer: FC<{}> = () => {
  const { id } = useParams<{ id: string }>();
  const [tour, setTour] = useState<any>(null);
  const [selectedTourTypeId, setSelectedTourTypeId] = useState<number | null>(
    null
  );
  const [selectedTourType, setSelectedTourType] = useState<TourType | null>(
    null
  );
  const [passengers, setPassengers] = useState<PassengerInfo[]>([]);
  const [orderTotalAud, setorderTotalAud] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isCreatingOrder, setIsCreatingOrder] = useState(false);

  const [selectedDate, setSelectedDate] = useState<
    TourAvailableDate | undefined
  >(undefined);
  const [selectedDepartureLocation, setSelectedDepartureLocation] = useState<
    TourDepartureLocation | undefined
  >(undefined);

  const [selectedTourData, setSelectedTourData] = useState<any>(null);

  const navigate = useNavigate();

  const [formErrors, setFormErrors] = useState<FormErrors>({});

  const passengerInfoSchema = Yup.object().shape({
    lastName: Yup.string().required("Last Name is required"),
    otherNames: Yup.string().required("Other Names are required"),
    dateOfBirth: Yup.date()
      .typeError("Invalid date of birth.")
      .required("Date of Birth is required")
      .test({
        name: "age",
        message: "Invalid age",
        test: function (value) {
          // const path: string = this.path;
          // const createError: any = this.createError;
          // const { path, createError } = this;
          // const dob = new Date(value);
          // const age = calculateAge(dob);

          // Get the passenger type from the field path

          // Adult age validation (13 years or above)
          // if (age < 13) {
          //   return createError({ path, message: "Age should be 13 or above" });
          // }

          return true;
        },
      }),
  });

  const formSchema = Yup.object().shape({
    // billingInfo: billingInfoSchema,
    passengers: Yup.array()
      .of(passengerInfoSchema)
      .required("Passenger information is required"),
    // Include other fields here if you have more fields in your form
  });

  useEffect(() => {
    setIsLoading(true);
    const fetchTourData = async () => {
      if (id) {
        try {
          const data = await fetchTour(id);
          setTour(data);
          const storedTourData = localStorage.getItem("tours");
          if (storedTourData) {
            const parsedTourData = JSON.parse(storedTourData);
            const tourData = parsedTourData[id];

            // Check if the stored tour data has a selectedTourTypeId
            if (tourData) {
              setSelectedTourData(tourData);
              if (tourData.selectedTourTypeId) {
                setSelectedTourTypeId(tourData.selectedTourTypeId);
                const tourType = data.tour_types.find(
                  (tourType: TourType) =>
                    tourType.id === tourData.selectedTourTypeId
                );
                setSelectedTourType(tourType);
              }

              if (tourData.selectedDepartureLocation) {
                setSelectedDepartureLocation(
                  tourData.selectedDepartureLocation
                );
              }

              if (tourData.selectedDate) {
                setSelectedDate(tourData.selectedDate);
              }

              if (tourData.guestAdults) {
                setPassengers(
                  Array.from({ length: tourData.guestAdults }, () => ({
                    lastName: "",
                    otherNames: "",
                    dateOfBirth: "",
                    additionalOptions: {},
                  }))
                );
              }
            }
          }
        } catch (error) {
          console.error("Error fetching tour data: ", error);
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchTourData();
  }, [id]);

  useEffect(() => {
    let totalAdditionalOptionsPrice = 0;
    passengers.forEach((passenger) => {
      Object.keys(passenger.additionalOptions).forEach((optionId) => {
        if (passenger.additionalOptions[Number(optionId)]) {
          const allOptions = [
            ...Object.values(
              selectedTourType?.tour_additional_options.single ?? {}
            ),
            ...Object.values(
              selectedTourType?.tour_additional_options.multi ?? {}
            ).flat(),
          ].flat();
          const option = allOptions.find((o) => o.id === Number(optionId));
          if (option) {
            totalAdditionalOptionsPrice += Number(option.additional_price);
          }
        }
      });
    });

    // Call the calculateTotal function with necessary parameters
    const total = calculateTotal({
      basePrice: tour?.base_price || "0",
      tourTypes: tour?.tour_types || [],
      selectedTourTypeId,
      selectedDate,
      departureLocations: selectedTourType?.tour_departure_locations,
      selectedDepartureLocation,
      numberOfPassengers: passengers.length,
      additionalOptionsPrice: totalAdditionalOptionsPrice,
    });
    setorderTotalAud(total);
  }, [passengers, tour, selectedTourType, selectedTourTypeId]);

  const handlePassengerInfoChange = (
    index: number,
    field: keyof PassengerInfo,
    value: string | { [optionId: number]: boolean }
  ) => {
    setPassengers((currentPassengers) => {
      const updatedPassengers = [...currentPassengers];
      if (typeof value === "string") {
        updatedPassengers[index] = {
          ...updatedPassengers[index],
          [field]: value,
        };
      } else {
        updatedPassengers[index].additionalOptions = value;
      }
      return updatedPassengers;
    });
  };

  useEffect(() => {
    console.log("passengers ::: ", passengers);
  }, [passengers]);

  const handleOptionChange = (
    passengerIndex: number,
    optionId: number,
    isChecked: boolean,
    categoryOptions: AdditionalOption[]
  ) => {
    setPassengers((currentPassengers) => {
      const updatedPassengers = [...currentPassengers];
      const updatedOptions = {
        ...updatedPassengers[passengerIndex].additionalOptions,
      };

      // Set all options in the category to false
      categoryOptions.forEach((option) => {
        updatedOptions[option.id] = false;
      });

      // Set the selected option to true
      if (isChecked) {
        updatedOptions[optionId] = true;
      }

      updatedPassengers[passengerIndex].additionalOptions = updatedOptions;

      return updatedPassengers;
    });
  };

  const handleCheckboxChange = (
    passengerIndex: number,
    optionId: number,
    isChecked: boolean
  ) => {
    setPassengers((currentPassengers) => {
      const updatedPassengers = [...currentPassengers];
      const updatedOptions = {
        ...updatedPassengers[passengerIndex].additionalOptions,
      };

      // Toggle the selected option
      updatedOptions[optionId] = isChecked;

      updatedPassengers[passengerIndex].additionalOptions = updatedOptions;

      return updatedPassengers;
    });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsCreatingOrder(true);
    try {
      const validData = await formSchema.validate(
        {
          passengers,
        },
        { abortEarly: false }
      );

      console.log("Form is valid, submitting data:", validData);
      setFormErrors({});

      const response = await createTourBookingPending({
        passengers: validData.passengers,
        tour: selectedTourData,
      }).finally(() => {
        setIsCreatingOrder(false);
      });
      const orderNumber = response.order_number;

      const toursData = JSON.parse(localStorage.getItem("tours") || "{}");
      toursData[tour.id] = {
        ...toursData[tour.id],
        orderNumber: orderNumber,
      };
      localStorage.setItem("tours", JSON.stringify(toursData));

      navigate(`/tours-checkout/${tour.id}/${orderNumber}`);

      // Proceed with form submission logic here
    } catch (err: any) {
      if (err instanceof Yup.ValidationError) {
        const updatedErrors: FormErrors = {};
        console.log("err.inner ::: ", err.inner);
        for (const error of err.inner) {
          if (error.path) {
            updatedErrors[error.path] = error.message;
          }
        }

        console.log("updatedErrors ::: ", updatedErrors);

        setFormErrors(updatedErrors);
        setIsCreatingOrder(false);
      }
    }
  };

  const renderPassengerForms = () => {
    return passengers.map((passenger, index) => (
      <div
        key={`passenger-form-${index}`}
        className="listingSection__wrap mb-10"
      >
        {/* HEADING */}
        <div>
          <h2 className="text-2xl font-semibold">Passenger {index + 1}</h2>
        </div>
        {/* CONTENT */}
        <div className="flow-root">
          <div className="text-sm sm:text-base text-neutral-6000 dark:text-neutral-300 mb-4">
            <div key={`passenger-${index}`} className="mb-4">
              <div className="space-y-1 mb-5">
                <Input
                  type="text"
                  placeholder="Last Name"
                  value={passenger.lastName}
                  onChange={(e) =>
                    handlePassengerInfoChange(index, "lastName", e.target.value)
                  }
                />
                {formErrors[`passengers[${index}].lastName`] && (
                  <div className="text-red-500">
                    {formErrors[`passengers[${index}].lastName`]}
                  </div>
                )}
              </div>

              <div className="space-y-1 mb-5">
                <Input
                  type="text"
                  placeholder="Other Names"
                  value={passenger.otherNames}
                  onChange={(e) =>
                    handlePassengerInfoChange(
                      index,
                      "otherNames",
                      e.target.value
                    )
                  }
                />
                {formErrors[`passengers[${index}].otherNames`] && (
                  <div className="text-red-500">
                    {formErrors[`passengers[${index}].otherNames`]}
                  </div>
                )}
              </div>
              <div className="space-y-1 mb-5">
                <Input
                  type="date"
                  placeholder="Date of Birth"
                  value={passenger.dateOfBirth}
                  onChange={(e) =>
                    handlePassengerInfoChange(
                      index,
                      "dateOfBirth",
                      e.target.value
                    )
                  }
                />
                {formErrors[`passengers[${index}].dateOfBirth`] && (
                  <div className="text-red-500">
                    {formErrors[`passengers[${index}].dateOfBirth`]}
                  </div>
                )}
              </div>

              {selectedTourType?.tour_additional_options.multi &&
                Object.entries(
                  selectedTourType.tour_additional_options.multi
                ).map(([category, options]) => (
                  <div key={category} className="space-y-1 mb-5">
                    <h5>{category}</h5>
                    {options.map((option: AdditionalOption) => (
                      <Checkbox
                        key={option.id}
                        name={`passenger-${index}-option-${category}`}
                        label={`${option.option_title} +${option.additional_price}`}
                        checked={!!passenger.additionalOptions[option.id]}
                        onChange={(checked) =>
                          handleCheckboxChange(index, option.id, checked)
                        }
                      />
                    ))}
                  </div>
                ))}

              {selectedTourType?.tour_additional_options.single &&
                Object.entries(
                  selectedTourType.tour_additional_options.single
                ).map(([category, options]) => (
                  <div key={category} className="space-y-1 mb-5">
                    <h5>{category}</h5>
                    {options.map((option: AdditionalOption) => (
                      <RadioButton
                        key={option.id}
                        name={`passenger-${index}-option-${category}`}
                        label={`${option.option_title} +${option.additional_price}`}
                        checked={!!passenger.additionalOptions[option.id]}
                        onChange={() =>
                          handleOptionChange(index, option.id, true, options)
                        }
                      />
                    ))}
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    ));
  };

  return isLoading ? (
    <div>Loading...</div>
  ) : (
    <div className="nc-TourDetailPage">
      <form onSubmit={handleSubmit}>
        {/*  HEADER */}
        <header className="rounded-b-md sm:rounded-b-xl">
          <div className="relative grid grid-cols-1 gap-1 sm:gap-2">
            <div className="relative overflow-hidden h-64 sm:h-72 rounded-b-md sm:rounded-b-xl">
              {
                <img
                  className="absolute inset-0 object-cover w-full h-full"
                  src={tour.banner_image}
                  alt=""
                  sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 50vw"
                  style={{ borderRadius: "0 0 0.5rem 0.5rem" }} // Here 0.5rem is just an example, adjust as needed
                />
              }
            </div>
          </div>
        </header>
        {/* MAIN */}
        <main className=" relative z-10 mt-11 flex flex-col lg:flex-row ">
          <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10">
            {renderPassengerForms()}
          </div>

          {/* SIDEBAR */}
          <div className=" lg:block flex-grow mt-14 lg:mt-0">
            <div className="sticky top-28">
              <div className="listingSectionSidebar__wrap shadow-xl">
                <div className="flex flex-col space-y-4">
                  <h3 className="text-2xl font-semibold">Price Details</h3>
                  <div className="flex justify-between font-semibold">
                    <span>Total Price</span>
                    <span>AUD ${orderTotalAud.toFixed(2)}</span>
                  </div>
                </div>

                {isCreatingOrder && (
                  <ButtonPrimary loading>In progress...</ButtonPrimary>
                )}

                {/* SUBMIT */}
                {!isCreatingOrder && (
                  <ButtonPrimary type="submit">Proceed</ButtonPrimary>
                )}
              </div>
            </div>
          </div>
        </main>
      </form>
    </div>
  );
};

export default function TourAdditionalOptionsPage() {
  return (
    <DetailPagetLayout>
      <TourAdditionalOptionsPageContainer />
    </DetailPagetLayout>
  );
}
