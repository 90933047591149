import { Tab } from "@headlessui/react";
import { FC, useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import { getFlightDealBookingPending } from "../../../data/flightDealOrderPending/_requests";
import { Formik, Form, Field, ErrorMessage, useFormikContext } from "formik";
import Loading from "../../../components/Loading/Loading";
import * as Yup from "yup";
import { initiateFlightDealCheckout } from "../../../data/payment/_requests";
import { fetchCurrentExchangeRate } from "../../../data/exchangeRate/_requests";
import FlightDealCheckOutPreProcessing from "./FlightDealCheckOutPreProcessing";
import Sidebar from "./Sidebar";

export interface FlightDealCheckOutPageProps {
  className?: string;
}

interface Payload {
  email_address: string;
  order_number: string;
  flight_deal_id: number;
  full_name: string;
  mobile: string;
  adult: any[];
  child: any[];
  infant: any[];
  currency: string;
  terms: boolean;
}

export type RouteParams = {
  flight_deal_id: string;
  order_number: string;
};

export type PaymentParams = {
  sessionId: string;
  orderId: string;
  merchant: string;
  currency: string;
};

const calculateAge = (birthDate: Date): number => {
  const today = new Date();
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDiff = today.getMonth() - birthDate.getMonth();
  if (
    monthDiff < 0 ||
    (monthDiff === 0 && today.getDate() < birthDate.getDate())
  ) {
    age--;
  }
  return age;
};

const passengerSchema = Yup.object().shape({
  last_name: Yup.string().required("Last Name is required"),
  other_names: Yup.string().required("Other Names are required"),
  date_of_birth: Yup.date()
    .required("Date of Birth is required")
    .test({
      name: "age",
      message: "Invalid age",
      test: function (value) {
        const path: string = this.path;
        const createError: any = this.createError;
        // const { path, createError } = this;
        const dob = new Date(value);
        const age = calculateAge(dob);

        // Get the passenger type from the field path
        const passengerType = path.match(/^(adult|child|infant)/)?.[0];

        if (passengerType === "adult") {
          // Adult age validation (13 years or above)
          if (age < 13) {
            return createError({ path, message: "Age should be 13 or above" });
          }
        } else if (passengerType === "child") {
          // Child age validation (2 to 12 years)
          if (age < 2 || age >= 13) {
            return createError({
              path,
              message: "Age should be between 2 and 12",
            });
          }
        } else if (passengerType === "infant") {
          // Infant age validation (less than 2 years)
          if (age >= 2) {
            return createError({ path, message: "Age should be less than 2" });
          }
        }

        return true;
      },
    }),
});

const validationSchema = Yup.object().shape({
  currency: Yup.string()
    .oneOf(["AUD", "LKR"])
    .required("Please select your preferred currency for the payment."),
  customerName: Yup.string().required("Customer Name is required"),
  emailAddress: Yup.string()
    .email("Invalid email address")
    .required("Email Address is required"),
  mobile: Yup.string().required("Mobile is required"),
  adult: Yup.array().of(Yup.lazy((value) => passengerSchema)),
  child: Yup.array().of(Yup.lazy((value) => passengerSchema)),
  infant: Yup.array().of(Yup.lazy((value) => passengerSchema)),
  terms: Yup.boolean()
    .oneOf([true], "Please  acknowledge that you accept our terms.")
    .required("Please  acknowledge that you accept our terms."),
});

const FlightDealCheckOutPage: FC<FlightDealCheckOutPageProps> = ({
  className = "",
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const { flight_deal_id, order_number } = useParams<RouteParams>();

  const [isCreatingCustomer, setCreatingCustomer] = useState(false);

  const [flightDealBookingPending, setFlightDealBookingPending] =
    useState<any>(null);

  const [paymentParams, setPaymentParams] = useState<PaymentParams | null>(
    null
  );

  const [initialValues, setInitialValues] = useState<any>(null);

  const [currency, setCurrency] = useState<string>("");
  const [exchangeRate, setExchangeRate] = useState<number>(0);
  const [terms, setTerms] = useState<boolean>(false);

  let search = useLocation().search;
  let params = new URLSearchParams(search);
  let checkoutVersion = params.get("checkoutVersion");

  console.log(search, params, checkoutVersion);

  useEffect(() => {
    // Get the result from local storage
    const result = localStorage.getItem("paymentResult");
    console.log("result ::: ", result);
    if (result || checkoutVersion) {
      console.log("result ::: ", result);
      window.location.href = "/success_payment";
    }

    const fetchFlightDealData = async (
      flight_deal_id: string,
      order_number: string
    ) => {
      try {
        const eRate = await fetchCurrentExchangeRate();

        setExchangeRate(eRate.exchange_rate);

        const data: any = await getFlightDealBookingPending(
          flight_deal_id,
          order_number
        );

        setFlightDealBookingPending(data);
        // Set initial values here
        const { adults, children, infants } = data;
        const initialValues: any = {
          currency: "",
          customerName: "",
          emailAddress: "",
          mobile: "",
          adult: Array(adults).fill({
            last_name: "",
            other_names: "",
            date_of_birth: "",
          }),
          child: Array(children).fill({
            last_name: "",
            other_names: "",
            date_of_birth: "",
          }),
          infant: Array(infants).fill({
            last_name: "",
            other_names: "",
            date_of_birth: "",
          }),
          terms: false,
        };

        setInitialValues(initialValues);
      } catch (error) {
        console.log("error ::: ", error);
        // Handle error
      } finally {
        setIsLoading(false);
      }
    };

    if (flight_deal_id && order_number) {
      fetchFlightDealData(flight_deal_id, order_number);
    }
  }, [flight_deal_id, order_number, checkoutVersion]);

  const handleConfirmAndPay = async (values: any) => {
    setCreatingCustomer(true);
    try {
      const {
        customerName,
        emailAddress,
        mobile,
        adult,
        child,
        infant,
        currency,
        terms,
      } = values;

      const payload: Payload = {
        email_address: emailAddress,
        order_number: order_number!,
        flight_deal_id: Number(flight_deal_id),
        full_name: customerName,
        mobile: mobile,
        adult: adult,
        child: child,
        infant: infant,
        currency: currency,
        terms: terms,
      };

      const response = await initiateFlightDealCheckout(payload);

      const { orderId, sessionId, merchant } = response.response;
      setPaymentParams({ orderId, sessionId, merchant, currency });

      setCreatingCustomer(false);
    } catch (error) {
      console.log("ERROR :: ", error);
      // Handle error
    }
  };

  const handleTermsSelection = (terms: any) => {
    setTerms(terms);
  };

  const renderPassengerDetails = () => {
    const passengers = [];

    for (let i = 0; i < flightDealBookingPending.adults; i++) {
      passengers.push(
        <div key={`adult${i}`}>
          <h3 className="text-2xl font-semibold">Adult {i + 1}</h3>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 my-5" />

          <div className="mt-6 space-y-4">
            <div className="space-y-1">
              <Label>Last Name</Label>
              <Field type="text" name={`adult[${i}].last_name`} as={Input} />
              <ErrorMessage
                name={`adult[${i}].last_name`}
                component="div"
                className="text-red-500"
              />
            </div>
            <div className="space-y-1">
              <Label>Other Names</Label>
              <Field type="text" name={`adult[${i}].other_names`} as={Input} />
              <ErrorMessage
                name={`adult[${i}].other_names`}
                component="div"
                className="text-red-500"
              />
            </div>
            <div className="space-y-1">
              <Label>Date of Birth</Label>
              <Field
                type="date"
                name={`adult[${i}].date_of_birth`}
                as={Input}
              />
              <ErrorMessage
                name={`adult[${i}].date_of_birth`}
                component="div"
                className="text-red-500"
              />
            </div>
          </div>
        </div>
      );
    }

    for (let i = 0; i < flightDealBookingPending.children; i++) {
      passengers.push(
        <div key={`child${i}`}>
          <h3 className="text-2xl font-semibold">Child {i + 1}</h3>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 my-5" />

          <div className="mt-6 space-y-4">
            <div className="space-y-1">
              <Label>Last Name</Label>
              <Field type="text" name={`child[${i}].last_name`} as={Input} />
              <ErrorMessage
                name={`child[${i}].last_name`}
                component="div"
                className="text-red-500"
              />
            </div>
            <div className="space-y-1">
              <Label>Other Names</Label>
              <Field type="text" name={`child[${i}].other_names`} as={Input} />
              <ErrorMessage
                name={`child[${i}].other_names`}
                component="div"
                className="text-red-500"
              />
            </div>
            <div className="space-y-1">
              <Label>Date of Birth</Label>
              <Field
                type="date"
                name={`child[${i}].date_of_birth`}
                as={Input}
              />
              <ErrorMessage
                name={`child[${i}].date_of_birth`}
                component="div"
                className="text-red-500"
              />
            </div>
          </div>
        </div>
      );
    }

    for (let i = 0; i < flightDealBookingPending.infants; i++) {
      passengers.push(
        <div key={`infant${i}`}>
          <h3 className="text-2xl font-semibold">Infant {i + 1}</h3>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 my-5" />

          <div className="mt-6 space-y-4">
            <div className="space-y-1">
              <Label>Last Name</Label>
              <Field type="text" name={`infant[${i}].last_name`} as={Input} />
              <ErrorMessage
                name={`infant[${i}].last_name`}
                component="div"
                className="text-red-500"
              />
            </div>
            <div className="space-y-1">
              <Label>Other Names</Label>
              <Field type="text" name={`infant[${i}].other_names`} as={Input} />
              <ErrorMessage
                name={`infant[${i}].other_names`}
                component="div"
                className="text-red-500"
              />
            </div>
            <div className="space-y-1">
              <Label>Date of Birth</Label>
              <Field
                type="date"
                name={`infant[${i}].date_of_birth`}
                as={Input}
              />
              <ErrorMessage
                name={`infant[${i}].date_of_birth`}
                component="div"
                className="text-red-500"
              />
            </div>
          </div>
        </div>
      );
    }

    return passengers;
  };

  const FormContents = () => {
    const { setFieldValue } = useFormikContext();
    // Rest of your form code here...
    return (
      <>
        <div>
          <h3 className="text-2xl font-semibold">Billing Information</h3>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 my-5"></div>

          <div className="mt-6">
            <Tab.Group>
              <Tab.Panels>
                <Tab.Panel className="space-y-5">
                  <div className="space-y-1">
                    <Label>Select Currency </Label>
                    <Field
                      as={Select} // Use your custom Select component here
                      name="currency"
                      className="block w-full mt-1 rounded-md shadow-sm"
                      onChange={(e: any) => {
                        setCurrency(e.target.value);
                        setFieldValue("currency", e.target.value);
                      }}
                    >
                      <option value="" disabled>
                        Select Currency
                      </option>
                      <option value="AUD">AUD</option>
                      <option value="LKR">LKR</option>
                    </Field>
                    <ErrorMessage
                      name="currency"
                      component="div"
                      className="text-red-500"
                    />
                  </div>

                  <div className="space-y-1">
                    <Label>Customer Name </Label>
                    <Field
                      type="text"
                      id="customerName"
                      name="customerName"
                      as={Input}
                    />{" "}
                    <ErrorMessage
                      name="customerName"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                  <div className="space-y-1">
                    <Label>Email Address </Label>
                    <Field
                      type="email"
                      id="emailAddress"
                      name="emailAddress"
                      as={Input}
                    />
                    <ErrorMessage
                      name="emailAddress"
                      component="div"
                      className="text-red-500"
                    />{" "}
                  </div>
                  <div className="space-y-1">
                    <Label>Mobile </Label>{" "}
                    <Field type="text" id="mobile" name="mobile" as={Input} />
                    <ErrorMessage
                      name="mobile"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
          </div>
        </div>
        <br />
        <br />
        <div>
          <h3 className="text-2xl font-semibold">Passenger Information</h3>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 my-5"></div>

          <div className="mt-6">
            <Tab.Group>
              <Tab.Panels>
                <Tab.Panel className="space-y-5">
                  {renderPassengerDetails()}
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
            <div className="pt-8">
              {/* {isCreatingCustomer && (
                <ButtonPrimary loading>In progress...</ButtonPrimary>
              )} */}

              {/* SUBMIT */}
              {/* {!isCreatingCustomer && (
                <ButtonPrimary type="submit">Proceed to Payment</ButtonPrimary>
              )} */}
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderMain = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
        {/* <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleConfirmAndPay}
        >
          <Form>
            <FormContents />
          </Form>
        </Formik> */}

        <FormContents />
      </div>
    );
  };

  if (isLoading || !flightDealBookingPending) {
    return (
      <div className={`nc-FlightDealCheckOutPage ${className}`}>
        <main className="container mt-11 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row">
          <Loading />
        </main>
      </div>
    );
  }

  if (paymentParams) {
    return <FlightDealCheckOutPreProcessing paymentParams={paymentParams} />;
  }

  return (
    <div className={`nc-FlightDealCheckOutPage ${className}`}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleConfirmAndPay}
      >
        <Form>
          <div
            className="container mt-11 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row lg:hidden flex-[3]  !mx-auto  mt-10 mb-20"
            style={{ textAlign: "center" }}
          >
            {
              <Sidebar
                flightDealBookingPending={flightDealBookingPending}
                currency={currency}
                exchangeRate={exchangeRate}
                isCreatingCustomer={isCreatingCustomer}
                useFormikContext={useFormikContext}
                terms={terms}
                handleTermsSelection={handleTermsSelection}
                ErrorMessage={ErrorMessage}
              />
            }
          </div>
          <main className="container mt-11 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row">
            <div className="w-full lg:w-3/5 xl:w-2/3 lg:pr-10 ">
              {renderMain()}
            </div>
            {/* <div className="hidden lg:block flex-grow">{renderSidebar()}</div> */}

            <div className="hidden lg:block flex-grow">
              {
                <Sidebar
                  flightDealBookingPending={flightDealBookingPending}
                  currency={currency}
                  exchangeRate={exchangeRate}
                  isCreatingCustomer={isCreatingCustomer}
                  useFormikContext={useFormikContext}
                  terms={terms}
                  handleTermsSelection={handleTermsSelection}
                  ErrorMessage={ErrorMessage}
                />
              }
            </div>
          </main>
        </Form>
      </Formik>
    </div>
  );
};

export default FlightDealCheckOutPage;
