import { NavItemType } from "shared/Navigation/NavigationItem";
import ncNanoId from "utils/ncNanoId";

export const NAVIGATION_DEMO: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/special-flights",
    name: "Special Flights",
    type: "none",
    isNew: true,
  },
  // {
  //   id: ncNanoId(),
  //   href: "/tours",
  //   name: "Tours",
  //   type: "none",
  //   isNew: true,
  // },
  {
    id: ncNanoId(),
    href: "/who-we-are",
    name: "Who We Are",
    type: "none",
    isNew: true,
  },
  {
    id: ncNanoId(),
    href: "/blog",
    name: "Blog",
    type: "none",
    isNew: true,
  },
  {
    id: ncNanoId(),
    href: "/contact",
    name: "Contact",
    type: "none",
    isNew: true,
  },
];
