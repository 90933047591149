"use client";

import React, { FC } from "react";
import FlightSearch from "./FlightSearch/FlightSearch";

export type SearchTab = "Flights";

export interface DealSearchProps {
  className?: string;
  currentTab?: SearchTab;
  currentPage?: "Flights";
}

const DealSearch: FC<DealSearchProps> = ({
  className = "",
  currentTab = "Flights",
  currentPage,
}) => {
  // const tabs: SearchTab[] = ["Flights"];
  // const [tabActive, setTabActive] = useState<SearchTab>(currentTab);
  const tabActive = currentTab;
  // const renderTab = () => {
  //   return (
  //     <div className="p-5 w-full relative mt-8 rounded-[49px] shadow-xl dark:shadow-2xl bg-white dark:bg-neutral-800">
  //       {" "}
  //       <ul className="ml-2 sm:ml-6 md:ml-12 flex space-x-5 sm:space-x-8 lg:space-x-11 overflow-x-auto hiddenScrollbar">
  //         {tabs.map((tab) => {
  //           const active = tab === tabActive;
  //           return (
  //             <li
  //               onClick={() => setTabActive(tab)}
  //               className={`flex-shrink-0 flex items-center cursor-pointer text-sm lg:text-base font-medium ${
  //                 active
  //                   ? ""
  //                   : "text-neutral-500 hover:text-neutral-700 dark:hover:text-neutral-400"
  //               } `}
  //               key={tab}
  //             >
  //               {active && (
  //                 <span className="block w-2.5 h-2.5 rounded-full bg-neutral-800 dark:bg-neutral-100 mr-2" />
  //               )}
  //               <span>{tab}</span>
  //             </li>
  //           );
  //         })}
  //       </ul>
  //     </div>
  //   );
  // };

  const renderForm = () => {
    switch (tabActive) {
      case "Flights":
        return <FlightSearch />;

      default:
        return null;
    }
  };

  return (
    <div className={`nc-DealSearch w-full max-w-6xl py-5 lg:py-0 ${className}`}>
      {/* {renderTab()} */}
      {renderForm()}
    </div>
  );
};

export default DealSearch;
