import { FC } from "react";
import { Helmet } from "react-helmet";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import { Disclosure } from "@headlessui/react";
import { ChevronRightIcon } from "@heroicons/react/20/solid";

export interface PagePrivacyPolicyProps {
  className?: string;
}

const PagePrivacyPolicy: FC<PagePrivacyPolicyProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-PagePrivacyPolicy overflow-hidden relative ${className}`}
      data-nc-id="PagePrivacyPolicy"
    >
      <Helmet>
        <title>Privacy Policy | My Flight Zone</title>
        <meta
          name="description"
          content="Your data's safety matters. Learn about our Privacy Policy and dedication to securing customer info at My Flight Zone. Trust your data's protection."
        />
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
        <div
          className={`nc-SectionHero relative ${className}`}
          data-nc-id="SectionHero"
        >
          <div className="flex flex-col lg:flex-row space-y-14 lg:space-y-0 lg:space-x-10 items-center relative text-center lg:text-left">
            <div className="flex-grow">
              <h1 className="text-3xl !leading-tight font-semibold text-neutral-900 md:text-4xl xl:text-5xl dark:text-neutral-100">
                Privacy Policy
              </h1>
              <br />
              <span className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400">
                <div>
                  <div className="p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800 mb-10">
                    <p>
                      The Satisfaction of our customers is our prime objective.
                      We strongly believe that the trust and confidence indulged
                      by the customer in our organization is a key most factor
                      to provide a pleasurable service. My Flight Zone
                      understand the importance of your personal information and
                      thereby has taken every measure to protect it.
                    </p>
                    <br />
                    <br />
                    <p>
                      We are bound to manage your personal information in
                      accordance with the Information Privacy Principles (IPPs)
                      in the Australian Privacy Act 1988. At My Flight Zone we
                      are highly committed to ensuring the security of our users
                      privacy regarding all information collected during the
                      usage of this site. Following the strict guidelines of the
                      privacy legislation of Australia, My Flight Zone have
                      clearly outlined below what information we collect, how it
                      is used and its storage.
                    </p>
                    <br />
                    <br />
                    <p>
                      This policy may be amended without prior notification due
                      to the changes in legislation and also the changes made to
                      the website. We ask that you read our privacy policy for
                      future updates and amendments along with our terms and
                      conditions
                    </p>
                  </div>
                  <div>
                    <Disclosure>
                      {({ open }) => (
                        <>
                          <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-blue-900 bg-blue-100 rounded-lg hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
                            <span>Information collected</span>
                            <ChevronRightIcon
                              className={`${
                                open ? "transform rotate-90" : ""
                              } w-5 h-5 text-blue-500`}
                            />
                          </Disclosure.Button>
                          <Disclosure.Panel className=" pt-4 pb-2 text-sm">
                            <div className="p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800">
                              <h3 className="text-2xl font-semibold leading-none text-neutral-900 md:text-3xl dark:text-neutral-200">
                                Information collected
                              </h3>
                              <span className="block text-sm text-neutral-500 mt-3 sm:text-base dark:text-neutral-400">
                                The information you enter while making a booking
                                on our website is mainly used to authenticate
                                your details in accordance with your Credit Card
                                number. During the process you will need to
                                provide us with your home address, contact
                                details and e-mail address in the instance we
                                need to contact you. This ensures we are
                                speaking to the correct individual and are able
                                to reply to your queries efficiently.
                              </span>
                            </div>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  </div>
                  <div className="mt-5">
                    <Disclosure>
                      {({ open }) => (
                        <>
                          <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-blue-900 bg-blue-100 rounded-lg hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
                            <span>Use of the information collected</span>
                            <ChevronRightIcon
                              className={`${
                                open ? "transform rotate-90" : ""
                              } w-5 h-5 text-blue-500`}
                            />
                          </Disclosure.Button>
                          <Disclosure.Panel className=" pt-4 pb-2 text-sm">
                            <div className="p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800">
                              <h3 className="text-2xl font-semibold leading-none text-neutral-900 md:text-3xl dark:text-neutral-200">
                                Use of the information collected
                              </h3>
                              <span className="block text-sm text-neutral-500 mt-3 sm:text-base dark:text-neutral-400">
                                <p>
                                  Once you have completed the booking form, your
                                  information is stored directly in the My
                                  Flight Zone database should it be necessary
                                  for a consultant to contact you in regards to
                                  your booking.
                                </p>
                                <p>
                                  We may use your e-mail address or mobile phone
                                  number on occasions to send you specials,
                                  discounted fares updates and newsletters from
                                  My Flight Zone and our partner's promotions
                                  wholesale and retail. You may select to
                                  unsubscribe from receiving these emails or SMS
                                  messages at any time.
                                </p>
                                <p>
                                  Other usages of collected information are to
                                  verify your details via our secure safe
                                  payment gateway in order to identify you are
                                  the correct card holder using the site. The
                                  information you enter while making a booking
                                  on our website is mainly used to authenticate
                                  your details in accordance with your Credit
                                  Card number. During the process you will need
                                  to provide us with your home address, contact
                                  details and e-mail address in the instance we
                                  need to contact you.
                                </p>
                                <p>
                                  This ensures we are speaking to the correct
                                  individual and are able to reply to your
                                  queries efficiently. My Flight Zone may need
                                  to disclose personal information if required
                                  to by law, court order or as requested by a
                                  governmental or law enforcement authority, or
                                  in the good faith believe that disclosure is
                                  otherwise necessary or advisable. Such reasons
                                  may include: Identify, contact, or bring legal
                                  action against someone who may be causing, or
                                  who may be threatening to cause interference
                                  with, or damage to our rights, or properties,
                                  whether intentionally or otherwise, or when
                                  anyone else could be harmed by such
                                  activities, this includes breaking the
                                  Australian Law.
                                </p>
                              </span>
                            </div>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  </div>

                  <div className="mt-5">
                    <Disclosure>
                      {({ open }) => (
                        <>
                          <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-blue-900 bg-blue-100 rounded-lg hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
                            <span>Data security</span>
                            <ChevronRightIcon
                              className={`${
                                open ? "transform rotate-90" : ""
                              } w-5 h-5 text-blue-500`}
                            />
                          </Disclosure.Button>
                          <Disclosure.Panel className=" pt-4 pb-2 text-sm">
                            <div className="p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800">
                              <h3 className="text-2xl font-semibold leading-none text-neutral-900 md:text-3xl dark:text-neutral-200">
                                Data security
                              </h3>
                              <span className="block text-sm text-neutral-500 mt-3 sm:text-base dark:text-neutral-400">
                                <p>
                                  My Flight Zone provides a secure environment
                                  for the authorisation of credit card
                                  transactions over the Internet. A client's
                                  credit card details are not stored anywhere on
                                  the Internet and the only company who has
                                  access to this data is the merchant's bank.
                                </p>
                                <p>
                                  The bank also sends confirmation information
                                  via the same encryption technology back to the
                                  client's browser. These secure processes
                                  provide clients with peace of mind when
                                  purchasing online.
                                </p>
                              </span>
                            </div>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  </div>

                  <div className="mt-5">
                    <Disclosure>
                      {({ open }) => (
                        <>
                          <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-blue-900 bg-blue-100 rounded-lg hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
                            <span>Clients</span>
                            <ChevronRightIcon
                              className={`${
                                open ? "transform rotate-90" : ""
                              } w-5 h-5 text-blue-500`}
                            />
                          </Disclosure.Button>
                          <Disclosure.Panel className=" pt-4 pb-2 text-sm">
                            <div className="p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800">
                              <h3 className="text-2xl font-semibold leading-none text-neutral-900 md:text-3xl dark:text-neutral-200">
                                Clients
                              </h3>
                              <span className="block text-sm text-neutral-500 mt-3 sm:text-base dark:text-neutral-400">
                                If you have a Privacy Complaint or Query If you
                                have a privacy complaint or wish to update or
                                access the personal information that we hold
                                about you, please contact our Privacy Officer
                                on:
                                <br />
                                Tel: (+61) 39803 2244
                                <br />
                                email: info@myflightzone.com.au
                              </span>
                            </div>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  </div>
                </div>
              </span>
            </div>{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PagePrivacyPolicy;
