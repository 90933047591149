import { FC, useEffect, useState } from "react";
import TourDetails from "../(components)/TourDetails";

import ButtonPrimary from "shared/Button/ButtonPrimary";
import Checkbox from "shared/Checkbox/Checkbox";
import {
  TourType,
  AdditionalOption,
  TourAvailableDate,
  Tour,
  TourDepartureLocation,
} from "../_models";

interface TourBookingPending {
  id: number;
  tour_id: number;
  tour_type_id: number;
  adults: number;
  children: number;
  infants: number;
  total_price: string;
  tour_departure_location_id: number;
  tour_departure_date_id: number;
  tour: Tour;
  tour_type: TourType;
  tour_available_date: TourAvailableDate;
  tour_departure_location: TourDepartureLocation;
  tour_booking_passengers: TourBookingPassenger[];
}

interface TourBookingPassenger {
  additional_options: AdditionalOption[];
}

interface PassengerDetail {
  passengerNumber: number;
  optionsPrice: number;
  options: AdditionalOption[];
}

interface PriceBreakdown {
  basePriceTotal: number;
  tourTypePrice: number;
  departureDatePrice: number;
  departureLocationPrice: number;
  passengerDetails: PassengerDetail[];
  basePrice: number;
}

interface SidebarProps {
  tourBookingPending: any;
  currency: string;
  exchangeRate: number;
  isCreatingCustomer: boolean;
  useFormikContext: any;
  handleTermsSelection: any;
  terms: boolean;
  ErrorMessage: any;
}

const Sidebar: FC<SidebarProps> = ({
  tourBookingPending,
  currency,
  exchangeRate,
  isCreatingCustomer,
  useFormikContext,
  terms,
  handleTermsSelection,
  ErrorMessage,
}) => {
  const [surcharge, setSurcharge] = useState<string>("");
  const totalPriceAud = tourBookingPending.total_price;
  const [subTotalPrice, setSubTotalPrice] = useState<string>("");
  const [totalPrice, setTotalPrice] = useState<string>("");

  const exchangeMultiplier = currency === "LKR" ? exchangeRate : 1;

  const getPriceBreakdown = (
    tourBookingPending: TourBookingPending,
    currency: string,
    exchangeRate: number
  ): PriceBreakdown => {
    const basePrice =
      parseFloat(tourBookingPending.tour.base_price) * exchangeMultiplier;

    const basePriceTotal =
      basePrice *
      (tourBookingPending.adults +
        tourBookingPending.children +
        tourBookingPending.infants);

    let tourTypePrice = 0;
    let departureDatePrice = 0;
    let departureLocationPrice = 0;

    const passengerDetails: PassengerDetail[] =
      tourBookingPending.tour_booking_passengers.map((passenger, index) => {
        let optionsPrice = 0;
        const options = passenger.additional_options.map((option) => {
          const optionPrice =
            Number(option.additional_price) * exchangeMultiplier;
          optionsPrice += optionPrice;
          return {
            id: option.id,
            additional_price: optionPrice,
            option_title: option.option_title,
          };
        });

        return {
          passengerNumber: index + 1,
          optionsPrice,
          options,
        };
      });

    console.log("tourBookingPending :: ", tourBookingPending);

    tourTypePrice =
      parseFloat(tourBookingPending.tour_type.additional_price) *
      exchangeMultiplier *
      (tourBookingPending.adults +
        tourBookingPending.children +
        tourBookingPending.infants);

    departureDatePrice =
      parseFloat(tourBookingPending.tour_available_date.additional_price) *
      exchangeMultiplier *
      (tourBookingPending.adults +
        tourBookingPending.children +
        tourBookingPending.infants);

    departureLocationPrice =
      parseFloat(tourBookingPending.tour_departure_location.additional_price) *
      exchangeMultiplier *
      (tourBookingPending.adults +
        tourBookingPending.children +
        tourBookingPending.infants);

    return {
      basePriceTotal,
      tourTypePrice,
      departureDatePrice,
      departureLocationPrice,
      passengerDetails,
      basePrice,
    };
  };

  const [priceBreakdown, setPriceBreakdown] = useState<PriceBreakdown>(
    getPriceBreakdown(tourBookingPending, currency, exchangeRate)
  );

  const surcharge_percentage_aud: number = parseFloat(
    process.env.REACT_APP_COMMWEB_SURCHARGE || "1"
  );
  const surcharge_percentage_lkr: number = parseFloat(
    process.env.REACT_APP_COMMERCIAL_SURCHARGE || "3"
  );

  useEffect(() => {
    setPriceBreakdown(
      getPriceBreakdown(tourBookingPending, currency, exchangeRate)
    );
    let totalAmount = Number(totalPriceAud);
    if (currency === "LKR") {
      totalAmount = totalPriceAud * exchangeRate;
    }

    let currentSurcharge = 0;
    if (currency === "AUD") {
      currentSurcharge = Number(totalAmount) * (surcharge_percentage_aud / 100);
    } else if (currency === "LKR") {
      currentSurcharge = Number(totalAmount) * (surcharge_percentage_lkr / 100);
    }

    const totalWithSurcharge = Number(totalAmount) + Number(currentSurcharge);

    setSubTotalPrice(totalAmount.toFixed(2));

    setTotalPrice(totalWithSurcharge.toFixed(2));

    setSurcharge(currentSurcharge.toFixed(2));
  }, [
    surcharge_percentage_aud,
    surcharge_percentage_lkr,
    currency,
    exchangeRate,
    totalPriceAud,
  ]);

  const { setFieldValue } = useFormikContext();

  console.log("priceBreakdown ::: ", priceBreakdown);

  return (
    <div className="w-full flex flex-col sm:rounded-2xl lg:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-8 px-0 sm:p-6 xl:p-8">
      <h1 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">
        {tourBookingPending.tour.tour_title}
      </h1>
      <div className="w-full border-b border-neutral-100 dark:border-neutral-700" />

      <TourDetails tour={tourBookingPending.tour} />

      <div className="flex flex-col space-y-4">
        <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
          <span>Number of Passengers</span>
          <span>
            {tourBookingPending.adults +
              tourBookingPending.children +
              tourBookingPending.infants}
          </span>
        </div>
        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
      </div>
      <div className="flex flex-col space-y-4">
        <h3 className="text-2xl font-semibold">Price Details</h3>
        {currency && (
          <div>
            <div>
              <div className="flex justify-between text-neutral-6000 dark:text-neutral-300 mb-3">
                <span>
                  Base Price <br />
                  <span
                    className="block sm:inline"
                    style={{ fontSize: "0.75rem" }}
                  >
                    {currency} {priceBreakdown.basePrice} X{" "}
                    {tourBookingPending.adults}
                  </span>
                </span>
                <span>
                  {currency} {priceBreakdown.basePriceTotal.toFixed(2)}
                </span>
              </div>

              <div className="flex justify-between text-neutral-6000 dark:text-neutral-300 mb-3">
                <span>
                  {tourBookingPending.tour_type.type_title} <br />
                  <span
                    className="block sm:inline"
                    style={{ fontSize: "0.75rem" }}
                  >
                    {currency} {tourBookingPending.tour_type.additional_price * exchangeMultiplier} X{" "}
                    {tourBookingPending.adults}
                  </span>
                </span>
                <span>
                  {currency} {priceBreakdown.tourTypePrice.toFixed(2)}
                </span>
              </div>

              <div className="flex justify-between text-neutral-6000 dark:text-neutral-300 mb-3">
                <span>
                  Tour Date ({tourBookingPending.tour_available_date.from_date})
                  <br />
                  <span
                    className="block sm:inline"
                    style={{ fontSize: "0.75rem" }}
                  >
                    {currency}{" "}
                    {tourBookingPending.tour_available_date.additional_price * exchangeMultiplier} X{" "}
                    {tourBookingPending.adults}
                  </span>
                </span>
                <span>
                  {currency} {priceBreakdown.departureDatePrice.toFixed(2)}
                </span>
              </div>
              <div className="flex justify-between text-neutral-6000 dark:text-neutral-300 mb-3">
                <span>
                  Tour Departure Location (
                  {tourBookingPending.tour_departure_location.location_name})
                  <br />
                  <span style={{ fontSize: "0.75rem" }}>
                    {currency}{" "}
                    {tourBookingPending.tour_departure_location
                      .additional_price * exchangeMultiplier}{" "}
                    X {tourBookingPending.adults} Passengers
                  </span>
                </span>
                <span>
                  {currency} {priceBreakdown.departureLocationPrice.toFixed(2)}
                </span>
              </div>

              {priceBreakdown.passengerDetails.map((passengerDetail, index) => (
                <div key={index}>
                  <h4>Passenger {passengerDetail.passengerNumber}</h4>
                  {passengerDetail.options.map((option, optionIndex) => (
                    <div
                      key={optionIndex}
                      className="flex justify-between text-neutral-6000 dark:text-neutral-300 mb-3"
                    >
                      <span>{option.option_title}</span>
                      <span>
                        {currency} {option.additional_price.toFixed(2)}
                      </span>
                    </div>
                  ))}
                </div>
              ))}
            </div>

            <div className="flex justify-between font-semibold">
              <span>Sub Total</span>
              <span>
                {currency} {subTotalPrice}
              </span>
            </div>
            <div className="flex justify-between font-semibold">
              <span>Surcharge</span>
              <span>
                {currency} {surcharge}
              </span>
            </div>
            <div className="flex justify-between font-semibold">
              <span>Total Price</span>
              <span>
                {currency} {totalPrice}
              </span>
            </div>
          </div>
        )}

        {!currency && (
          <div className="flex justify-between font-semibold">
            <span>Please select the currency.</span>
            <span></span>
          </div>
        )}
      </div>

      <div className="relative flex flex-col  space-y-5">
        <div
          className="bg-blue-200 border-blue-400 text-blue-700 shadow-xl  listingSectionSidebar__wrap"
          role="alert"
        >
          <strong className="font-bold">
            <i className="las la-balance-scale"></i> Terms & Conditions
          </strong>
          <div className="block sm:inline" style={{ fontSize: "0.75rem" }}>
            <a href="/terms-and-conditions" target="_blank">
              Click here to view Terms & Conditions
            </a>
          </div>
        </div>

        <Checkbox
          name="terms"
          label="I have read and agree to the conditions"
          checked={terms}
          onChange={(value: boolean) => {
            handleTermsSelection(value);

            setFieldValue("terms", value);
          }}
        />

        <ErrorMessage name="terms" component="div" className="text-red-500" />
      </div>

      {isCreatingCustomer && (
        <ButtonPrimary loading>In progress...</ButtonPrimary>
      )}
      {!isCreatingCustomer && (
        <ButtonPrimary type="submit">Proceed to Payment</ButtonPrimary>
      )}
    </div>
  );
};

export default Sidebar;
