import { useEffect } from "react";

const PaymentSuccess = () => {
  const result = localStorage.getItem("paymentResult");
  console.log("result :: ", result);
  useEffect(() => {
    console.log("useEffect result ::: ", result);
    if (result) {
      console.log("IN RESULT");
      localStorage.removeItem("paymentResult");
    } else {
      console.log("ELSE");

      // window.location.href = "/";
    }
  }, [result]);

  return (
    <div className={`nc-PaymentSuccess`}>
      <main className="container mt-11 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row">
        <div className="w-full lg:w-3/5 xl:w-2/3 lg:pr-10 ">
          <h1>Payment Successful</h1>
          <div className="mt-10">
            {" "}
            <span
              className={` bg-teal-500 rounded-full text-white text-xs flex items-center justify-center absolute `}
              style={{ padding: "10px" }}
            >
              <i className="las la-check" style={{ fontSize: "20px" }}></i>
            </span>
            <div style={{ paddingLeft: "50px" }}>
              Your payment has been processed successfully and you will receive
              a confirmation email shortly. Thank you!
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default PaymentSuccess;
