import { Tab } from "@headlessui/react";
import { FC, useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import { getTourBookingPending } from "../../../data/tourOrderPending/_requests";
import { Formik, Form, Field, ErrorMessage, useFormikContext } from "formik";
import Loading from "../../../components/Loading/Loading";
import * as Yup from "yup";
import { initiateTourCheckout } from "../../../data/payment/_requests";
import { fetchCurrentExchangeRate } from "../../../data/exchangeRate/_requests";
import TourCheckOutPreProcessing from "./TourCheckOutPreProcessing";
import Sidebar from "./Sidebar";

export interface TourCheckOutPageProps {
  className?: string;
}

interface Payload {
  email_address: string;
  order_number: string;
  tour_id: number;
  full_name: string;
  mobile: string;
  adult: any[];
  child: any[];
  infant: any[];
  currency: string;
  terms: boolean;
}

export type RouteParams = {
  tour_id: string;
  order_number: string;
};

export type PaymentParams = {
  sessionId: string;
  orderId: string;
  merchant: string;
  currency: string;
};

const validationSchema = Yup.object().shape({
  currency: Yup.string()
    .oneOf(["AUD", "LKR"])
    .required("Please select your preferred currency for the payment."),
  customerName: Yup.string().required("Customer Name is required"),
  emailAddress: Yup.string()
    .email("Invalid email address")
    .required("Email Address is required"),
  mobile: Yup.string().required("Mobile is required"),
  terms: Yup.boolean()
    .oneOf([true], "Please  acknowledge that you accept our terms.")
    .required("Please  acknowledge that you accept our terms."),
});

const TourCheckOutPage: FC<TourCheckOutPageProps> = ({ className = "" }) => {
  const [isLoading, setIsLoading] = useState(true);
  const { tour_id, order_number } = useParams<RouteParams>();

  const [isCreatingCustomer, setCreatingCustomer] = useState(false);

  const [tourBookingPending, setTourBookingPending] = useState<any>(null);

  const [paymentParams, setPaymentParams] = useState<PaymentParams | null>(
    null
  );

  const [initialValues, setInitialValues] = useState<any>(null);

  const [currency, setCurrency] = useState<string>("");
  const [exchangeRate, setExchangeRate] = useState<number>(0);
  const [terms, setTerms] = useState<boolean>(false);

  let search = useLocation().search;
  let params = new URLSearchParams(search);
  let checkoutVersion = params.get("checkoutVersion");

  console.log(search, params, checkoutVersion);

  useEffect(() => {
    // Get the result from local storage
    const result = localStorage.getItem("paymentResult");
    console.log("result ::: ", result);
    if (result || checkoutVersion) {
      console.log("result ::: ", result);
      window.location.href = "/success_payment";
    }

    const fetchTourData = async (tour_id: string, order_number: string) => {
      try {
        const eRate = await fetchCurrentExchangeRate();

        setExchangeRate(eRate.exchange_rate);

        const data: any = await getTourBookingPending(tour_id, order_number);

        setTourBookingPending(data);
        // Set initial values here
        const { adults, children, infants } = data;
        const initialValues: any = {
          currency: "",
          customerName: "",
          emailAddress: "",
          mobile: "",
          adult: Array(adults).fill({
            last_name: "",
            other_names: "",
            date_of_birth: "",
          }),
          child: Array(children).fill({
            last_name: "",
            other_names: "",
            date_of_birth: "",
          }),
          infant: Array(infants).fill({
            last_name: "",
            other_names: "",
            date_of_birth: "",
          }),
          terms: false,
        };

        setInitialValues(initialValues);
      } catch (error) {
        console.log("error ::: ", error);
        // Handle error
      } finally {
        setIsLoading(false);
      }
    };

    if (tour_id && order_number) {
      fetchTourData(tour_id, order_number);
    }
  }, [tour_id, order_number, checkoutVersion]);

  const handleConfirmAndPay = async (values: any) => {
    console.log("handleConfirmAndPay ::: ", values);
    setCreatingCustomer(true);
    try {
      const {
        customerName,
        emailAddress,
        mobile,
        adult,
        child,
        infant,
        currency,
        terms,
      } = values;

      const payload: Payload = {
        email_address: emailAddress,
        order_number: order_number!,
        tour_id: Number(tour_id),
        full_name: customerName,
        mobile: mobile,
        adult: adult,
        child: child,
        infant: infant,
        currency: currency,
        terms: terms,
      };

      const response = await initiateTourCheckout(payload);

      const { orderId, sessionId, merchant } = response.response;
      setPaymentParams({ orderId, sessionId, merchant, currency });

      setCreatingCustomer(false);
    } catch (error) {
      console.log("ERROR :: ", error);
      // Handle error
    }
  };

  const handleTermsSelection = (terms: any) => {
    setTerms(terms);
  };

  const FormContents = () => {
    const { setFieldValue } = useFormikContext();
    // Rest of your form code here...
    return (
      <>
        <div>
          <h3 className="text-2xl font-semibold">Billing Information</h3>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 my-5"></div>

          <div className="mt-6">
            <Tab.Group>
              <Tab.Panels>
                <Tab.Panel className="space-y-5">
                  <div className="space-y-1">
                    <Label>Select Currency </Label>
                    <Field
                      as={Select} // Use your custom Select component here
                      name="currency"
                      className="block w-full mt-1 rounded-md shadow-sm"
                      onChange={(e: any) => {
                        setCurrency(e.target.value);
                        setFieldValue("currency", e.target.value);
                      }}
                    >
                      <option value="" disabled>
                        Select Currency
                      </option>
                      <option value="AUD">AUD</option>
                      <option value="LKR">LKR</option>
                    </Field>
                    <ErrorMessage
                      name="currency"
                      component="div"
                      className="text-red-500"
                    />
                  </div>

                  <div className="space-y-1">
                    <Label>Customer Name </Label>
                    <Field
                      type="text"
                      id="customerName"
                      name="customerName"
                      as={Input}
                    />{" "}
                    <ErrorMessage
                      name="customerName"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                  <div className="space-y-1">
                    <Label>Email Address </Label>
                    <Field
                      type="email"
                      id="emailAddress"
                      name="emailAddress"
                      as={Input}
                    />
                    <ErrorMessage
                      name="emailAddress"
                      component="div"
                      className="text-red-500"
                    />{" "}
                  </div>
                  <div className="space-y-1">
                    <Label>Mobile </Label>{" "}
                    <Field type="text" id="mobile" name="mobile" as={Input} />
                    <ErrorMessage
                      name="mobile"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
          </div>
        </div>
      </>
    );
  };

  const renderMain = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
        {/* <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleConfirmAndPay}
        >
          <Form>
            <FormContents />
          </Form>
        </Formik> */}

        <FormContents />
      </div>
    );
  };

  if (isLoading || !tourBookingPending) {
    return (
      <div className={`nc-TourCheckOutPage ${className}`}>
        <main className="container mt-11 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row">
          <Loading />
        </main>
      </div>
    );
  }

  if (paymentParams) {
    return <TourCheckOutPreProcessing paymentParams={paymentParams} />;
  }

  return (
    <div className={`nc-TourCheckOutPage ${className}`}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleConfirmAndPay}
      >
        <Form>
          <div
            className="container mt-11 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row lg:hidden flex-[3]  !mx-auto  mt-10 mb-20"
            style={{ textAlign: "center" }}
          >
            {
              <Sidebar
                tourBookingPending={tourBookingPending}
                currency={currency}
                exchangeRate={exchangeRate}
                isCreatingCustomer={isCreatingCustomer}
                useFormikContext={useFormikContext}
                terms={terms}
                handleTermsSelection={handleTermsSelection}
                ErrorMessage={ErrorMessage}
              />
            }
          </div>
          <main className="container mt-11 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row">
            <div className="w-full lg:w-3/5 xl:w-2/3 lg:pr-10 ">
              {renderMain()}
            </div>
            {/* <div className="hidden lg:block flex-grow">{renderSidebar()}</div> */}

            <div className="hidden lg:block flex-grow">
              {
                <Sidebar
                  tourBookingPending={tourBookingPending}
                  currency={currency}
                  exchangeRate={exchangeRate}
                  isCreatingCustomer={isCreatingCustomer}
                  useFormikContext={useFormikContext}
                  terms={terms}
                  handleTermsSelection={handleTermsSelection}
                  ErrorMessage={ErrorMessage}
                />
              }
            </div>
          </main>
        </Form>
      </Formik>
    </div>
  );
};

export default TourCheckOutPage;
