import { FC, ReactNode } from "react";

export interface SectionHeroProps {
  className?: string;
  rightImg: string;
  heading: ReactNode;
  subHeading: string;
  btnText: string;
}

const SectionHero: FC<SectionHeroProps> = ({
  className = "",
  rightImg,
  heading,
  subHeading,
  btnText,
}) => {
  return (
    <>
      <div
        className={`nc-SectionHero relative ${className}`}
        data-nc-id="SectionHero"
      >
        <div className="flex flex-col lg:flex-row space-y-14 lg:space-y-0 lg:space-x-10 items-center relative text-center lg:text-left">
          <div className="flex-grow">
            <h1 className="text-3xl !leading-tight font-semibold text-neutral-900 md:text-4xl xl:text-5xl dark:text-neutral-100">
              {heading}
            </h1>
            <br />
            <span className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400">
              {subHeading}
            </span>
          </div>{" "}
          <div className="w-screen max-w-full xl:max-w-lg space-y-5 lg:space-y-7">
            <img className="w-full" src={rightImg} alt="" />
          </div>
        </div>
      </div>
      <div className={`nc-SectionStatistic relative ${className}`}>
        <div className="grid md:grid-cols-2 gap-6 lg:grid-cols-3 xl:gap-8">
          <div className="p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800">
            <h3 className="text-2xl font-semibold leading-none text-neutral-900 md:text-3xl dark:text-neutral-200">
              We Live and Breathe Travel
            </h3>
            <span className="block text-sm text-neutral-500 mt-3 sm:text-base dark:text-neutral-400">
              At My Flight Zone, we live for the rush our customers get while
              planning their next vacation. We love to serve our faithful
              clients whether it is holidaying, travelling or just browsing
              through our ever-growing website.
            </span>
          </div>
          <div className="p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800">
            <h3 className="text-2xl font-semibold leading-none text-neutral-900 md:text-3xl dark:text-neutral-200">
              We pledge a Conscientious Service
            </h3>
            <span className="block text-sm text-neutral-500 mt-3 sm:text-base dark:text-neutral-400">
              At My Flight Zone, We love to put ourselves in our customer’s
              shoes when it comes to designing quality services for them. We can
              fully understand the struggles, the requirements and all the panic
              that comes with travelling.
            </span>
          </div>
          <div className="p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800">
            <h3 className="text-2xl font-semibold leading-none text-neutral-900 md:text-3xl dark:text-neutral-200">
              We Innovate the way Forward
            </h3>
            <span className="block text-sm text-neutral-500 mt-3 sm:text-base dark:text-neutral-400">
              With the ever-changing and evolving travel industry, we go to
              great lengths to make sure that we are up to date so that our
              customers get the best for the money they pay. We at My Flight
              Zone, try to be the innovators and lead the industry.
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default SectionHero;
