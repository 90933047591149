import axios, { AxiosError } from "axios";

const API_URL = process.env.REACT_APP_API_URL;
const ADD_REQUEST_FORM = `${API_URL}/refund/add`;
const REFUND_STATUS = `${API_URL}/refund/status`;
const REFUND_VALIDATE = `${API_URL}/refund/validate`;

export interface RefundFormPayload {
  booking_reference: string;
  last_name: string;
  refund_reason: string;
}

interface AddRefundFormPayload {
  bookings: RefundFormPayload[];
  refund_terms_accepted: boolean;
  no_show_terms_accepted: boolean;
}

interface RefundFormResponse {
  message?: string;
  error?: string;
}

interface RefundStatusFormResponse {
  message?: string;
  error?: string;
  status?: string;
  refund_date?: string;
  updated_at?: string;
}

export interface Booking {
  booking_reference: string;
  last_name: string;
  invoice_item_id: number;
}

interface ValidateRefundFormResponse {
  bookings?: Booking[];
  error?: string;
}

export async function validateRefundForm(
  payload: RefundFormPayload
): Promise<ValidateRefundFormResponse> {
  try {
    const response = await axios.post<ValidateRefundFormResponse>(
      REFUND_VALIDATE,
      payload
    );
    console.log("addRefundForm response ::: ", response);
    return response.data;
  } catch (error) {
    console.log("error ::: ", error);
    if (axios.isAxiosError(error)) {
      console.log("axios.isAxiosError(error) ::: ", axios.isAxiosError(error));
      const axiosError = error as AxiosError<ValidateRefundFormResponse>;
      console.log("axiosError ::: ", axiosError);
      if (axiosError.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(
          "axiosError.response.data.error ::: ",
          axiosError.response.data.error
        );
        throw new Error(
          axiosError.response.data.error || "Unknown error occurred"
        );
      } else if (axiosError.request) {
        // The request was made but no response was received
        throw new Error("No response received from server");
      }
    }
    // Something happened in setting up the request that triggered an Error
    throw new Error("Failed to send refund form");
  }
}

export async function addRefundForm(
  payload: AddRefundFormPayload
): Promise<RefundFormResponse> {
  try {
    const response = await axios.post<RefundFormResponse>(
      ADD_REQUEST_FORM,
      payload
    );
    console.log("addRefundForm response ::: ", response);
    return response.data;
  } catch (error) {
    console.log("error ::: ", error);
    if (axios.isAxiosError(error)) {
      console.log("axios.isAxiosError(error) ::: ", axios.isAxiosError(error));
      const axiosError = error as AxiosError<RefundFormResponse>;
      console.log("axiosError ::: ", axiosError);
      if (axiosError.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(
          "axiosError.response.data.error ::: ",
          axiosError.response.data.error
        );
        throw new Error(axiosError.response.data.error);
      } else if (axiosError.request) {
        // The request was made but no response was received
        throw new Error("No response received from server");
      }
    }
    // Something happened in setting up the request that triggered an Error
    throw new Error("Failed to send refund form");
  }
}

export async function refundStatus(
  payload: RefundFormPayload
): Promise<RefundStatusFormResponse> {
  try {
    const response = await axios.post<RefundStatusFormResponse>(
      REFUND_STATUS,
      payload
    );
    console.log("refundStatus response ::: ", response);
    return response.data;
  } catch (error) {
    console.log("error ::: ", error);
    if (axios.isAxiosError(error)) {
      console.log("axios.isAxiosError(error) ::: ", axios.isAxiosError(error));
      const axiosError = error as AxiosError<RefundStatusFormResponse>;
      console.log("axiosError ::: ", axiosError);
      if (axiosError.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(
          "axiosError.response.data.error ::: ",
          axiosError.response.data.error
        );
        throw new Error(axiosError.response.data.error);
      } else if (axiosError.request) {
        // The request was made but no response was received
        throw new Error("No response received from server");
      }
    }
    // Something happened in setting up the request that triggered an Error
    throw new Error("Failed to send refund form");
  }
}
