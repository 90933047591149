import { FC } from "react";
import moment from "moment";
import GallerySlider from "components/GallerySlider/GallerySlider";
import { StayDataType } from "data/types";
import SaleOffBadge from "components/SaleOffBadge/SaleOffBadge";
import Badge from "shared/Badge/Badge";

export interface StayCardProps {
  className?: string;
  data: StayDataType;
  size?: "default" | "small";
}

const StayCard: FC<StayCardProps> = ({
  size = "default",
  className = "",
  data,
}) => {
  const {
    id,
    title,
    new_price,
    old_price,
    banner_image,
    available_tickets,
    slug,
    arrival_city,
    arrival_datetime,
    departure_city,
    departure_datetime,
    is_return_trip,
    return_departure_city,
    return_departure_datetime,
  } = data;

  const formattedPrice = `AUD $${new_price.toLocaleString("en-AU", {
    style: "currency",
    currency: "AUD",
  })}`;

  const renderSliderGallery = () => {
    return (
      <div className="relative w-full">
        <GallerySlider
          uniqueID={`StayCard_${id}`}
          ratioClass="aspect-w-4 aspect-h-3 "
          galleryImgs={[banner_image]}
          href={`/special-flight/${id}/${slug}`}
        />
        <SaleOffBadge
          desc={`$${old_price}`}
          className="absolute left-3 top-3 line-through"
        />
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className={size === "default" ? "p-4 space-y-4" : "p-3 space-y-2"}>
        <div className="space-y-2">
          <div className="flex justify-between items-center">
            {available_tickets < 10 && (
              <Badge
                name={
                  <div className="flex items-center">
                    <i className="text-sm la la-plane"></i>
                    <span className="ml-1">
                      Last {available_tickets} Seats!
                    </span>
                  </div>
                }
                color="yellow"
              />
            )}
            &nbsp;
            {is_return_trip && (
              <Badge
                name={
                  <div className="flex items-center">
                    <i className="text-sm la la-plane"></i>
                    <i
                      className="text-sm la la-plane mr-1"
                      style={{ transform: "rotate(180deg)" }}
                    ></i>
                    <span className="ml-1">Return</span>
                  </div>
                }
                color="blue"
              />
            )}
          </div>

          <div className="flex items-center space-x-2">
            <h2
              className={` font-medium capitalize ${
                size === "default" ? "text-lg" : "text-base"
              }`}
            >
              <span className="line-clamp-1">{title}</span>
            </h2>
          </div>
        </div>

        {!is_return_trip && (
          <div>
            <div className="flex justify-between items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
              <div className="flex items-center space-x-2">
                <div className="flex items-center space-x-2">
                  <span className="ml-1">
                    <i className="las la-plane-departure"></i>
                  </span>
                </div>
                <div className="flex items-center space-x-2">
                  {departure_city}
                </div>
              </div>

              <div className="flex items-center space-x-2">
                <div className="flex items-center space-x-2">
                  <span className="ml-1">
                    <i className="las la-clock"></i>{" "}
                  </span>
                </div>
                <div className="flex items-center space-x-2">
                  {moment(departure_datetime).format("DD, MMM YY @ HH:mm")}
                </div>
              </div>
            </div>
            <div className="flex justify-between items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
              <div className="flex items-center space-x-2">
                <div className="flex items-center space-x-2">
                  <span className="ml-1">
                    <i className="las la-plane-arrival"></i>
                  </span>
                </div>
                <div className="flex items-center space-x-2">
                  {arrival_city}
                </div>
              </div>

              <div className="flex items-center space-x-2">
                <div className="flex items-center space-x-2">
                  <span className="ml-1">
                    <i className="las la-clock"></i>{" "}
                  </span>
                </div>
                <div className="flex items-center space-x-2">
                  {moment(arrival_datetime).format("DD, MMM YY @ HH:mm")}
                </div>
              </div>
            </div>
          </div>
        )}

        {is_return_trip && (
          <div>
            <div className="flex justify-between items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
              <div className="flex items-center space-x-2">
                <div className="flex items-center space-x-2">
                  <span className="ml-1">
                    <i className="text-sm la la-plane"></i>
                  </span>
                </div>
                <div className="flex items-center space-x-2">
                  {departure_city}
                </div>
              </div>

              <div className="flex items-center space-x-2">
                <div className="flex items-center space-x-2">
                  <span className="ml-1">
                    <i className="las la-clock"></i>{" "}
                  </span>
                </div>
                <div className="flex items-center space-x-2">
                  {new Date(departure_datetime).toLocaleString("en-US", {
                    day: "2-digit",
                    month: "short",
                    year: "2-digit",
                    hour: "numeric",
                    minute: "numeric",
                    hour12: false,
                  })}
                </div>
              </div>
            </div>
            <div className="flex justify-between items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
              <div className="flex items-center space-x-2">
                <div className="flex items-center space-x-2">
                  <span className="ml-1">
                    <i
                      className="text-sm la la-plane mr-1"
                      style={{ transform: "rotate(180deg)" }}
                    ></i>
                  </span>
                </div>
                <div className="flex items-center space-x-2">
                  {return_departure_city}
                </div>
              </div>

              <div className="flex items-center space-x-2">
                <div className="flex items-center space-x-2">
                  <span className="ml-1">
                    <i className="las la-clock"></i>{" "}
                  </span>
                </div>
                <div className="flex items-center space-x-2">
                  {moment(return_departure_datetime).format(
                    "DD, MMM YY @ HH:mm"
                  )}
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="w-14 border-b border-neutral-100 dark:border-neutral-800"></div>
        <div className="flex justify-between items-center">
          <span className="text-base font-semibold">{formattedPrice}</span>

          <a
            href={`/special-flight/${id}/${slug}`}
            className="ttnc-ButtonPrimary px-4 py-2 text-sm disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50 rounded-full inline-flex items-center text-sm dark:text-neutral-300 font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
          >
            Book Now
          </a>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-StayCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow ${className}`}
      data-nc-id="StayCard"
    >
      {renderSliderGallery()}
      {renderContent()}
    </div>
  );
};

export default StayCard;
