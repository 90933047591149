import React from "react";
import moment from "moment";

interface FlightDetailsProps {
  flightDeal: any;
}

const FlightDetails: React.FC<FlightDetailsProps> = ({ flightDeal }) => {
  return (
    <>
      <div className="grid grid-cols-2 gap-4">
        <div className="flex items-start justify-start space-x-6 text-sm text-neutral-700 dark:text-neutral-300">
          <div className="flex items-start space-x-2">
            <span className="flex items-center ml-1">
              <i className="las la-plane-departure mr-2"></i> Departure
            </span>
          </div>
          <div className="flex items-start space-x-2">
            <span className="ml-1">
              <i className="las la-map-marker"></i>{" "}
              {flightDeal.departure_airport}
              {flightDeal.departure_country}, {flightDeal.departure_city}
              <br />
              <i className="las la-clock"></i>{" "}
              {moment(flightDeal.departure_datetime).format(
                "DD, MMM YY @ HH:mm"
              )}
            </span>
          </div>
        </div>
        <div className="flex items-start justify-start space-x-6 text-sm text-neutral-700 dark:text-neutral-300">
          <div className="flex items-start space-x-2">
            <span className="flex items-center ml-1">
              <i className="las la-plane-arrival mr-2"></i>{" "}
              Arrival&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </div>
          <div className="flex items-start space-x-2">
            <span className="ml-1">
              <i className="las la-map-marker"></i> {flightDeal.arrival_airport}
              , {flightDeal.arrival_country}, {flightDeal.arrival_city}
              <br />
              <i className="las la-clock"></i>{" "}
              {moment(flightDeal.arrival_datetime).format("DD, MMM YY @ HH:mm")}
            </span>
          </div>
        </div>
      </div>
      {flightDeal.is_return_trip && (
        <div>
          <h4 className="mb-3">Return Trip</h4>
          <div className="grid grid-cols-2 gap-4">
            <div className="flex items-start justify-start space-x-6 text-sm text-neutral-700 dark:text-neutral-300">
              <div className="flex items-start space-x-2">
                <span className="flex items-center ml-1">
                  <i className="las la-plane-departure mr-2"></i> Departure
                </span>
              </div>
              <div className="flex items-start space-x-2">
                <span className="ml-1">
                  <i className="las la-map-marker"></i>{" "}
                  {flightDeal.return_departure_airport},{" "}
                  {flightDeal.return_departure_country},{" "}
                  {flightDeal.return_departure_city}
                  <br />
                  <i className="las la-clock"></i>{" "}
                  {moment(flightDeal.return_departure_datetime).format(
                    "DD, MMM YY @ HH:mm"
                  )}
                </span>
              </div>
            </div>

            <div className="flex items-start justify-start space-x-6 text-sm text-neutral-700 dark:text-neutral-300">
              <div className="flex items-start space-x-2">
                <span className="flex items-center ml-1">
                  <i className="las la-plane-arrival mr-2"></i>{" "}
                  Arrival&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </div>
              <div className="flex items-start space-x-2">
                <span className="ml-1">
                  <i className="las la-map-marker"></i>{" "}
                  {flightDeal.return_arrival_airport},{" "}
                  {flightDeal.return_arrival_country},{" "}
                  {flightDeal.return_arrival_city} <br />
                  <i className="las la-clock"></i>{" "}
                  {moment(flightDeal.return_arrival_datetime).format(
                    "DD, MMM YY @ HH:mm"
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FlightDetails;
