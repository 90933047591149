import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import { FC } from "react";
import SectionGridFilterCard from "./SectionGridFilterCard";
import { Helmet } from "react-helmet";

export interface ListingTourPageProps {
  className?: string;
}

const ListingTourPage: FC<ListingTourPageProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-ListingTourPage relative overflow-hidden ${className}`}
      data-nc-id="ListingTourPage"
    >
      <Helmet>
        <title>Tours - Flights Ticket Booking | My Flight Zone</title>
        <meta
          name="description"
          content="Find cheap flight tickets to travel to & from Sri Lanka. My Flight Zone offers affordable flights to Colombo, Sri Lanka ,Perth, Melbourne and more places."
        />
      </Helmet>
      <BgGlassmorphism />

      <div className="container relative overflow-hidden">
        <SectionGridFilterCard className="pb-24 lg:pb-28" />
      </div>
    </div>
  );
};

export default ListingTourPage;
