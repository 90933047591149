import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const usePageTracking = (): void => {
  const location = useLocation();

  useEffect(() => {
    console.log(
      "Tracking page view :: ",
      location.pathname + location.search + location.hash
    );
    console.log("location.pathname :: ", location.pathname);
    console.log("location.search :: ", location.search);
    console.log("location.hash :: ", location.hash);

    window.gtag("event", "page_view", {
      page_path: location.pathname + location.search + location.hash,
      page_search: location.search,
      page_hash: location.hash,
    });
  }, [location]);
};
