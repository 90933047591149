import { FC, useEffect, useState } from "react";

export interface Props {
  className?: string;
  paymentParams: PaymentParams;
}

export type PaymentParams = {
  sessionId: string;
  orderId: string;
  merchant: string;
  currency: string;
};

const TourCheckOutPreProcessing: FC<Props> = ({
  className = "",
  paymentParams,
}) => {
  const { sessionId, orderId, merchant, currency } = paymentParams;
  const [countdown, setCountdown] = useState(5);

  useEffect(() => {
    window.formData = JSON.stringify({ orderId, sessionId, merchant });

    const scriptPaymentGateway = document.createElement("script");

    let pgScript = "";

    console.log("currency ::: ", currency);

    if (currency === "AUD") {
      pgScript =
        "https://paymentgateway.commbank.com.au/static/checkout/checkout.min.js";
    } else if (currency === "LKR") {
      pgScript =
        "https://cbcmpgs.gateway.mastercard.com/static/checkout/checkout.min.js";
    }

    scriptPaymentGateway.setAttribute("src", pgScript);
    scriptPaymentGateway.setAttribute("data-error", "errorCallback");
    scriptPaymentGateway.setAttribute("data-complete", "completeCallback");
    document.body.appendChild(scriptPaymentGateway);

    const scriptCheckout = document.createElement("script");

    scriptCheckout.setAttribute("src", "/js/script.js");

    scriptPaymentGateway.onload = () => {
      document.body.appendChild(scriptCheckout);
    };

    const countdownTimer = setInterval(() => {
      setCountdown((prevCountdown: number) => prevCountdown - 1);
    }, 1000);

    const timeout = setTimeout(() => {
      showPaymentPage();
      clearInterval(countdownTimer);
    }, 5000);

    localStorage.removeItem("tours");

    return () => {
      document.body.removeChild(scriptPaymentGateway);
      document.body.removeChild(scriptCheckout);
      clearTimeout(timeout);
      clearInterval(countdownTimer);
    };
  }, [orderId, sessionId, merchant]);

  const showPaymentPage = () => {
    (window as any).Checkout.showPaymentPage();
  };

  const renderMain = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
        <div>
          <div className="mt-6">
            Please wait... Redirecting to Secure Payments.
            {countdown > 0 && (
              <div>
                <span
                  style={{ cursor: "pointer", textDecoration: "underline" }}
                  onClick={showPaymentPage}
                >
                  Click here
                </span>{" "}
                If this does not take you to the payment page in {countdown}{" "}
                seconds.
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={`nc-TourCheckOutPage ${className}`}>
      <main className="container mt-11 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row">
        <div className="w-full lg:w-3/5 xl:w-2/3 lg:pr-10 ">{renderMain()}</div>
      </main>
    </div>
  );
};

export default TourCheckOutPreProcessing;
