import { FC } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";
import { Link } from "react-router-dom";
import { DestinationDataType } from "data/types";

export interface DestinationCardProps {
  className?: string;
  data: DestinationDataType;
  size?: "default" | "small";
  index: number;
}

const DestinationCard: FC<DestinationCardProps> = ({
  index,
  size = "default",
  className = "",
  data,
}) => {
  const { id, title, banner_image, slug } = data;

  const renderSliderGallery = () => {
    return (
      <div className="relative w-full">
        <GallerySlider
          uniqueID={`DestinationCard_${index}`}
          ratioClass="aspect-w-4 aspect-h-3 "
          galleryImgs={[banner_image]}
          href={`/blog/${id}/${slug}`}
        />
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className={size === "default" ? "p-4 space-y-4" : "p-3 space-y-2"}>
        <div className="space-y-2">
          <div className="flex items-center space-x-2">
            <h2
              className={` font-medium capitalize ${
                size === "default" ? "text-lg" : "text-base"
              }`}
            >
              <span className="line-clamp-1">{title}</span>
            </h2>
          </div>
        </div>
        <div className="w-14 border-b border-neutral-100 dark:border-neutral-800"></div>
      </div>
    );
  };

  return (
    <div
      className={`nc-DestinationCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow ${className}`}
      data-nc-id="DestinationCard"
    >
      {renderSliderGallery()}
      <Link to={`/blog/${id}/${slug}`}>{renderContent()}</Link>
    </div>
  );
};

export default DestinationCard;
