import { Helmet } from "react-helmet";
import SectionHero from "components/SectionHero/SectionHero";
import SectionGridFeaturePlaces from "./SectionGridFeaturePlaces";
// import SectionGridFeatureTours from "./SectionGridFeatureTours";

import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import homeBackdropImg from "images/home-backdrop.jpg";
import SectionGridPopularDestinations from "./SectionGridPopularDestinations";
import SectionCommunity from "./SectionCommunity";

function PageHome() {
  const appBaseUrl = process.env.APP_BASE_URL
    ? process.env.APP_BASE_URL
    : "https://myflightzone.com.au";
    
  return (
    <>
      <Helmet>
        <link rel="canonical" href={`${appBaseUrl}`} />
      </Helmet>

      <div
        className=" relative overflow-hidden"
        style={{
          backgroundImage: `url(${homeBackdropImg})`,
          backgroundSize: "cover",
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
          // height: "100vh",
          backgroundColor: "#000",
        }}
      >
        <div className="container relative space-y-24 mb-24 lg:space-y-28 lg:mb-28">
          {/* SECTION HERO */}
          <SectionHero className="pt-10 lg:pt-16 lg:pb-16" />
        </div>
      </div>
      <div className="nc-PageHome relative overflow-hidden mt-10">
        {/* GLASSMOPHIN */}
        <BgGlassmorphism />

        <div className="container relative space-y-24 mb-24 lg:space-y-28 lg:mb-28">
          <div className="relative py-16">
            <BackgroundSection />
            <SectionGridFeaturePlaces />
          </div>

          {/* <SectionGridFeatureTours /> */}

          <div className="relative py-16">
            <BackgroundSection />
            <SectionGridPopularDestinations />
          </div>

          {/* SECTION 1 */}
          <div className="relative py-16">
            <SectionCommunity />
          </div>
        </div>
      </div>
    </>
  );
}

export default PageHome;
