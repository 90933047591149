import { Routes, Route, Navigate } from "react-router-dom";
import { Page } from "./types";
import ScrollToTop from "./ScrollToTop";
import Footer from "shared/Footer/Footer";
import PageHome from "containers/PageHome/PageHome";
import PageAbout from "containers/PageAbout/PageAbout";
import PageContact from "containers/PageContact/PageContact";
import Page404 from "containers/Page404/Page404";
import SiteHeader from "containers/SiteHeader";
import FooterNav from "components/FooterNav";
import useWindowSize from "hooks/useWindowResize";
import ListingStayDetailPage from "containers/FlightDealDetailPage/flight-deal-detail/ListingStayDetailPage";
import ListingStayPage from "containers/ListingStayPage/ListingStayPage";
import ListingTourPage from "containers/ListingTourPage/ListingTourPage";
import FlightDealCheckOutPage from "containers/FlightDealDetailPage/(components)/FlightDealCheckOutPage";
import BlogPage from "containers/BlogPage/BlogPage";
import BlogSingle from "containers/BlogPage/BlogSingle";
import PaymentSuccess from "containers/PaymentSuccess/PaymentSuccess";
import PageTermsAndConditions from "containers/PageTermsAndConditions/PageTermsAndConditions";
import PagePrivacyPolicy from "containers/PagePrivacyPolicy/PagePrivacyPolicy";
import TourDetailPage from "containers/TourDetailPage/tour-detail/TourDetailPage";
import TourAdditionalOptionsPage from "containers/TourDetailPage/(components)/TourAdditionalOptionsPage";

import TourCheckOutPage from "containers/TourDetailPage/(components)/TourCheckOutPage";

import PageRefundRequest from "containers/PageRefundRequest/PageRefundRequest";
import PageRefundStatus from "containers/PageRefundStatus/PageRefundStatus";

import PaymentInstalment from "containers/PaymentInstalment/PaymentInstalment";

import { usePageTracking } from "../utils/tracking/tracking";

export const pages: Page[] = [
  { path: "/", exact: true, component: PageHome },
  { path: "/#", exact: true, component: PageHome },
  { path: "/special-flights", component: ListingStayPage },

  { path: "/tours", component: ListingTourPage },

  { path: "/blog", component: BlogPage },
  { path: "/who-we-are", component: PageAbout },
  { path: "/contact", component: PageContact },
  { path: "/success_payment", component: PaymentSuccess },
  { path: "/terms-and-conditions", component: PageTermsAndConditions },
  { path: "/privacy-policy", component: PagePrivacyPolicy },
  { path: "/refund-request", component: PageRefundRequest },
  { path: "/refund-status", component: PageRefundStatus },
  { path: "/payment-instalment", component: PaymentInstalment },
];

const MyRoutes = () => {
  let WIN_WIDTH = useWindowSize().width;
  if (typeof window !== "undefined") {
    WIN_WIDTH = WIN_WIDTH || window.innerWidth;
  }

  usePageTracking();

  return (
    <>
      <ScrollToTop />
      <SiteHeader />

      <Routes>
        {pages.map(({ component, path }) => {
          const Component = component;
          return <Route key={path} element={<Component />} path={path} />;
        })}
        <Route
          path="/special-flight/:id/:slug"
          element={<ListingStayDetailPage />}
        />
        <Route
          path="/special-flights-checkout/:flight_deal_id/:order_number"
          element={<FlightDealCheckOutPage />}
        />

        <Route path="/tours/:id/:slug" element={<TourDetailPage />} />
        <Route
          path="/tours-additional-options/:id/"
          element={<TourAdditionalOptionsPage />}
        />
        <Route
          path="/tours-checkout/:tour_id/:order_number"
          element={<TourCheckOutPage />}
        />

        <Route path="/blog/:id/:slug" element={<BlogSingle />} />

        <Route path="/payment-instalment" element={<PaymentInstalment />} />

        {/* Redirect any other route to the home page */}
        <Route path="*" element={<Navigate to="/" replace />} />
        <Route element={<Page404 />} />
      </Routes>

      {WIN_WIDTH < 768 && <FooterNav />}
      <Footer />
    </>
  );
};

export default MyRoutes;
