import React, { FC } from "react";
import Logo from "shared/Logo/Logo";
import Navigation from "shared/Navigation/Navigation";
import MenuBar from "shared/MenuBar/MenuBar";
import { Link } from "react-router-dom";

export interface MainNav1Props {
  className?: string;
}

const MainNav1: FC<MainNav1Props> = ({ className = "" }) => {
  return (
    <div className={`nc-MainNav1 relative z-10 ${className}`}>
      <div className="px-4 lg:container py-4 lg:py-5 relative flex justify-between items-center">
        <div className="hidden md:flex justify-start flex-1 items-center space-x-4 sm:space-x-10">
          <Logo />
          <Navigation />
        </div>{" "}
        <div className="md:hidden">
          <Logo />
        </div>
        <div className="hidden md:flex flex-shrink-0 items-center justify-end flex-1 lg:flex-none text-neutral-700 dark:text-neutral-100">
          <div className="hidden items-center lg:flex space-x-1">
            <Link
              to="tel:+61452365247"
              className="
    bg-blue-500 hover:bg-blue-700
    text-white
    group px-4 py-2 border-blue-500 hover:border-blue-700 dark:border-blue-700 rounded-full inline-flex items-center text-sm dark:text-neutral-300 font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
            >
              <i className="las la-phone mr-1"></i>+61 452 365 24 7
            </Link>

            <div></div>

            <a
              href="https://payments.myflightzone.com.au/"
              className="ttnc-ButtonPrimary px-4 py-2 text-sm disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50 rounded-full inline-flex items-center text-sm dark:text-neutral-300 font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
            >
              <i className="las la-credit-card mr-1"></i>
              Payments
            </a>
          </div>

          <div className="flex xl:hidden items-center">
            <MenuBar />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainNav1;
