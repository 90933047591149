import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionMagazine5 from "./SectionMagazine5";
import SectionLatestPosts from "./SectionLatestPosts";
import { fetchBlogArticles } from "../../data/blogArticle/_requests"; // import the fetchBlogArticles function
import Pagination from "shared/Pagination/Pagination";
import Input from "shared/Input/Input";
import Heading1 from "components/Heading/Heading1";

const BlogPage = () => {
  const [posts, setPosts] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    console.log("searchTerm :: ", searchTerm);
    const fetchPosts = async () => {
      try {
        const params = {
          page: currentPage,
          limit: 10,
          search: searchTerm.length >= 4 ? searchTerm : null,
        };
        const response = await fetchBlogArticles(params); // Fetch 15 posts
        setPosts(response.data.data);
        setTotalItems(response.data.pagination.total);
      } catch (error) {
        console.error("Error fetching posts:", error);
      }
    };

    if (searchTerm.length >= 4 || searchTerm.length === 0) {
      fetchPosts();
    }
  }, [currentPage, searchTerm]);

  const magazinePosts = posts.slice(0, 4); // Get the first 4 posts for the magazine section
  const latestPosts = posts.slice(4); // Get the rest of the posts for the latest posts section

  return (
    <div className="nc-BlogPage overflow-hidden relative">
      <Helmet>
        <title>Blog | My Flight Zone</title>

        <meta
          name="description"
          content="Explore top places to visit, adventures, and beauty of Sri Lanka and Australia in our comprehensive travel guide. Unveil the wonders today!"
        />
      </Helmet>

      <BgGlassmorphism />
      <div className="container relative mt-5">
        <Heading1
          heading="My Flight Zone - Blog"
          subHeading="Your Ultimate Guide to Unforgettable Journeys"
        />
        <Input
          type="search"
          placeholder="Search blogs"
          onChange={handleSearch}
          value={searchTerm}
        />
      </div>
      <div className="container relative">
        {/* === SECTION 1 === */}
        <div className="pt-12 pb-16 lg:pb-28">
          <SectionMagazine5 posts={magazinePosts} />
        </div>

        {/* === SECTION 8 === */}
        <SectionLatestPosts posts={latestPosts} />

        <Pagination
          totalItems={totalItems}
          itemsPerPage={10}
          currentPage={currentPage}
          onChangePage={handlePageChange}
        />
        <br />
        <br />
      </div>
    </div>
  );
};

export default BlogPage;
