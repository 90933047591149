import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;
const SEND_CONTACT_FORM = `${API_URL}/contact/send`;

interface ContactFormPayload {
  fullName: string;
  email: string;
  phoneNumber: string;
  enquiryType: string;
  message: string;
}

interface ContactFormResponse {
  message?: string;
  error?: string;
}

export async function sendContactForm(
  payload: ContactFormPayload
): Promise<ContactFormResponse> {
  try {
    const response = await axios.post<ContactFormResponse>(
      SEND_CONTACT_FORM,
      payload
    );
    return response.data;
  } catch (error) {
    throw new Error("Failed to send contact us form");
  }
}
